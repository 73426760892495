import Sidebars from "../../components/bars/Sidebar";
import Header from "../../components/bars/Header";
import React, { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import CreatableSelect from "react-select/creatable";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import icon from "../../assets/images/location.png";
import api from "../../api/api";
import Swal from "sweetalert2";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";

const WareHouseEdit = () => {
  const { user } = useAuth();
  const params = useParams();
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [customerID, setCustomerID] = useState(Number());
  //FORM STATE
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [customerKey, setCustomerKey] = useState([]);
  const [wareHouseTypes, setWareHouseTypes] = useState([]);
  const [selectedWareHouseType, setSelectedWareHouseType] = useState({});
  const [name, setName] = useState("");

  //FOR SELECT BOX OPTIONS
  const [getCountry, setGetCountry] = useState([]);
  const [getCity, setGetCity] = useState([]);
  const [getDistrict, setGetDistrict] = useState([]);

  //SELECT VALUE STATES
  const [country, setCountry] = useState({});
  const [city, setCity] = useState({});
  const [district, setDistrict] = useState({});
  const [keywords, setKeywords] = useState([]);

  //MAP STATE
  const [latLng, setLatLng] = useState({});

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=37`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.updatePermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/WareHouse/Get?id=${params.id}&customerID=${user.country}`
            )
            .then((res) => {
              setData(res.data);
              setName(res.data.name);
              setAddress(res.data.address);
              setDescription(res.data.description);
              const data = JSON.parse(res.data.keywords);
              const newData = data.map((item) => ({
                value: item,
                label: item,
                __isNew__: true,
              }));
              setKeywords(newData);
              setCountry({
                label: res.data.countryName,
                value: res.data.countryID,
              });
              setCity({ label: res.data.cityName, value: res.data.cityID });
              setDistrict({
                label: res.data.districtName,
                value: res.data.districtID,
              });
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get("/api/Common/GetParameters?parametername=WareHouseType")
            .then((res) => {
              const newDataType = res.data?.map((item) => ({
                value: item.parameterID,
                label: item.parameterValue2,
              }));
              setWareHouseTypes(newDataType);
            })
            .catch((err) => {
              console.log(err.data);
            });
          api
            .get("/api/Common/GetParameters?parametername=Country")
            .then((res) => {
              setGetCountry(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get("/api/Common/GetParameters?parametername=City")
            .then((res) => {
              setGetCity(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get("/api/Common/GetParameters?parametername=District")
            .then((res) => {
              setGetDistrict(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newData = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setCustomerKey(newData);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  // REACT-LEAFLET
  const handleClick = (e) => {
    const { lat, lng } = e.latlng;
    setLatLng({ lat, lng });
  };

  const MapComponent = () => {
    const map = useMapEvents({
      click: handleClick,
    });

    return null;
  };
  const ozelIcon = new L.Icon({
    iconUrl: icon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  const districtChange = (e) => {
    setLoading(true);
    setCity(e);
    api
      .get(`/api/Common/GetDistricts?city=${e.parameterValue1}`)
      .then((res) => {
        setGetDistrict(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      })
      .finally(() => setLoading(false));
  };

  //// SELECT OPTIONS /////////

  const countryOptions = getCountry?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
  }));

  const cityOptions = getCity?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
    parameterValue1: item.parameterValue1,
  }));

  const districtOptions = getDistrict?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
  }));

  const postData = {
    wareHouseID: params.id,
    customerID,
    name,
    address,
    cityID: city.value,
    districtID: district.value,
    countryID: country.value,
    latLng: JSON.stringify(latLng),
    type: 0,
    status: 0,
    viewIndex: 0,
    description,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Axios isteği yapılandırma
      await api.put("/api/WareHouse/Put/Put", postData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Depo güncellendi !",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/wareHouses");
    } catch (error) {
      console.error("Hata alındı: ", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${error}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data && wareHouseTypes) {
      const matchedID = wareHouseTypes.find((item) => item.value === data.type);

      setSelectedWareHouseType({
        value: matchedID?.value,
        label: matchedID?.label,
      });
    }
  }, [data, wareHouseTypes]);

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen w-[75%] bg-gray-100">

            <form
              onSubmit={handleSubmit}
              className="grid grid-cols-3 gap-7 items-center w-full"
            >
              <div className="mb-5 flex items-center gap-2">
              <FaStarOfLife size={15} />
                <h1 className="text-3xl font-semibold title">Depo Güncelle</h1>
              </div>
              <div className="col-span-3 flex items-center gap-[100px]">
                <MapContainer
                  center={[39.1417632, 34.1284977]}
                  zoom={5}
                  scrollWheelZoom={false}
                >
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  {latLng.lat && (
                    <Marker position={[latLng.lat, latLng.lng]} icon={ozelIcon}>
                      {/* Marker içerisinde ekstra içerik ekleyebilirsiniz */}
                      <Popup>
                        <span>Lat : {latLng?.lat}</span>
                        <br /> Lng: {latLng?.lng}
                      </Popup>
                    </Marker>
                  )}

                  <MapComponent />
                </MapContainer>
              </div>

              <div className="col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Depo Adı :
                </label>
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="col-span-1">
                <div className="flex flex-col">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="type"
                  >
                    Tip :
                  </label>
                  <Select
                    value={selectedWareHouseType}
                    onChange={(e) => setSelectedWareHouseType(e)}
                    options={wareHouseTypes}
                    id="type"
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                  />
                </div>
              </div>

              <div className="col-span-3">
                <label
                  htmlFor="title"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Adres :
                </label>
                <input
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  type="text"
                  id="title"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>

              <div className="col-span-1">
                <label
                  htmlFor="title"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Ülke :
                </label>
                <Select
                  value={country}
                  onChange={(e) => setCountry(e)}
                  options={countryOptions}
                  maxMenuHeight={200}
                  classNamePrefix="custom-select"
                />
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="title"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  İl :
                </label>
                <Select
                  value={city}
                  onChange={(e) => districtChange(e)}
                  options={cityOptions}
                  maxMenuHeight={200}
                  classNamePrefix="custom-select"
                />
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="title"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  İlçe :
                </label>
                <Select
                  value={district}
                  onChange={(e) => setDistrict(e)}
                  options={districtOptions}
                  maxMenuHeight={200}
                  classNamePrefix="custom-select"
                />
              </div>

              <div className="flex flex-col gap-[20px]"></div>

              <div className="col-span-3">
                <label
                  htmlFor="title"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Anahtar Kelimeler :
                </label>
                <CreatableSelect
                  isMulti
                  options={customerKey}
                  onChange={(e) => setKeywords(e)}
                  maxMenuHeight={200}
                  value={keywords}
                  classNamePrefix="custom-select"
                />
              </div>
              <div className="col-span-3">
                <label
                  htmlFor="title"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Açıklama :
                </label>
                <textarea
                  id="description"
                  type="text"
                  className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>

              <div className="col-span-3 flex justify-end gap-[20px]">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg my-5"
                >
                  Kaydet
                  <i className="fa-regular fa-floppy-disk ml-2"></i>
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default WareHouseEdit;
