import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import "../demands/demands.css";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api/api";
import Swal from "sweetalert2";
import { RiDeleteBin6Line, RiUserSettingsLine } from "react-icons/ri";
import { useAuth } from "../../context/AuthContext";
import * as XLSX from "xlsx";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa6";
import Footer from "../../components/bars/Footer";
import UserPdfModal from "./UserPdfModal";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";

const Users = () => {
  const { user } = useAuth();
  const [permissions, setPermissions] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=9`
        )
        .then((res) => {
          setPermissions(res.data);

          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(`/api/User/GetUsers?customerID=${user.country}`)
            .then((res) => setData(res.data))
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const handleDelete = async (userID, customerID) => {
    try {
      // await
      Swal.fire({
        title: "Silmek istediğinize emin misiniz?",
        text: "Bunu geri döndüremezsiniz!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, eminim !",
        cancelButtonText: "Kapat",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(`/api/User/DeleteUser/${userID}?customerID=${customerID}`)
            .then(() => {
              setData((prevData) =>
                prevData.filter((item) => item.userID !== userID)
              );
              Swal.fire({
                title: "Başarıyla silindi!",
                text: "Kullanıcı silindi.",
                icon: "success",
              });
            })
            .catch((error) => {
              console.error("Error occurred while deleting invoice:", error);
              Swal.fire({
                title: "Silme hatası!",
                text: "Kullanıcı silinemedi. Lütfen tekrar deneyin.",
                icon: "error",
              });
            });
        }
      });
    } catch (error) {
      console.error("Error occurred while handling delete operation:", error);
    }
  };

  // ITEMS TO EXCEL
  const handleExportData = async () => {
    setLoading(true);
    try {
      const token =
        sessionStorage.getItem("token") || localStorage.getItem("token");
      const response = await api.get(
        `/api/Common/GetDocumentNumber?customerID=${customerID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPdfData(response.data);

      const worksheet = XLSX.utils.json_to_sheet(data);

      // Örneğin, A, B, C sütunları için genişlik ayarı
      worksheet["!cols"] = [
        { wch: 5 }, // A sütunu genişliği
        { wch: 5 }, // B sütunu genişliği
        { wch: 10 }, // C sütunu genişliği
        { wch: 10 }, // D sütunu genişliği
        { wch: 10 }, // E sütunu genişliği
        { wch: 50 }, // F sütunu genişliği
        { wch: 20 }, // G sütunu genişliği
        { wch: 100 }, // H sütunu genişliği
        { wch: 60 }, // I sütunu genişliği
        { wch: 20 }, // J sütunu genişliği
        { wch: 20 }, // K sütunu genişliği
        { wch: 50 }, // L sütunu genişliği
        { wch: 20 }, // M sütunu genişliği
        { wch: 20 }, // N sütunu genişliği
        { wch: 20 }, // O sütunu genişliği
        { wch: 10 }, // P sütunu genişliği
        { wch: 10 }, // Q sütunu genişliği
        { wch: 10 }, // R sütunu genişliği
        { wch: 10 }, // S sütunu genişliği
        { wch: 20 }, // T sütunu genişliği
        { wch: 10 }, // U sütunu genişliği
        { wch: 10 }, // V sütunu genişliği
        { wch: 10 }, // W sütunu genişliği
        { wch: 100 }, // X sütunu genişliği
        { wch: 50 }, // Y sütunu genişliği
        { wch: 10 }, // Z sütunu genişliği
        { wch: 100 }, // AA sütunu genişliği
        { wch: 50 }, // AB sütunu genişliği
        { wch: 10 }, // AC sütunu genişliği
        { wch: 20 }, // AD sütunu genişliği
        { wch: 20 }, // AE sütunu genişliği
        { wch: 40 }, // AF sütunu genişliği
        { wch: 10 }, // AG sütunu genişliği
        // ve böyle devam eder...
      ];

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const fileName = `${response.data}.xlsx`; // Dosya adını ve uzantısını belirtiyoruz
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.error("Error fetching document number:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDocumentNumber = async () => {
    setLoading(true);
    try {
      const token =
        sessionStorage.getItem("token") || localStorage.getItem("token"); // Token'ı localStorage'dan alabilirsiniz
      const response = await api.get(
        `/api/Common/GetDocumentNumber?customerID=${customerID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Authorization header'ını ekliyoruz
          },
        }
      );

      // Mevcut veri ile API'den gelen veriyi birleştiriyoruz

      setPdfData(response.data);
    } catch (error) {
      console.error("Error fetching document number:", error);
    } finally {
      setLoading(false);
      setOpenPdfModal(true); // Modal'ı açıyoruz
    }
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="m-12 min-h-screen w-[90%]">
            <div className="add-button">
              {permissions.insertPermission && (
                <Link to={"/userAdd"}>
                  <button
                    type="button"
                    className="butonCl text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mr-2 mb-2"
                  >
                    Kullanıcı Oluştur
                    <i className="fa-solid fa-plus ml-2"></i>
                  </button>
                </Link>
              )}
                            <div className="mb-5 flex items-center gap-2">
                            <FaStarOfLife size={15}/>
                <h1 className="text-3xl font-semibold title">Kullanıcılar</h1>
              </div>
              <div className="flex justify-between">
                <input
                  className="w-96 block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                  type="text"
                  placeholder="Kullanıcı ara.."
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
                <div className="flex gap-5 mr-4">
                  <a
                    onClick={fetchDocumentNumber}
                    className="font-medium text-red-600 hover:underline cursor-pointer"
                  >
                    <FaRegFilePdf size={30} />
                  </a>
                  <button onClick={handleExportData}>
                    <a className="font-medium text-green-600 hover:underline">
                      <RiFileExcel2Line size={35} />
                    </a>
                  </button>
                </div>
              </div>
            </div>

            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-white uppercase bg-slate-900 sticky top-0">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Sicil No
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Kullanıcı Adı
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Adı
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Soyadı
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Mail
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Telefon
                    </th>
                    <th scope="col" className="px-6 py-3">
                      İslemler
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.length > 0 ? (
                    data
                      ?.filter(
                        (item) =>
                          item.registerNumber
                            ?.toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                          item.userName
                            ?.toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                          item.name
                            ?.toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                          item.surname
                            ?.toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                          item.email
                            ?.toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                          item.telephone
                            ?.toLowerCase()
                            .includes(searchTerm.toLowerCase())
                      )
                      .map((item, index) => (
                        <tr
                          key={index}
                          className="bg-white border-b hover:bg-gray-50"
                        >
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                          >
                            {item.registerNumber}
                          </th>
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-700 whitespace-nowrap"
                          >
                            {item.userName}
                          </th>
                          <td className="px-6 py-4">{item.name}</td>
                          <td className="px-6 py-4">{item.surname}</td>
                          <td className="px-6 py-4">{item.email}</td>
                          <td className="px-6 py-4">{item.telephone}</td>

                          <td className="flex items-center px-6 py-4 space-x-3">
                            {permissions.reportPermission && (
                              <Link to={`/userEdit/${item.userID}`}>
                                {" "}
                                <a className="font-medium text-blue-600 hover:underline">
                                  <RiUserSettingsLine size={20} />
                                </a>
                              </Link>
                            )}
                            {/* <Link to={`/userProducts/${item.userID}`}>
                             <a className="font-medium text-green-600 hover:underline">
                             <IoDocumentsOutline size={20}/>
                               </a>
                             </Link> */}
                            {permissions.deletePermission && (
                              <button
                                onClick={() => {
                                  handleDelete(item.userID, item.customerID);
                                }}
                              >
                                <a className="font-medium text-red-600 hover:underline">
                                  <RiDeleteBin6Line size={20} />
                                </a>
                              </button>
                            )}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td
                        colSpan={7}
                        className="px-6 py-4 text-center text-2xl font-medium"
                      >
                        Henüz kayıtlı bir veri yoktur.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      {openPdfModal === true && (
        <UserPdfModal
          data={data}
          pdfData={pdfData}
          onCancel={() => setOpenPdfModal(false)}
        />
      )}
      <Footer />
    </>
  );
};

export default Users;
