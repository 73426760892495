import React, { useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import "../demands/demands.css";
import WorkOrdersActive from "./WorkOrdersActive";
import WorkOrdersClosed from "./WorkOrdersClosed";
import Footer from "../../components/bars/Footer";
import PreProduction from "./PreProduction";

const WorkOrders = () => {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTabWorkOrders") || "WorkOrders"
  ); // Varsayılan olarak local storage'dan veya 'AssignerUser' olarak başlat

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    localStorage.setItem("activeTabWorkOrders", tabName); // Sekme değiştiğinde local storage'a kaydet
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />

        <div className="m-12 w-full min-h-screen">
          <div
            className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 mt-[-30px]"
          >
            <ul className="flex flex-wrap -mb-px ">
              <li className="me-2">
                <a
                  href="#"
                  className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                    activeTab === "PreProduction"
                      ? "text-blue-800 border-blue-300"
                      : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                  }`}
                  onClick={() => handleTabClick("PreProduction")}
                >
                  Ön İmalat
                </a>
              </li>
              <li className="me-2">
                <a
                  href="#"
                  className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                    activeTab === "WorkOrders"
                      ? "text-blue-800 border-blue-300"
                      : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                  }`}
                  onClick={() => handleTabClick("WorkOrders")}
                >
                 Üretim Planlama
                </a>
              </li>
              <li className="me-2">
                <a
                  href="#"
                  className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                    activeTab === "WorkOrdersClosed"
                      ? "text-blue-800 border-blue-300"
                      : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                  }`}
                  onClick={() => handleTabClick("WorkOrdersClosed")}
                >
                  Kapatılan Üretim Planlamaları
                </a>
              </li>
            </ul>
          </div>
          <div>
            {activeTab === "WorkOrders" && <WorkOrdersActive />}
            {activeTab === "WorkOrdersClosed" && <WorkOrdersClosed />}
            {activeTab === "PreProduction" && <PreProduction />}
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default WorkOrders;
