import Sidebars from "../../components/bars/Sidebar";
import Header from "../../components/bars/Header";
import React, { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import Switch from "react-switch";
import api from "../../api/api";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import { FaRegCopy } from "react-icons/fa";
import StockCardCopyModal from "./StockCardCopyModal";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";

const StockCardsEdit = () => {
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState(null);
  const [data, setData] = useState();
  const [stockCardID, setStockCardID] = useState(null);
  const [getBrands, setGetBrands] = useState(null);
  const [getCategories, setGetCategories] = useState(null);
  const [getVat, setGetVat] = useState(null);
  const [getUnitTypes, setGetUnitTypes] = useState(null);
  const [getStockTypes, setGetStockTypes] = useState(null);
  const [getStockStatus, setGetStockStatus] = useState(null);
  const [name, setName] = useState("");
  const [stockQuality, setStockQuality] = useState("");
  const [description, setDescription] = useState("");
  const [code, setCode] = useState("");
  const [barcode, setBarcode] = useState("");
  const [purchasePrice, setPurchasePrice] = useState(Number());
  const [salesPrice, setSalesPrice] = useState(Number());
  const [alertLevel, setAlertLevel] = useState(Number());
  const [size1, setSize1] = useState(Number());
  const [size2, setSize2] = useState(Number());
  const [size3, setSize3] = useState(Number());
  const [isAlert, setIsAlert] = useState(false);
  const [customerID, setCustomerID] = useState(Number());
  const [categoryList, setCategoryList] = useState([]);
  const [token, setToken] = useState(null);
  const [keywords, setKeywords] = useState([]);
  const [stockCardQuantity, setStockCardQuantity] = useState([]);
  const [customerKey, setCustomerKey] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [wareHouses, setWareHouses] = useState([]);
  const [selectedWareHouse, setSelectedWareHouse] = useState({});
  const [currency, setCurrency] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [openModule, setOpenModule] = useState(false);
  const [stockStatus, setStockStatus] = useState({
    value: "",
    label: "",
  });
  const [stockTypes, setStockTypes] = useState({
    value: "",
    label: "",
  });
  const [unitTypes, setUnitTypes] = useState({
    value: "",
    label: "",
  });
  const [brand, setBrand] = useState({
    value: "",
    label: "",
  });
  const [vat, setVat] = useState({
    value: "",
    label: "",
  });

  const handleChange = (nextChecked) => {
    setIsAlert(nextChecked);
  };

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=6`
        )
        .then((res) => {
          setPermissions(res.data);
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.reportPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/StockCard/Get?id=${params.id}&customerID=${user.country}`
            )
            .then((res) => {
              setData(res.data);
              setStockCardID(res.data.stockCardID);
              setName(res.data.name);
              setDescription(res.data.description);
              setBarcode(res.data.barcode);
              setCode(res.data.code);
              setSalesPrice(res.data.salesPrice);
              setPurchasePrice(res.data.purchasePrice);
              setStockQuality(res.data.stockQuality);
              setSize1(res.data.setSize1);
              setSize2(res.data.setSize2);
              setSize3(res.data.setSize3);
              setSelectedWareHouse({
                value: res.data.wareHouseID,
                label: res.data.wareHouseName,
              });
              // setUnitTypes({value:matchID?.parameterID, label:matchID?.parameterValue2})
              setVat({
                value: res.data.vat.toString(),
                label: res.data.vat.toString(),
              });
              setAlertLevel(res.data.alertLevel);
              setIsAlert(res.data.alert);
              setBrand({
                value: res.data.brandName,
                label: res.data.brandName,
              });
              setSelectedCurrency({
                value: res.data.currencyID,
                label: res.data.currency,
              });
              setStockTypes({
                value: res.data.type,
                label: res.data.typeString,
              });
              const data = JSON.parse(res.data.categoryList);
              if (data && data.length > 1) {
                const newData = data.map((item) => ({
                  value: item.CategoryID,
                  label: item.Name,
                }));
                setCategoryList(newData);
              } else {
                if (data && data.length === 1) {
                  const singleItem = {
                    value: data[0].CategoryID,
                    label: data[0].Name,
                  };
                  setCategoryList([singleItem]);
                } else {
                  setCategoryList([]);
                }
              }
              setDescription(res.data.description);
              const dataKey = JSON.parse(res.data.keywords);
              const newData = dataKey.map((item) => ({
                value: item,
                label: item,
                __isNew__: true,
              }));
              setKeywords(newData);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newData = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setCustomerKey(newData);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/StockCard/GetStockBrands/GetBrands?customerID=${user.country}`
            )
            .then((res) => {
              setGetBrands(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get(
              `/api/StockCard/GetStockCategories/GetCategories?customerID=${user.country}`
            )
            .then((res) => {
              setGetCategories(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get("/api/Common/GetVat")
            .then((res) => {
              setGetVat(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get("/api/Common/GetParameters?parametername=UnitType")
            .then((res) => {
              setGetUnitTypes(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get("/api/Common/GetParameters?parametername=StockType")
            .then((res) => {
              setGetStockTypes(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });
          api
            .get(
              `/api/WareHouse/GetStockCardQuantity?customerID=${user.country}&stockCardID=${params.id}`
            )
            .then((res) => {
              setStockCardQuantity(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get(
              `/api/WareHouse/GetWareHouses?customerID=${user.country}&pageNumber=1&pageSize=9999`
            )
            .then((res) => {
              console.log(res.data);
              const newDataWareHouses = res.data?.items.map((item) => ({
                value: item.wareHouseID,
                label: item.name,
              }));
              setWareHouses(newDataWareHouses);
            })
            .catch((err) => console.log(err));

          api
            .get("/api/Common/GetCurrencies")
            .then((res) => {
              const currencyData = res.data?.map((item) => ({
                value: item.currencyID,
                label: item.name,
              }));
              setCurrency(currencyData);
            })
            .catch((err) => console.log(err.data));

          api
            .get("/api/Common/GetParameters?parametername=StockStatus")
            .then((res) => {
              setGetStockStatus(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            })
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  useEffect(() => {
    const total = stockCardQuantity.reduce(
      (acc, item) => acc + item.quantity,
      0
    );
    setTotalQuantity(total);
  }, [stockCardQuantity]);

  const brandsName = getBrands?.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const categoriesName = getCategories?.map((item) => ({
    value: item.categoryID,
    label: item.name,
  }));
  const vatName = getVat?.map((item) => ({
    value: item,
    label: item,
  }));

  const unitTypeName = getUnitTypes?.map((item) => ({
    value: item.parameterID,
    label: item.parameterValue2,
  }));
  const stockTypeName = getStockTypes?.map((item) => ({
    value: item.parameterID,
    label: item.parameterValue2,
  }));
  const stockStatusName = getStockStatus?.map((item) => ({
    value: item.parameterID,
    label: item.parameterValue2,
  }));

  const find = getBrands?.find((item) => item.name === brand.value);
  const categoryId = categoryList?.map((item) => item.value);
  const stringCatId = JSON.stringify(categoryId);

  // const newCategoryList = categoryId?.map((item)=>item.value)
  const handleChangeFile = (event) => {
    setFile(event.target.files);
  };

  const postData = {
    stockCardID,
    customerID: Number(customerID),
    brandID: find?.brandID,
    wareHouseID: selectedWareHouse.value,
    categoryList: stringCatId,
    currencyID: selectedCurrency.value,
    code,
    name,
    barcode,
    status: Number(stockStatus.value),
    alert: isAlert,
    alertLevel: Number(alertLevel),
    viewIndex: 0,
    description,
    salesPrice: Number(salesPrice),
    purchasePrice: Number(purchasePrice),
    vat: Number(vat.value),
    type: Number(stockTypes.value),
    unitType: unitTypes.value,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    stockQuality,
    size1,
    size2,
    size3,
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();

    // Dosya yüklenmişse formData'ya ekle
    if (file && file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append(`file`, file[i]);
      }
    }

    api
      .put("/api/StockCard/Put/Put", postData)
      .then((res) => {
        // Dosya yüklenmişse dosyaları yükle
        if (file && file.length > 0) {
          api
            .post(
              `/api/FileProcess/UploadFiles/UploadFiles?customerID=${customerID}&type=120&relationID=${stockCardID}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(() => {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Ürün güncellendi !",
                showConfirmButton: false,
                timer: 1500,
              });
              navigate("/stockCards");
            })
            .catch((error) => {
              console.error(error);
              Swal.fire("Ürün güncellenemedi !");
            });
        } else {
          // Dosya yüklenmemişse direkt olarak bildirim göster
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Ürün güncellendi !",
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/stockCards");
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Ürün güncellenemedi !");
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    if (getUnitTypes && data && getStockTypes && getStockStatus) {
      const matchedID = getUnitTypes.find(
        (item) => item.parameterID === data.unitType
      );
      setUnitTypes({
        value: matchedID?.parameterID,
        label: matchedID?.parameterValue2,
      });

      const matchedTypeID = getStockTypes.find(
        (item) => item.parameterID === data.type
      );
      setStockTypes({
        value: matchedTypeID?.parameterID,
        label: matchedTypeID?.parameterValue2,
      });

      const matchedStatusID = getStockStatus.find(
        (item) => item.parameterID === data.status
      );
      setStockStatus({
        value: matchedStatusID?.parameterID,
        label: matchedStatusID?.parameterValue2,
      });
    }
  }, [getUnitTypes, data, getStockTypes, getStockStatus]);

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen w-[75%] bg-gray-100">
            {permissions.insertPermission && (
              <div className="absolute right-0 pr-24">
                <a
                  onClick={() => {
                    setOpenModule(true);
                  }}
                  className="text-lg font-medium text-red-600 hover:underline flex gap-2 cursor-pointer"
                >
                  <FaRegCopy size={25} />
                  Ürün kopyala.
                </a>
              </div>
            )}

            <div className="mb-5 flex items-center gap-2">
              <FaStarOfLife size={15} />
              <h1 className="text-3xl font-semibold title">Ürün Güncelle</h1>
            </div>
            <form
              onSubmit={handleSubmit}
              className="grid grid-cols-2 gap-7 items-center w-full"
            >
              <div className="col-span-2">
                <input
                  className="block w-54 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                  type="file"
                  onChange={handleChangeFile}
                  multiple
                />
              </div>
              <div>
                <label
                  htmlFor="title"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Adı :
                </label>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  id="title"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Açıklama :
                </label>
                <input
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  type="text"
                  id="description"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
              <div>
                <label
                  htmlFor="code"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Kodu :
                </label>
                <input
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  type="text"
                  id="code"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <div className="flex items-end justify-between">
                <div>
                  <label
                    htmlFor="ProductCode"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Ürün Barkodu :
                  </label>
                  <input
                    value={barcode}
                    onChange={(e) => setBarcode(e.target.value)}
                    type="number"
                    id="ProductCode"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  />
                </div>
                <div className="w-[200px]">
                  <label
                    htmlFor="stockType"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Tip :
                  </label>
                  <Select
                    id="stockType"
                    options={stockTypeName}
                    value={stockTypes}
                    onChange={(e) => setStockTypes(e)}
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                  />
                </div>
                <div className="w-[105px]">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="unitTypes"
                  >
                    Birim :
                  </label>
                  <Select
                    id="unitTypes"
                    options={unitTypeName}
                    value={unitTypes}
                    onChange={(e) => setUnitTypes(e)}
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="salesPrice"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Satış Fiyatı :
                </label>
                <input
                  value={salesPrice}
                  onChange={(e) => setSalesPrice(e.target.value)}
                  type="number"
                  id="salesPrice"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>

              <div className="flex items-end justify-between">
                <div>
                  <label
                    htmlFor="purchasePrice"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Alış Fiyatı :
                  </label>
                  <input
                    value={purchasePrice}
                    onChange={(e) => setPurchasePrice(e.target.value)}
                    type="number"
                    id="purchasePrice"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
                <div className="w-[200px]">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="stockStatus"
                  >
                    Durum :
                  </label>
                  <Select
                    id="stockStatus"
                    options={stockStatusName}
                    value={stockStatus}
                    onChange={(e) => setStockStatus(e)}
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                  />
                </div>
                <div className="w-[105px]">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="vatRate"
                  >
                    KDV Oranı :
                  </label>
                  <Select
                    id="vatRate"
                    options={vatName}
                    value={vat}
                    onChange={(e) => setVat(e)}
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="brand">Marka :</label>
                <Select
                  value={brand}
                  onChange={(e) => setBrand(e)}
                  options={brandsName}
                  id="brand"
                  maxMenuHeight={200}
                  classNamePrefix="custom-select"
                />
              </div>

              <div className="flex items-center gap-[30px] justify-between">
                <div className="w-[200px]">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="brand"
                  >
                    Para Birimi :
                  </label>
                  <Select
                    maxMenuHeight={200}
                    options={currency}
                    value={selectedCurrency}
                    onChange={(e) => setSelectedCurrency(e)}
                    classNamePrefix="custom-select"
                  />
                </div>
                <div>
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor=""
                  >
                    Stok Adet :
                  </label>
                  <input
                    value={alertLevel}
                    onChange={(e) => setAlertLevel(e.target.value)}
                    type="number"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5"
                    required
                  />
                </div>
                <div className="flex flex-col">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor=""
                  >
                    Bildirim :
                  </label>
                  <Switch checked={isAlert} onChange={handleChange} />
                </div>
              </div>
              <div>
                <label htmlFor="categories">Kategoriler :</label>
                <Select
                  id="categories"
                  options={categoriesName}
                  isMulti
                  name="colors"
                  classNamePrefix="custom-select"
                  value={categoryList}
                  onChange={(e) => setCategoryList(e)}
                  maxMenuHeight={200}
                />
              </div>
              <div className="flex items-center justify-between">
                <div>
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="size1"
                  >
                    Ölçü 1 :
                  </label>
                  <input
                    type="number"
                    className="bg-red-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5"
                    required
                    id="size1"
                    value={size1}
                    onChange={(e) => setSize1(e.target.value)}
                  />
                  <p className="text-xs text-red-400 mt-2 ml-1 font-medium absolute">
                    Bu ölçü birimi üretim planlamada arama kriteri olarak
                    kullanılacaktır.
                  </p>
                </div>

                <div>
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="size2"
                  >
                    Ölçü 2 :
                  </label>
                  <input
                    type="number"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5"
                    required
                    id="size2"
                    value={size2}
                    onChange={(e) => setSize2(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="size3"
                  >
                    Ölçü 3 :
                  </label>
                  <input
                    type="number"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5"
                    required
                    id="size3"
                    value={size3}
                    onChange={(e) => setSize3(e.target.value)}
                  />
                </div>
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Varsayılan Depo Seçimi :
                </label>
                <Select
                  maxMenuHeight={200}
                  options={wareHouses}
                  value={selectedWareHouse}
                  onChange={(e) => setSelectedWareHouse(e)}
                  classNamePrefix="custom-select"
                />
              </div>
              <div>
                <label
                  htmlFor="title"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Ürün Kalitesi :
                </label>
                <input
                  value={stockQuality}
                  onChange={(e) => setStockQuality(e.target.value)}
                  type="text"
                  id="title"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="visitors"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Anahtar Kelimeler
                </label>
                <CreatableSelect
                  value={keywords}
                  onChange={(e) => setKeywords(e)}
                  options={customerKey}
                  isMulti
                  maxMenuHeight={200}
                  classNamePrefix="custom-select"
                />
              </div>
              {permissions.updatePermission && (
                <div className="col-span-2 flex justify-end gap-[20px]">
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg m-y5"
                  >
                    Kaydet
                    <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              )}
            </form>
            <div className="relative overflow-x-auto my-10">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-white uppercase bg-slate-900 sticky top-0">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Depo Adı
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Birim
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Tutar
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {stockCardQuantity?.map((item, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b hover:bg-gray-50"
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        {item.wareHouseName}
                      </th>
                      <td className="px-6 py-4">{item.unitName}</td>
                      <td className="px-6 py-4">{item.quantity}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex justify-end mx-5 mt-5">
                <div className="resultMoneyInfo pb-3">
                  <h1 className="font-medium">Toplam :</h1>
                  <p className="font-semibold text-green-600">
                    {totalQuantity}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {openModule === true && (
        <StockCardCopyModal
          onCancel={() => setOpenModule(false)}
          onSubmitCancel={() => {
            setOpenModule(false);
            navigate("/stockCards");
          }}
          data={data}
        />
      )}
      <Footer />
    </>
  );
};

export default StockCardsEdit;
