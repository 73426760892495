import React, { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai/";
import AddProduct from "./AddProduct";
import PutProduct from "./PutProduct";
import DeleteProduct from "./DeleteProduct";
import AddAccount from "./accounts/AddAccount";
import PutAccount from "./accounts/PutAccount";
import DeleteAccount from "./accounts/DeleteAccount";

const SideBarRight = ({ onCancel = () => {} }) => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openPutModal, setOpenPutModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAccountAddModal, setOpenAccountAddModal] = useState(false);
  const [openAccountPutModal, setOpenAccountPutModal] = useState(false);
  const [openAccountDeleteModal, setOpenAccountDeleteModal] = useState(false);
  return (
    <>
      <div className="absolute top-0 right-0 w-[220px] h-full bg-white z-[1000] flex flex-col gap-8 border-l border-gray-300 items-end">
        <div>
          <div className="text-3xl float-right pt-2">
            <AiOutlineCloseCircle
              color="black"
              cursor="pointer"
              onClick={onCancel}
            />
          </div>
          <div className="flex flex-col mt-[70px] gap-5 mr-5">
            <h1 className="border-b border-gray-300 text-lg font-medium text-black">
              Ürünler
            </h1>
            <button
              onClick={() => setOpenAddModal(true)}
              type="button"
              className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              Ürün Ekle
            </button>
            <button
              onClick={() => setOpenPutModal(true)}
              type="button"
              className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              Ürün Güncelle
            </button>
            <button
              onClick={() => setOpenDeleteModal(true)}
              type="button"
              className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              Ürün Sil
            </button>
            <h1 className="border-b border-gray-400 text-[17px] font-medium text-black">
              Müşteriler
            </h1>
            <button
              onClick={() => setOpenAccountAddModal(true)}
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              Müşteri Ekle
            </button>
            <button
              onClick={() => setOpenAccountPutModal(true)}
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              Müşteri Güncelle
            </button>
            <button
              onClick={() => setOpenAccountDeleteModal(true)}
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              Müşteri Sil
            </button>
          </div>
        </div>
      </div>
      {openAddModal === true && (
        <AddProduct onCancel={() => setOpenAddModal(false)} />
      )}
      {openPutModal === true && (
        <PutProduct onCancel={() => setOpenPutModal(false)} />
      )}
      {openDeleteModal === true && (
        <DeleteProduct onCancel={() => setOpenDeleteModal(false)} />
      )}
      {openAccountAddModal === true && (
        <AddAccount onCancel={() => setOpenAccountAddModal(false)} />
      )}
      {openAccountPutModal === true && (
        <PutAccount onCancel={() => setOpenAccountPutModal(false)} />
      )}
      {openAccountDeleteModal === true && (
        <DeleteAccount onCancel={() => setOpenAccountDeleteModal(false)} />
      )}
    </>
  );
};

export default SideBarRight;
