import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Select from "react-select";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";

const OperationAdd = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(Number());
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [stockCards, setStockCards] = useState([]);
  const [operationGroups, setOperationGroups] = useState([]);
  const [operationStatusOptions, setOperationStatusOptions] = useState([]);
  const [selectedOperationStatus, setSelectedOperationStatus] = useState({
    value: 0,
    label: "Operasyon Durumunu Seçiniz...",
  });
  const [token, setToken] = useState(null);
  //FORM
  const [operationProductionItems, setOperationProductionItems] = useState([]);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [notes, setNotes] = useState("");
  const [operationGroupID, setOperationGroupID] = useState({});
  const [keywords, setKeywords] = useState([]);
  const [keywordsData, setKeywordsData] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=24`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.insertPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/StockCard/GetStockCards?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              const dataStockCards = res.data.items;
              const newDataStockCards = dataStockCards?.map((item) => ({
                value: item.stockCardID,
                label: `${item.code} - ${item.name}`,
              }));
              setStockCards(newDataStockCards);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/Operation/GetoperationGroups?customerID=${user.country}`)
            .then((res) => {
              const dataOperationGroups = res.data;
              const newDataOperationGroups = dataOperationGroups?.map(
                (item) => ({
                  value: item.operationGroupID,
                  label: item.name,
                })
              );
              setOperationGroups(newDataOperationGroups);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/Common/GetParameters?parametername=OperationStatus`)
            .then((res) => {
              const dataOperationsStatus = res.data;
              const newDataOperationGroups = dataOperationsStatus?.map(
                (item) => ({
                  value: item.parameterID,
                  label: item.parameterValue2,
                })
              );
              setOperationStatusOptions(newDataOperationGroups);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/Common/GetLocations?customerID=${user.country}`)
            .then((res) => {
              let dataLocations = res.data;
              let newDataLocations = dataLocations.map((item) => ({
                value: item,
                label: item,
              }));
              setLocations(newDataLocations);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newDataKey = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setKeywordsData(newDataKey);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const handleServiceRemove = (index) => {
    const list = [...operationProductionItems];
    list.splice(index, 1);
    setOperationProductionItems(list);
  };

  const handleServiceAdd = () => {
    setOperationProductionItems([
      ...operationProductionItems,
      {
        stockCardID: Number(),
        productionCapacityPerHour: Number(),
        productionCapacityPerMinute: Number(),
        name: "",
        title: "",
        type: Number(),
        status: "",
      },
    ]);
  };

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...operationProductionItems];

    // Saatlik kapasite değiştiğinde dakika başına kapasiteyi hesapla
    if (name === "productionCapacityPerHour") {
      const productionCapacityPerMinute = value / 60;
      list[index]["productionCapacityPerMinute"] = productionCapacityPerMinute;
    }

    list[index][name] = value;
    setOperationProductionItems(list);
  };

  const handleSelectChange = (e, index) => {
    const list = [...operationProductionItems];
    list[index].stockCardID = e;
    setOperationProductionItems(list);
  };

  const postData = {
    customerID,
    operationGroupID: operationGroupID?.value,
    name,
    title,
    location: selectedLocation?.label,
    type: 0,
    status: selectedOperationStatus?.value,
    maintanceStatus: 0,
    description,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    notes,
    operationProductionItems,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Axios isteği yapılandırma
      await api.post("/api/Operation/Post/Post", postData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Operasyon oluşturuldu !",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/operations");
    } catch (error) {
      console.error("Hata alındı: ", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${error}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen w-[75%] bg-gray-100">
              <div className="mb-5 flex items-center gap-2">
              <FaStarOfLife size={15}/>
              <h1 className="text-3xl font-semibold title">
                Operasyon Oluştur
              </h1>
            </div>
            <form
              className="grid grid-cols-4 gap-7 items-center w-full"
              onSubmit={handleSubmit}
            >
              <div className="col-span-2">
                <label
                  htmlFor="title"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Başlık :
                </label>
                <input
                  type="text"
                  id="title"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Adı :
                </label>
                <input
                  type="text"
                  id="name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Operasyon Grubu :
                </label>
                <Select
                  value={operationGroupID}
                  onChange={(e) => setOperationGroupID(e)}
                  options={operationGroups}
                  maxMenuHeight={200}
                  classNamePrefix="custom-select"
                />
              </div>

              <div className="col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Durum :
                </label>
                <Select
                  maxMenuHeight={200}
                  options={operationStatusOptions}
                  value={selectedOperationStatus}
                  onChange={(e) => setSelectedOperationStatus(e)}
                  classNamePrefix="custom-select"
                />
              </div>

              <div className="col-span-2">
                <label
                  htmlFor="keywords"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Anahtar Kelimeler :
                </label>
                <CreatableSelect
                  isMulti
                  maxMenuHeight={200}
                  id="keywords"
                  onChange={(e) => setKeywords(e)}
                  options={keywordsData}
                  classNamePrefix="custom-select"
                />
              </div>

              <div className="col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Lokasyon :
                </label>
                <Select
                  value={selectedLocation}
                  onChange={(e) => setSelectedLocation(e)}
                  options={locations}
                  maxMenuHeight={200}
                  classNamePrefix="custom-select"
                />
              </div>
              <div className="col-span-3">
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Açıklama :
                </label>
                <textarea
                  id="description"
                  type="text"
                  className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="note"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Not :
                </label>
                <textarea
                  id="note"
                  type="text"
                  className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  rows={4}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </div>

              <div className="relative overflow-x-auto mb-5 col-span-4 mt-12">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-white uppercase bg-slate-900">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Ürün
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Saatlik Üretim Adedi
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Dakikalık Üretim Adedi
                      </th>
                      <th scope="col" className="px-6 py-3">
                        İşlem
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {operationProductionItems.map((singleService, index) => (
                      <tr key={index} className="bg-white border-b">
                        <td className="px-6 py-4">
                          <div>
                            <Select
                              onChange={(e) =>
                                handleSelectChange(e.value, index)
                              }
                              options={stockCards}
                              maxMenuHeight={200}
                              classNamePrefix="custom-select"
                            />
                          </div>
                        </td>

                        <td className="px-6 py-4">
                          <input
                            value={singleService.productionCapacityPerHour}
                            onChange={(e) => handleServiceChange(e, index)}
                            name="productionCapacityPerHour"
                            type="number"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required
                          />
                        </td>
                        <td className="px-6 py-4">
                          <input
                            value={singleService.productionCapacityPerMinute}
                            onChange={(e) => handleServiceChange(e, index)}
                            name="productionCapacityPerMinute"
                            type="number"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required
                          />
                        </td>

                        <td className="px-6 py-4">
                          <button
                            onClick={() => handleServiceRemove(index)}
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg "
                          >
                            <i className="fa-regular fa-trash-can"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <button
                    onClick={handleServiceAdd}
                    type="button"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg m-5"
                  >
                    Ekle
                    <i className="fa-solid fa-plus ml-2"></i>
                  </button>
                </table>
              </div>

              <div className="col-span-4 flex justify-end gap-[20px]">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg my-5"
                >
                  Kaydet
                  <i className="fa-regular fa-floppy-disk ml-2"></i>
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default OperationAdd;
