import React, { useEffect, useState } from "react";
import Select from "react-select";
import api from "../../api/api";
import Swal from "sweetalert2";
import { useAuth } from "../../context/AuthContext";
import LoadingSpinnerModal from "../LoadingSpinnerModal";

const JobRemove = ({ onCancel = () => {}, onSubmitCancel = () => {} }) => {
  const { user } = useAuth();
  const [data, setData] = useState([]);
  const [customerID, setCustomerID] = useState(null);
  const [userID, setUserID] = useState(null);
  const [selectedJob, setSelectedJob] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user && user.country) {
      setUserID(user.nameidentifier);
      setCustomerID(user.country);

      api
        .get(`/api/User/GetJobs?customerID=${user.country}`)
        .then((res) => {
          const dataJob = res.data;
          const newData = dataJob?.map((item) => ({
            value: item.jobID,
            label: item.name,
          }));
          setData(newData);
        })
        .catch((err) => console.log(err.data))
        .finally(() => setLoading(false));
    }
  }, [user]);

  const handleDelete = async () => {
    try {
      Swal.fire({
        title: "Silmek istediğinize emin misiniz?",
        text: "Bunu geri döndüremezsiniz!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, eminim !",
        cancelButtonText: "Kapat",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(
              `/api/User/DeleteJob/DeleteJob?customerId=${customerID}&jobID=${selectedJob.value}`
            )
            .then(() => {
              Swal.fire({
                title: "Başarıyla silindi!",
                text: "Silindi.",
                icon: "success",
              });
              onSubmitCancel();
            })
            .catch((error) => {
              console.error("Error occurred while deleting invoice:", error);
              Swal.fire({
                title: "Birim altında personel mevcut!",
                icon: "error",
              });
            });
        }
      });
    } catch (error) {
      console.error("Error occurred while handling delete operation:", error);
    }
  };

  return (
    <>
      {loading ? (
      <LoadingSpinnerModal loading={loading} />
      ) : (
        <div className="fixed inset-0 w-screen h-screen bg-black bg-opacity-30 z-50 flex justify-center items-center">
          <div className="w-3/5 p-8 bg-white rounded-lg flex flex-col justify-center items-center">
            <form className="grid grid-cols-4 gap-7 items-center">
              <div className="col-span-4">
                <label
                  htmlFor="job"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Silinecek Birim Seç :
                </label>
                <Select
                  id="job"
                  options={data}
                  maxMenuHeight={200}
                  value={selectedJob}
                  onChange={(e) => setSelectedJob(e)}
                />
              </div>
              <div className="col-span-4 flex justify-center gap-[20px]">
                <button
                  onClick={onCancel}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg m-5"
                >
                  Vazgeç
                </button>
                <button
                  onClick={() => handleDelete()}
                  type="button"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg m-5"
                >
                  Onayla
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default JobRemove;
