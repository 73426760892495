import React, { useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import "../demands/demands.css";
import WorkOrderSlips from "./shippingSlipsTabs/WorkOrderSlips";
import WarehouseTransferSlips from "./shippingSlipsTabs/WarehouseTransferSlips";
import Footer from "../../components/bars/Footer";

const ShippingSlips = () => {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTabShippingSlips") || "WarehouseTransferSlips"
  ); // Varsayılan olarak local storage'dan veya 'AssignerUser' olarak başlat

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    localStorage.setItem("activeTabShippingSlips", tabName); // Sekme değiştiğinde local storage'a kaydet
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />

        <div className="m-12 w-full min-h-screen">
          <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 mt-[-30px]">
            <ul className="flex flex-wrap -mb-px ">
              <li className="me-2">
                <a
                  href="#"
                  className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                    activeTab === "WarehouseTransferSlips"
                      ? "text-blue-800 border-blue-300"
                      : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                  }`}
                  onClick={() => handleTabClick("WarehouseTransferSlips")}
                >
                  Depo Transfer Fişleri
                </a>
              </li>
              <li className="me-2">
                <a
                  href="#"
                  className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                    activeTab === "WorkOrderSlips"
                      ? "text-blue-800 border-blue-300"
                      : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                  }`}
                  onClick={() => handleTabClick("WorkOrderSlips")}
                >
                  İş Emri Fişleri
                </a>
              </li>
            </ul>
          </div>
          <div>
            {activeTab === "WorkOrderSlips" && <WorkOrderSlips />}
            {activeTab === "WarehouseTransferSlips" && (
              <WarehouseTransferSlips />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ShippingSlips;
