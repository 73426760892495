import React, { memo, useEffect, useState } from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';
import api from '../../api/api';
import { useAuth } from '../../context/AuthContext';



function Select({ value, handleId, nodeId }) {
  const { user } = useAuth();
  const [data,setData] = useState(null)
  const [isChecked,setIsChecked] = useState(false)
  useEffect(() => {
    if  (user && user.country){

    api
      .get(`/api/FormBuilder/GetForms?customerID=${user.country}&pageNumber=1&pageSize=99999`)
      .then((res) =>{
        const datas = res.data.items
        const newData = datas.map((item) => ({
          value: item.formID,
          label: item.name,
        }));
        setData(newData)
      })
      .catch((err) => console.log(err.data))
    }
  }, [user]);



  const { setNodes } = useReactFlow();
  const store = useStoreApi();

  const onChange = (evt) => {
    const { nodeInternals } = store.getState();
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...node.data,
            formID: {
              ...node.data.formID,
              [handleId]: evt.target.value,
            },
          };
        }

        return node;
      })
    );
  };

  const checkChange = (evt)=>{
    const { nodeInternals } = store.getState();
    const newCheckedValue = evt.target.checked;

    setIsChecked(newCheckedValue);
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...node.data,
            checked: {
              ...node.data.checked,
              [handleId]: newCheckedValue,
            },
          };
        }

        return node;
      })
    )
  }
  return (
    <div className="custom-node__select">
      <Handle style={{top:"24px", left:"-15px",width:"6px", height:"10px",borderRadius:"2px",backgroundColor:"#778899"}} type='target' position={Position.Left} id={handleId}/>
      <div style={{fontSize:"12px", fontWeight:"500", paddingRight:"10px", width:"100px"}}>Seçiniz</div>
      <select className="nodrag" onChange={onChange} value={value}>
        {data?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className='mt-2 flex items-center gap-1'>
        <label className='text-xs' htmlFor="required">Zorunlu</label>
        <input onChange={checkChange} checked={isChecked} type="checkbox" id="required" />
        </div>
      <Handle style={{top:"24px", right:"-15px",width:"6px", height:"10px",borderRadius:"2px",backgroundColor:"#778899"}} type="source" position={Position.Right} id={handleId} />
    </div>
  );
}

function CustomNodeForm({ id, data }) {
  console.log("data",data);
  return (
    <>
   <div style={{backgroundColor:"#f5f5f6",borderRadius:"10px"}}>
   <div className="custom-node__header">
        This is a <strong>{data.label}</strong>
      </div>
      <div className="custom-node__body">
        {Object.keys(data.formID).map((handleId) => (
          <Select key={handleId} nodeId={id} value={data.formID[handleId]} handleId={handleId} />
        ))}
      </div>
      
   </div>
    </>
  );
}

export default memo(CustomNodeForm);













// import React, { memo, useState } from "react";
// import { Handle, Position } from "reactflow";

// function CustomNodeForm({ data, isConnectable }) {
//   const [selectedOption, setSelectedOption] = useState("");
//   const [selectedOption2, setSelectedOption2] = useState("");

//   // Seçeneklerin bulunduğu dizi
//   const options = ["ISO-22001:1 Finans-Teklif", "ISO-9001 Talep-Takip"];

//   // Select değiştiğinde çağrılacak fonksiyon
//   const handleSelectChange = (event) => {
//     const value = event.target.value;
//     setSelectedOption(value);
//   };
//   const handleSelectChange2 = (event) => {
//     const value = event.target.value;
//     setSelectedOption2(value);
//   };
//   return (
//     <>
//     <div className="custom-action">
//       <Handle
//         type="target"
//         position={Position.Left}
//         style={{ background: "#555" }}
//         onConnect={(params) => console.log("handle onConnect", params)}
//         isConnectable={isConnectable}
//       />
//       <div style={{ textAlign: "center", margin: "10px 0" }}>
//         <h1 style={{ fontWeight: "500", fontSize: "20px" }}>{data.label}</h1>
//       </div>
//       <div className="mt-6">
//         <h2 style={{fontWeight:"500", fontSize:"13px"}}>Gönderim Tipi:</h2>
//         <select
//           id="selectOptions"
//           onChange={handleSelectChange}
//           value={selectedOption}
//           className="w-full text-center mb-6"
//           style={{ fontSize: "15px", marginTop:"5px" }}
//         >
//           <option value="">Seçiniz</option>
//           {options.map((option, index) => (
//             <option key={index} value={option}>
//               {option}
//             </option>
//           ))}
//         </select>
//       <div className="flex gap-2 float-right">
//         <label htmlFor="form">Zorunlu</label>
//         <input id="form" type="checkbox" />
//       </div>
//       </div>
    
//       <Handle
//         type="source"
//         position={Position.Right}
//         id="a"
//         style={{ background: "#555" }}
//         isConnectable={isConnectable}
//       />
//     </div>
//   </>
//   );
// }

// export default memo(CustomNodeForm);


















// export default memo(({ data, isConnectable }) => {
//   const [selectedOption, setSelectedOption] = useState("");
//   const [selectedOption2, setSelectedOption2] = useState("");

//   // Seçeneklerin bulunduğu dizi
//   const options = ["ISO-22001:1 Finans-Teklif", "ISO-9001 Talep-Takip"];

//   // Select değiştiğinde çağrılacak fonksiyon
//   const handleSelectChange = (event) => {
//     const value = event.target.value;
//     setSelectedOption(value);
//   };
//   const handleSelectChange2 = (event) => {
//     const value = event.target.value;
//     setSelectedOption2(value);
//   };
//   return (
//     <>
//       <div className="custom-action">
//         <Handle
//           type="target"
//           position={Position.Left}
//           style={{ background: "#555" }}
//           onConnect={(params) => console.log("handle onConnect", params)}
//           isConnectable={isConnectable}
//         />
//         <div style={{ textAlign: "center", margin: "10px 0" }}>
//           <h1 style={{ fontWeight: "500", fontSize: "20px" }}>{data.label}</h1>
//         </div>
//         <div className="mt-6">
//           <h2 style={{fontWeight:"500", fontSize:"13px"}}>Gönderim Tipi:</h2>
//           <select
//             id="selectOptions"
//             onChange={handleSelectChange}
//             value={selectedOption}
//             className="w-full text-center mb-6"
//             style={{ fontSize: "15px", marginTop:"5px" }}
//           >
//             <option value="">Seçiniz</option>
//             {options.map((option, index) => (
//               <option key={index} value={option}>
//                 {option}
//               </option>
//             ))}
//           </select>
//         <div className="flex gap-2 float-right">
//           <label htmlFor="form">Zorunlu</label>
//           <input id="form" type="checkbox" />
//         </div>
//         </div>
      
//         <Handle
//           type="source"
//           position={Position.Right}
//           id="a"
//           style={{ background: "#555" }}
//           isConnectable={isConnectable}
//         />
//       </div>
//     </>
//   );
// });

// import React, { memo } from 'react';
// import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';

// const options = [
//   {
//     value: 'smoothstep',
//     label: 'Smoothstep',
//   },
//   {
//     value: 'step',
//     label: 'Step',
//   },
//   {
//     value: 'default',
//     label: 'Bezier (default)',
//   },
//   {
//     value: 'straight',
//     label: 'Straight',
//   },
// ];

// function Select({ value, handleId, nodeId }) {
//   const { setNodes } = useReactFlow();
//   const store = useStoreApi();

//   const onChange = (evt) => {
//     const { nodeInternals } = store.getState();
//     setNodes(
//       Array.from(nodeInternals.values()).map((node) => {
//         if (node.id === nodeId) {
//           node.data = {
//             ...node.data,
//             selects: {
//               ...node.data.selects,
//               [handleId]: evt.target.value,
//             },
//           };
//         }

//         return node;
//       })
//     );
//   };

//   return (
//     <div className="custom-node__select">
//       <div>Edge Type</div>
//       <select className="nodrag" onChange={onChange} value={value}>
//         {options.map((option) => (
//           <option key={option.value} value={option.value}>
//             {option.label}
//           </option>
//         ))}
//       </select>
//       <Handle position={Position.Right} id={handleId} />
//     </div>
//   );
// }

// function CustomNode({ id, data }) {
//   return (
//     <>
//       <div className="custom-node__header">
//         This is a <strong>custom node</strong>
//       </div>
//       <div className="custom-node__body">
//         {Object.keys(data.selects).map((handleId) => (
//           <Select key={handleId} nodeId={id} value={data.selects[handleId]} handleId={handleId} />
//         ))}
//       </div>
//     </>
//   );
// }

// export default memo(CustomNode);
