import React, { useEffect, useState } from "react";
import "../home/home.css";
import DashboardChart from "../../components/views/DashboardChart";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import ColumnChart from "../../components/views/ColumnChart";
import WelcomeCard from "../../components/views/WelcomeCard";
import AssignmentCard from "../../components/views/AssignmentCard";
import { useDispatch, useSelector } from "react-redux";
import { getNotification, getUser } from "../../redux/userDataSlice";
import PersonalChart from "../../components/views/PersonalChart";
import TaskGraph from "../../components/views/TaskGraph";
import Footer from "../../components/bars/Footer";
import WorkOrdersGraph from "../../components/views/WorkOrdersGraph";
import WorkOrderContinue from "../../components/views/WorkOrderContinue";
import LoadingSpinner from "../LoadingSpinner";

const Home = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state);

  useEffect(() => {
    dispatch(getUser());
    dispatch(getNotification());
  }, []);

  return (
    <>
      <Header />
      <div className="flex min-h-screen">
        <Sidebars />
        {data.isLoading ? (
          <LoadingSpinner loading={data.isLoading} />
        ) : (
          <div className="grid md:gap-7 md:grid-cols-2 lg:grid-cols-4 lg:gap-7 items-center mt-10 mx-auto w-[75%]">
            <div className="col-span-2">
              <WelcomeCard data={data} />
            </div>
            <div className="col-span-2">
              <AssignmentCard />
            </div>
            <div className="col-span-2">
              <TaskGraph />
            </div>
            <div className="col-span-2">
              <DashboardChart />
            </div>
            <div className="col-span-2">
              <PersonalChart />
            </div>
            <div className="col-span-2">
              <ColumnChart />
            </div>
            <div className="col-span-2 mb-7">
              <WorkOrdersGraph />
            </div>
            <div className="col-span-2 mb-7">
              <WorkOrderContinue />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Home;
