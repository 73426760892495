import React, { useEffect, useState } from "react";
import "../../demands/demands.css";
import { Link, useNavigate } from "react-router-dom";
import api from "../../../api/api";
import { TbEdit } from "react-icons/tb";
import Swal from "sweetalert2";
import { FaCheck } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { useAuth } from "../../../context/AuthContext";
import * as XLSX from "xlsx";
import { FaTruck } from "react-icons/fa";
import QuarantineCountModal from "../QuarantineCountModal";
import ControlCountModal from "../ControlCountModal";
import { FaAngleDown } from "react-icons/fa";
import { FaWpforms } from "react-icons/fa";
import LoadingSpinner from "../../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";

const WorkOrderSlips = () => {
  const { user } = useAuth();
  const [data, setData] = useState(null);
  const [customerID, setCustomerID] = useState(Number());
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const [selectedWareHouse, setSelectedWareHouse] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Toplam sayfa sayısını depolamak için
  const [pageSize, setPageSize] = useState(14);
  const [openModalQuarantina, setOpenModalQuarantina] = useState(false);
  const [openModalControl, setOpenModalControl] = useState(false);
  const [selectedOpenModalId, setSelectedOpenModalId] = useState(Number());
  const [selectedOpenModalParentId, setSelectedOpenModalParentId] = useState(
    Number()
  );
  const [openDetailIds, setOpenDetailIds] = useState([]);

  const [slips, setSlips] = useState({
    name: "desc",
    shippingNo: "desc",
    exitWareHouseName: "desc",
    entranceWareHouseName: "desc",
    shippingConfirm: "desc",
    approveConfirm: "desc",
    shippingDate: "desc",
  });
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=38`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }

          api
            .get(
              `/api/WareHouse/GetShippingSlips?customerID=${customerID}&warehouseID=${
                selectedWareHouse?.value || ""
              }&pageNumber=${currentPage}&pageSize=${pageSize}`
            )
            .then((res) => {
              setData(res.data.items);
              setTotalPages(res.data.totalPages);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user, customerID, selectedWareHouse, currentPage, pageSize]);

  // PAGINATION
  const nextPage = () => {
    setLoading(true);
    setCurrentPage((prev) => Math.min(prev + 1, totalPages)); // Son sayfayı aşmamak için kontrol
  };

  const prevPage = () => {
    setLoading(true);
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > 5 && currentPage > 3) {
      startPage = currentPage - 2;
      endPage = Math.min(currentPage + 2, totalPages);
    } else {
      endPage = Math.min(totalPages, 5);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i}>
          <button
            className={`flex items-center justify-center px-4 h-10 leading-tight ${
              currentPage === i
                ? "text-blue-600 bg-blue-50"
                : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
            }`}
            onClick={() => goToPage(i)}
          >
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setLoading(true);
  };

  // DELETE OPTIONS
  const handleDelete = async (shippingSlipID) => {
    try {
      const token =
        localStorage.getItem("token") || sessionStorage.getItem("token");

      Swal.fire({
        title: "Silmek istediğinize emin misiniz?",
        text: "Bunu geri döndüremezsiniz!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, eminim !",
        cancelButtonText: "Kapat",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(
              `/api/WareHouse/DeleteWareHouseShippingSlip/DeleteShippingSlip?id=${shippingSlipID}&customerID=${customerID}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then(() => {
              setData((prevData) =>
                prevData.filter(
                  (item) => item.shippingSlipID !== shippingSlipID
                )
              );
              Swal.fire({
                title: "Başarıyla silindi!",
                text: "Depo fişi silindi.",
                icon: "success",
              });
            })
            .catch((error) => {
              console.error("Error occurred while deleting invoice:", error);
              Swal.fire({
                title: "Silme hatası!",
                text: "Depo fişi silinemedi. Lütfen tekrar deneyin.",
                icon: "error",
              });
            });
        }
      });
    } catch (error) {
      console.error("Error occurred while handling delete operation:", error);
    }
  };

  //FILTER COLUMNS
  const handleSort = (columnName) => {
    const slipsCopy = { ...slips };
    slipsCopy[columnName] = slips[columnName] === "asc" ? "desc" : "asc";
    setSlips(slipsCopy);

    setData((prevData) => {
      const sortedData = [...prevData].sort((a, b) => {
        if (
          columnName === "shippingNo" ||
          columnName === "name" ||
          columnName === "exitWareHouseName" ||
          columnName === "entranceWareHouseName" ||
          columnName === "shippingConfirm" ||
          columnName === "approveConfirm" ||
          columnName === "shippingDate"
        ) {
          if (slipsCopy[columnName] === "asc") {
            return a[columnName]?.localeCompare(b[columnName]);
          } else {
            return b[columnName]?.localeCompare(a[columnName]);
          }
        } else {
          // Sayısal sütunlar için sıralama işlevini çağır
          return numericSort(
            a[columnName],
            b[columnName],
            slipsCopy[columnName]
          );
        }
      });
      return sortedData;
    });
  };

  const numericSort = (a, b, sortOrder) => {
    if (sortOrder === "asc") {
      return a - b;
    } else {
      return b - a;
    }
  };

  const handleWareHouse = async (e) => {
    setSelectedWareHouse(e);
    setCurrentPage(1);
  };

  // DATE FORMAT
  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    // Saate 3 ekleyerek hesaplama yapalım
    let saat = tarihObjesi.getHours();

    // Eğer saat 24'ten büyükse 24'e göre modunu alalım
    saat = saat % 24;

    // Saati string formatına çevirirken padding işlemi yapalım
    const saatString = saat.toString().padStart(2, "0");
    const dakika = tarihObjesi.getMinutes().toString().padStart(2, "0");

    return `${gun}/${ay}/${yil} - ${saatString}.${dakika} `;
  };

  // PUT APPROVED CONFIRMS
  const handleApproveConfirm = async (shippingSlipID) => {
    setLoading(true);
    try {
      const token =
        localStorage.getItem("token") || sessionStorage.getItem("token");

      if (!token) {
        throw new Error("Token bulunamadı, lütfen giriş yapın.");
      }

      api
        .put(
          `/api/WareHouse/PutApproveSlipConfirm/PutApproveSlipConfirm?customerID=${customerID}&shippingSlipID=${shippingSlipID}&confirm=true`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Depo giriş onaylandı !",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            window.location.reload();
          });
        })
        .catch((error) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${error}`,
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Error occurred while handling delete operation:", error);
      setLoading(false);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${error.message}`,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  // PUT SHIPPING CONFIRMS
  const handleShippingConfirm = async (shippingSlipID) => {
    setLoading(true);
    try {
      const token =
        localStorage.getItem("token") || sessionStorage.getItem("token");

      if (!token) {
        throw new Error("Token bulunamadı, lütfen giriş yapın.");
      }

      api
        .put(
          `/api/WareHouse/PutShippingSlipConfirm/PutShippingSlipConfirm?customerID=${customerID}&shippingSlipID=${shippingSlipID}&confirm=true`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Depo çıkış onaylandı !",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            window.location.reload();
          });
        })
        .catch((error) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${error}`,
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Error occurred while handling delete operation:", error);
      setLoading(false);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${error.message}`,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  // ITEMS TO EXCEL
  const handleExportData = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Örneğin, A, B, C sütunları için genişlik ayarı
    worksheet["!cols"] = [
      { wch: 10 }, // A sütunu genişliği
      { wch: 10 }, // B sütunu genişliği
      { wch: 10 }, // C sütunu genişliği
      { wch: 10 }, // D sütunu genişliği
      { wch: 10 }, // E sütunu genişliği
      { wch: 20 }, // F sütunu genişliği
      { wch: 20 }, // G sütunu genişliği
      { wch: 100 }, // H sütunu genişliği
      { wch: 60 }, // I sütunu genişliği
      { wch: 30 }, // J sütunu genişliği
      { wch: 30 }, // K sütunu genişliği
      { wch: 20 }, // L sütunu genişliği
      { wch: 20 }, // M sütunu genişliği
      { wch: 20 }, // N sütunu genişliği
      { wch: 20 }, // O sütunu genişliği
      { wch: 10 }, // P sütunu genişliği
      { wch: 10 }, // Q sütunu genişliği
      { wch: 10 }, // R sütunu genişliği
      { wch: 10 }, // S sütunu genişliği
      { wch: 20 }, // T sütunu genişliği
      { wch: 10 }, // U sütunu genişliği
      { wch: 10 }, // V sütunu genişliği
      { wch: 10 }, // W sütunu genişliği
      { wch: 100 }, // X sütunu genişliği
      { wch: 50 }, // Y sütunu genişliği
      { wch: 10 }, // Z sütunu genişliği
      { wch: 100 }, // AA sütunu genişliği
      { wch: 50 }, // AB sütunu genişliği
      { wch: 10 }, // AC sütunu genişliği
      { wch: 20 }, // AD sütunu genişliği
      { wch: 20 }, // AE sütunu genişliği
      { wch: 40 }, // AF sütunu genişliği
      { wch: 10 }, // AG sütunu genişliği
      // ve böyle devam eder...
    ];

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Data.xlsx");
  };

  // Detay açma işlevi
  const openDetail = (workOrderID) => {
    setOpenDetailIds((prevIds) => [...prevIds, workOrderID]);
  };

  // Detay kapatma işlevi
  const closeDetail = (workOrderID) => {
    setOpenDetailIds((prevIds) => prevIds.filter((id) => id !== workOrderID));
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  console.log(data);
  return (
    <>
      {loading ? (
        <LoadingSpinner loading={loading} />
      ) : (
        <>
          <div className="add-button pt-6">
          <div className="mb-5 flex items-center gap-2">
          <FaStarOfLife size={15}/>
              <h1 className="text-3xl font-semibold title">İş Emri Fişleri</h1>
            </div>
            <div className="flex justify-between">
              <input
                className="w-96 block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                type="text"
                placeholder="İş emri fişi ara.."
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
              {/* <div className="flex items-center gap-5">
                <div className="flex gap-5 mr-4">
                  <PDFDownloadLink
                    document={<ShippingSlipsPDF data={data} />}
                    filename="FORM"
                  >
                    {({ loading }) =>
                      loading ? (
                        <a className="font-medium text-red-600 hover:underline">
                          <FaRegFilePdf size={30} />
                        </a>
                      ) : (
                        <a className="font-medium text-red-600 hover:underline">
                          <FaRegFilePdf size={30} />
                        </a>
                      )
                    }
                  </PDFDownloadLink>
                  <button onClick={handleExportData}>
                    <a className="font-medium text-green-600 hover:underline">
                      <RiFileExcel2Line size={35} />
                    </a>
                  </button>
                </div>
              </div> */}
            </div>
          </div>

          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-white uppercase bg-slate-900 sticky top-0">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    İş Emri Başlık
                  </th>
                  <th scope="col" className="px-6 py-3">
                    İş Emri Adı
                  </th>
                  <th scope="col" className="px-6 py-3 ">
                    Baş. Tarih
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Bit. Tarih
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Gerçekleşen Üretim Adet
                  </th>
                  <th scope="col" className="px-6 py-3"></th>
                  <th scope="col" className="px-6 py-3"></th>
                  <th scope="col" className="px-6 py-3"></th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data
                    ?.filter(
                      (item) =>
                        item.name
                          ?.toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        item.title
                          ?.toLowerCase()
                          .includes(searchTerm.toLowerCase())
                    )
                    .map((item, index) => (
                      <React.Fragment key={index}>
                        <tr
                          key={index}
                          className={`bg-white border-b hover:bg-gray-50 ${
                            item.shippingSlips.length > 0
                              ? "cursor-pointer"
                              : ""
                          }`}
                          onClick={() =>
                            openDetailIds.includes(item.workOrderID)
                              ? closeDetail(item.workOrderID)
                              : openDetail(item.workOrderID)
                          }
                        >
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                          >
                            {item.title}
                          </th>
                          <td className="px-6 py-4">{item.name}</td>
                          <td className="px-6 py-4">
                            {formatDate(item.actualStartDate)}
                          </td>
                          <td className="px-6 py-4">
                            {formatDate(item.actualEndDate)}
                          </td>
                          <td className="px-6 py-4">
                            {item.actualProductionQuantity}
                          </td>
                          <td className="px-6 py-4"></td>
                          <td className="px-6 py-4"></td>
                          {item.shippingSlips.length > 0 && (
                            <div className="relative">
                              <FaAngleDown
                                className={`absolute right-4 top-6 transform -translate-y-1/2 ${
                                  openDetailIds.includes(item.workOrderID)
                                    ? "rotate-180"
                                    : ""
                                }`}
                                size={18}
                                color="gray"
                              />
                            </div>
                          )}
                        </tr>

                        {openDetailIds.includes(item.workOrderID) && // Detay açık ise alt satırları göster
                          item.shippingSlips.map((detail, idx) => (
                            <tr
                              key={idx}
                              className={`border-b hover:bg-gray-200 ${
                                detail.type === 1575
                                  ? "bg-red-100 hover:bg-red-200"
                                  : detail.type === 1578
                                  ? "bg-blue-100 hover:bg-blue-200"
                                  : "bg-gray-100"
                              }`}
                            >
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                              >
                                {detail.shippingNo}
                              </th>
                              <td title={detail.name} className="px-6 py-4">
                                <span className="font-semibold text-black">
                                  ADI:
                                </span>{" "}
                                {truncateText(detail.name, 25)}
                              </td>
                              <td className="px-6 py-4">
                                <span className="font-semibold text-black">
                                  ÇIKAN DEPO:
                                </span>{" "}
                                {detail.exitWareHouseName}
                              </td>
                              <td className="px-6 py-4">
                                <span className="font-semibold text-black">
                                  GİREN DEPO:
                                </span>{" "}
                                {detail.entranceWareHouseName}
                              </td>
                              <td className="px-6 py-4 pl-16">
                                <span className="font-semibold text-black">
                                  ÇIKIŞ ONAY:
                                </span>{" "}
                                {detail.shippingConfirm ? (
                                  <FaCheck size={17} color="green" />
                                ) : (
                                  <IoCloseSharp size={25} color="red" />
                                )}
                              </td>
                              <td className="px-6 py-4 pl-16">
                                <span className="font-semibold text-black">
                                  GİRİŞ ONAY:
                                </span>{" "}
                                {detail.approveConfirm ? (
                                  <FaCheck size={17} color="green" />
                                ) : (
                                  <IoCloseSharp size={25} color="red" />
                                )}
                              </td>
                              <td className="px-6 py-4">
                                <span className="font-semibold text-black">
                                  TARİH:
                                </span>{" "}
                                {formatDate(detail.shippingDate)}
                              </td>

                              <td className="flex items-center px-6 py-4 space-x-3">
                                <Link
                                  to={`/wareHouseSlipEdit/${detail.shippingSlipID}`}
                                >
                                  <a className="font-medium text-blue-600 hover:underline">
                                    <TbEdit size={20} />
                                  </a>
                                </Link>
                                <button
                                  onClick={() => {
                                    setOpenModalControl(true);
                                    setSelectedOpenModalId(
                                      detail.shippingSlipID
                                    );
                                    setSelectedOpenModalParentId(
                                      detail.parentID
                                    );
                                  }}
                                >
                                  {detail.type === 1576 &&
                                    detail.controlWareHouseShipment === !true &&
                                    detail.controlQualityForms === true && (
                                      <FaTruck
                                        title="Kontrol Depo Sevkleri"
                                        color="green"
                                        size={20}
                                      />
                                    )}
                                </button>
                                <button
                                  onClick={() => {
                                    setOpenModalQuarantina(true);
                                    setSelectedOpenModalId(
                                      detail.shippingSlipID
                                    );
                                    setSelectedOpenModalParentId(
                                      detail.parentID
                                    );
                                  }}
                                >
                                  {detail.type === 1575 &&
                                    detail.quarantinaWareHouseShipment ===
                                      !true && (
                                      <FaTruck
                                        title="Karantina Depodan Gönderim"
                                        color="red"
                                        size={20}
                                      />
                                    )}
                                </button>
                                <Link
                                  to={`/qualityFormsCheck/${detail.stockCardID}?shippingSlipID=${detail.shippingSlipID}`}
                                >
                                  {detail.type === 1576 &&
                                    detail.controlWareHouseShipment === !true &&
                                    detail.controlQualityForms === !true && (
                                      <FaWpforms
                                        title="Kalite Formları"
                                        color="orange"
                                        size={20}
                                      />
                                    )}
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </React.Fragment>
                    ))
                ) : (
                  <tr>
                    <td
                      colSpan={10}
                      className="px-6 py-4 text-center text-2xl font-medium"
                    >
                      Henüz kayıtlı bir veri yoktur.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <nav
            className="mt-2 float-right"
            aria-label="Page navigation example"
          >
            <ul className="flex items-center -space-x-px h-10 text-sm">
              <li>
                <button
                  onClick={prevPage}
                  disabled={currentPage === 1}
                  className={`flex items-center justify-center px-4 h-10 ${
                    currentPage === 1
                      ? "text-gray-300 cursor-not-allowed"
                      : "text-gray-500"
                  }`}
                >
                  <span className="sr-only">Previous</span>
                  <svg
                    className="w-2.5 h-2.5 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 1 1 5l4 4"
                    />
                  </svg>
                </button>
              </li>
              {renderPageNumbers()}
              <li>
                <button
                  onClick={nextPage}
                  disabled={currentPage === totalPages}
                  className={`flex items-center justify-center pl-4 h-10 ${
                    currentPage === totalPages
                      ? "text-gray-300 cursor-not-allowed"
                      : "text-gray-500"
                  }`}
                >
                  <span className="sr-only">Next</span>
                  <svg
                    className="w-2.5 h-2.5 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                </button>
              </li>
            </ul>
          </nav>
        </>
      )}

      {openModalQuarantina === true && (
        <QuarantineCountModal
          selectedOpenModalId={selectedOpenModalId}
          onCancel={() => setOpenModalQuarantina(false)}
        />
      )}
      {openModalControl === true && (
        <ControlCountModal
          selectedOpenModalId={selectedOpenModalId}
          selectedOpenModalParentId={selectedOpenModalParentId}
          onCancel={() => setOpenModalControl(false)}
        />
      )}
    </>
  );
};

export default WorkOrderSlips;
