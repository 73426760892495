import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import "../demands/demands.css";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api/api";
import Swal from "sweetalert2";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useAuth } from "../../context/AuthContext";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa6";
import * as XLSX from "xlsx";
import Footer from "../../components/bars/Footer";
import AccountModal from "./AccountModal";
import AccountPdfModal from "./AccountPdfModal";
import Select from "react-select";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";

const Accounts = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [accountTypes, setAccountTypes] = useState([]);
  const [selectedAccountType, setSelectedAccountType] = useState({
    value: 0,
    label: "Seçiniz",
  });
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Toplam sayfa sayısını depolamak için
  const [pageSize, setPageSize] = useState(20);
  const [openModal, setOpenModal] = useState(false);
  const [sortOrder, setSortOrder] = useState({
    name: "desc",
    contactName: "desc",
    telephone: "desc",
    email: "desc",
    cityName: "desc",
  });
  useEffect(() => {
    setToken(localStorage.getItem("token"));
    setLoading(true);

    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=7`
        )
        .then((res) => {
          setPermissions(res.data);
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get("/api/Common/GetParameters?parametername=AccountType")
            .then((res) => {
              const data = res.data;
              const newData = [
                { value: 0, label: "Seçiniz" },
                ...data.map((item) => ({
                  value: item.parameterID,
                  label: item.parameterValue2,
                })),
              ];
              setAccountTypes(newData);
            })
            .catch((err) => console.error(err));

          api
            .get(
              `/api/Account/GetAccounts?customerID=${user.country}&typeID=${selectedAccountType.value}&pageNumber=${currentPage}&pageSize=${pageSize}`
            )
            .then((res) => {
              setData(res.data.items);
              setTotalPages(res.data.totalPages);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user, currentPage, pageSize, selectedAccountType]);

  ///////////////////////// PAGING
  const nextPage = () => {
    setLoading(true);
    setCurrentPage((prev) => Math.min(prev + 1, totalPages)); // Son sayfayı aşmamak için kontrol
  };

  const prevPage = () => {
    setLoading(true);
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > 5 && currentPage > 3) {
      startPage = currentPage - 2;
      endPage = Math.min(currentPage + 2, totalPages);
    } else {
      endPage = Math.min(totalPages, 5);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i}>
          <button
            className={`flex items-center justify-center px-4 h-10 leading-tight ${
              currentPage === i
                ? "text-blue-600 bg-blue-50"
                : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
            }`}
            onClick={() => goToPage(i)}
          >
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setLoading(true);
  };

  const handleSort = (columnName) => {
    const sortOrderCopy = { ...sortOrder };
    sortOrderCopy[columnName] =
      sortOrder[columnName] === "asc" ? "desc" : "asc";
    setSortOrder(sortOrderCopy);

    setData((prevData) => {
      const sortedData = [...prevData].sort((a, b) => {
        if (
          columnName === "name" ||
          columnName === "contactName" ||
          columnName === "telephone" ||
          columnName === "email" ||
          columnName === "cityName"
        ) {
          if (sortOrderCopy[columnName] === "asc") {
            return a[columnName]?.localeCompare(b[columnName]);
          } else {
            return b[columnName]?.localeCompare(a[columnName]);
          }
        } else {
          // Sayısal sütunlar için sıralama işlevini çağır
          return numericSort(
            a[columnName],
            b[columnName],
            sortOrderCopy[columnName]
          );
        }
      });
      return sortedData;
    });
  };

  const numericSort = (a, b, sortOrder) => {
    if (sortOrder === "asc") {
      return a - b;
    } else {
      return b - a;
    }
  };
  /////////////////////////////////////////////

  const handleDelete = async (accountID, customerID) => {
    try {
      // await
      Swal.fire({
        title: "Silmek istediğinize emin misiniz?",
        text: "Bunu geri döndüremezsiniz!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, eminim !",
        cancelButtonText: "Kapat",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(
              `/api/Account/DeleteAccount/${accountID}?customerID=${customerID}`
            )
            .then(() => {
              setData((prevData) =>
                prevData.filter((item) => item.accountID !== accountID)
              );
              Swal.fire({
                title: "Başarıyla silindi!",
                text: "Cari silindi.",
                icon: "success",
              });
            })
            .catch((error) => {
              console.error("Error occurred while deleting invoice:", error);
              Swal.fire({
                title: "Silme hatası!",
                text: "Fatura silinemedi. Lütfen tekrar deneyin.",
                icon: "error",
              });
            });
        }
      });
    } catch (error) {
      console.error("Error occurred while handling delete operation:", error);
    }
  };

  // ITEMS TO EXCEL
  const handleExportData = async () => {
    setLoading(true);
    try {
      const token =
        sessionStorage.getItem("token") || localStorage.getItem("token");
      const response = await api.get(
        `/api/Common/GetDocumentNumber?customerID=${customerID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPdfData(response.data);

      const worksheet = XLSX.utils.json_to_sheet(data);

      // Örneğin, A, B, C sütunları için genişlik ayarı
      worksheet["!cols"] = [
        { wch: 15 }, // A sütunu genişliği
        { wch: 15 }, // B sütunu genişliği
        { wch: 60 }, // C sütunu genişliği
        { wch: 10 }, // D sütunu genişliği
        { wch: 50 }, // E sütunu genişliği
        { wch: 50 }, // F sütunu genişliği
        { wch: 20 }, // G sütunu genişliği
        { wch: 60 }, // H sütunu genişliği
        { wch: 20 }, // I sütunu genişliği
        { wch: 20 }, // J sütunu genişliği
        { wch: 60 }, // K sütunu genişliği
        { wch: 40 }, // L sütunu genişliği
        { wch: 40 }, // M sütunu genişliği
        { wch: 10 }, // N sütunu genişliği
        { wch: 10 }, // O sütunu genişliği
        { wch: 10 }, // P sütunu genişliği
        { wch: 7 }, // Q sütunu genişliği
        { wch: 10 }, // R sütunu genişliği
        { wch: 10 }, // S sütunu genişliği
        { wch: 20 }, // T sütunu genişliği
        { wch: 10 }, // U sütunu genişliği
        { wch: 10 }, // V sütunu genişliği
        { wch: 10 }, // W sütunu genişliği
        { wch: 10 }, // X sütunu genişliği
        { wch: 5 }, // Y sütunu genişliği
        { wch: 10 }, // Z sütunu genişliği
        { wch: 8 }, // AA sütunu genişliği
        { wch: 10 }, // AB sütunu genişliği
        { wch: 10 }, // AC sütunu genişliği
        { wch: 100 }, // AD sütunu genişliği
        { wch: 50 }, // AE sütunu genişliği
        { wch: 50 }, // AF sütunu genişliği
        { wch: 10 }, // AG sütunu genişliği
        // ve böyle devam eder...
      ];

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const fileName = `${response.data}.xlsx`; // Dosya adını ve uzantısını belirtiyoruz
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.error("Error fetching document number:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDocumentNumber = async () => {
    setLoading(true);
    try {
      const token =
        sessionStorage.getItem("token") || localStorage.getItem("token"); // Token'ı localStorage'dan alabilirsiniz
      const response = await api.get(
        `/api/Common/GetDocumentNumber?customerID=${customerID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Authorization header'ını ekliyoruz
          },
        }
      );

      // Mevcut veri ile API'den gelen veriyi birleştiriyoruz

      setPdfData(response.data);
    } catch (error) {
      console.error("Error fetching document number:", error);
    } finally {
      setLoading(false);
      setOpenPdfModal(true); // Modal'ı açıyoruz
    }
  };
  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="m-12 w-full min-h-screen">
            <div className="add-button">
              {permissions.insertPermission && (
                <Link to={"/accountAdd"}>
                  <button
                    type="button"
                    className="butonCl text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mr-2 mb-2"
                  >
                    Cari Oluştur
                    <i className="fa-solid fa-plus ml-2"></i>
                  </button>
                </Link>
              )}
              <button
                type="button"
                className="butonCl text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-4 focus:ring-yellow-500 font-medium rounded-lg text-sm px-12 py-2.5 text-center mr-2 mb-2"
                onClick={() => setOpenModal(true)}
              >
                Excel Dosyası Yükle
                <i className="fa-solid fa-arrow-up-from-bracket ml-2"></i>
              </button>

              <div className="mb-5 flex items-center gap-2">
              <FaStarOfLife size={15}/>
                <h1 className="text-3xl font-semibold title">Cariler</h1>
              </div>
              <div className="flex justify-between">
                <div className="flex gap-2">
                  <div>
                    <input
                      className="w-96 block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg pb-3"
                      type="text"
                      placeholder="Cari ara.."
                      onChange={(e) => setSearchTerm(e.target.value)}
                      value={searchTerm}
                    />
                  </div>
                  <div className="w-96">
                    <Select
                      options={accountTypes}
                      value={selectedAccountType}
                      onChange={(e) => setSelectedAccountType(e)}
                      maxMenuHeight={200}
                      classNamePrefix="custom-select"
                    />
                  </div>
                </div>
                <div className="flex gap-5 mr-4">
                  <a
                    onClick={fetchDocumentNumber}
                    className="font-medium text-red-600 hover:underline cursor-pointer"
                  >
                    <FaRegFilePdf size={30} />
                  </a>
                  <button onClick={handleExportData}>
                    <a className="font-medium text-green-600 hover:underline">
                      <RiFileExcel2Line size={35} />
                    </a>
                  </button>
                </div>
              </div>
            </div>

            <div className="relative overflow-x-auto mb-5">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-white uppercase bg-slate-900">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 cursor-pointer"
                      onClick={() => handleSort("name")}
                    >
                      Cari Adı {sortOrder.name === "asc" ? "▲" : "▼"}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 cursor-pointer"
                      onClick={() => handleSort("contactName")}
                    >
                      Kisi Adı {sortOrder.contactName === "asc" ? "▲" : "▼"}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 cursor-pointer"
                      onClick={() => handleSort("telephone")}
                    >
                      Tel NO {sortOrder.telephone === "asc" ? "▲" : "▼"}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 cursor-pointer"
                      onClick={() => handleSort("email")}
                    >
                      Mail {sortOrder.email === "asc" ? "▲" : "▼"}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 cursor-pointer"
                      onClick={() => handleSort("cityName")}
                    >
                      Sehir {sortOrder.cityName === "asc" ? "▲" : "▼"}
                    </th>
                    <th scope="col" className="px-6 py-3">
                      İslemler
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.length > 0 ? (
                    data
                      ?.filter(
                        (item) =>
                          item.name
                            ?.toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                          item.contactName
                            ?.toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                          item.telephone
                            ?.toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                          item.email
                            ?.toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                          item.cityName
                            ?.toLowerCase()
                            .includes(searchTerm.toLowerCase())
                      )
                      .map((item, index) => (
                        <tr
                          key={index}
                          className="bg-white border-b hover:bg-gray-50"
                        >
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                          >
                            {item.name}
                          </th>
                          <td className="px-6 py-4">{item.contactName}</td>
                          <td className="px-6 py-4">{item.telephone}</td>
                          <td className="px-6 py-4">{item.email}</td>
                          <td className="px-6 py-4">{item.cityName}</td>

                          <td className="flex items-center px-6 py-4 space-x-3">
                            {permissions.updatePermission && (
                              <Link to={`/accountEdit/${item.accountID}`}>
                                {" "}
                                <a className="font-medium text-blue-600 hover:underline">
                                  <TbEdit size={20} />
                                </a>
                              </Link>
                            )}
                            {/* <Link to={`/userProducts/${item.accountID}`}>
                                <a className="font-medium text-green-600 hover:underline">
                                  <IoDocumentsOutline size={20} />
                                </a>
                              </Link> */}
                            {permissions.deletePermission && (
                              <button
                                onClick={() => {
                                  handleDelete(item.accountID, item.customerID);
                                }}
                              >
                                <a className="font-medium text-red-600 hover:underline">
                                  <RiDeleteBin6Line size={20} />
                                </a>
                              </button>
                            )}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td
                        colSpan={6}
                        className="px-6 py-4 text-center text-2xl font-medium"
                      >
                        Henüz kayıtlı bir veri yoktur.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <nav
              className="mt-2 float-right"
              aria-label="Page navigation example"
            >
              <ul className="flex items-center -space-x-px h-10 text-sm">
                <li>
                  <button
                    onClick={prevPage}
                    disabled={currentPage === 1}
                    className={`flex items-center justify-center px-4 h-10 ${
                      currentPage === 1
                        ? "text-gray-300 cursor-not-allowed"
                        : "text-gray-500"
                    }`}
                  >
                    <span className="sr-only">Previous</span>
                    <svg
                      className="w-2.5 h-2.5 rtl:rotate-180"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 1 1 5l4 4"
                      />
                    </svg>
                  </button>
                </li>
                {renderPageNumbers()}
                <li>
                  <button
                    onClick={nextPage}
                    disabled={currentPage === totalPages}
                    className={`flex items-center justify-center pl-4 h-10 ${
                      currentPage === totalPages
                        ? "text-gray-300 cursor-not-allowed"
                        : "text-gray-500"
                    }`}
                  >
                    <span className="sr-only">Next</span>
                    <svg
                      className="w-2.5 h-2.5 rtl:rotate-180"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 9 4-4-4-4"
                      />
                    </svg>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
      {openModal === true && (
        <AccountModal onCancel={() => setOpenModal(false)} />
      )}
      {openPdfModal === true && (
        <AccountPdfModal
          data={data}
          pdfData={pdfData}
          onCancel={() => setOpenPdfModal(false)}
        />
      )}
      <Footer />
    </>
  );
};

export default Accounts;
