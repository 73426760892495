import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Select from "react-select";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import { setHours, setMinutes, setSeconds } from "date-fns";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";

const PersonelShiftAdd = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [shiftDates, setShiftDates] = useState([]);
  const [allPersonels, setAllPersonels] = useState([]);
  const [personels, setPersonels] = useState([]);
  const [personelGroups, setPersonelGroups] = useState([]);
  const [selectedPersonelGroups, setSelectedPersonelGroups] = useState({});
  const [machines, setMachines] = useState([]);
  const [operations, setOperations] = useState([]);
  const [selectedShift, setSelectedShift] = useState(null); // Seçilen mesai saati
  const [customerID, setCustomerID] = useState(Number());
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  //FORM
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  //AGENDA ITEMS
  const [personShifts, setPersonShifts] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);

      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=26`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.insertPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(`/api/Shift/GetCustomerShifts?customerID=${user.country}`)
            .then((res) => {
              const transformedShifts = res.data.map((shift) => ({
                value: `${shift.shiftStart}-${shift.shiftEnd}`,
                label: `${shift.shiftStart}-${shift.shiftEnd}`,
              }));
              setShiftDates(transformedShifts);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/Personel/GetPersons?customerID=${user.country}&pageNumber=1&pageSize=9999`
            )
            .then((res) => {
              setAllPersonels(res.data.items);
              const dataPersons = res.data.items;
              const newDataPersons = dataPersons?.map((item) => ({
                value: item.personelID,
                label: item.name,
              }));
              setPersonels(newDataPersons);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/Machine/GetMachines?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              const dataMachines = res.data.items;
              const newDataMachines = dataMachines?.map((item) => ({
                value: item.machineID,
                label: item.name,
              }));
              setMachines(newDataMachines);
            })
            .catch((err) => console.log(err.data));
          api
            .get(`/api/Personel/GetPersonGroups?customerID=${user.country}`)
            .then((res) => {
              const dataPersonGroups = res.data;
              const newDataPersonGroups = dataPersonGroups?.map((item) => ({
                value: item.personGroupID,
                label: item.name,
              }));
              setPersonelGroups(newDataPersonGroups);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/Operation/Getoperations?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              const dataOperations = res.data.items;
              const newDataOperations = dataOperations?.map((item) => ({
                value: item.operationID,
                label: item.name,
              }));
              setOperations(newDataOperations);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  //PERSONEL SHIFT ITEMS OPTIONS

  const handlePersonelShiftsChange = (e, index) => {
    const list = [...personShifts];
    list[index].personelID = e;
    const matchedPerson = allPersonels.find(
      (item) => item.personelID === e.value
    );
    list[index].operationID = {
      label: matchedPerson.operationName,
      value: matchedPerson.operationID,
    };
    list[index].machineID = {
      label: matchedPerson.machineName,
      value: matchedPerson.machineID,
    };
    setPersonShifts(list);
  };

  const handleMachineChange = (e, index) => {
    const list = [...personShifts];
    list[index].machineID = e;
    setPersonShifts(list);
  };

  const handleOperationChange = (e, index) => {
    const list = [...personShifts];
    list[index].operationID = e;
    setPersonShifts(list);
  };

  const handleServiceAdd = () => {
    setPersonShifts([
      ...personShifts,
      {
        personelID: null,
        machineID: null,
        operationID: null,
        projectID: null,
      },
    ]);
  };
  const handleServiceRemove = (index) => {
    const list = [...personShifts];
    list.splice(index, 1);
    setPersonShifts(list);
  };

  useEffect(() => {
    if (selectedShift) {
      const [shiftStartHour, shiftStartMinute, shiftStartSecond] =
        selectedShift.value.split("-")[0].split(":"); // Başlangıç saatini, dakikasını ve saniyesini ayır
      const [shiftEndHour, shiftEndMinute, shiftEndSecond] = selectedShift.value
        .split("-")[1]
        .split(":"); // Bitiş saatini, dakikasını ve saniyesini ayır
      const currentStartDate = new Date(startDate); // Başlangıç tarihini kopyala
      const currentEndDate = new Date(endDate); // Bitiş tarihini kopyala
      const startDateTime = setHours(
        setMinutes(
          setSeconds(currentStartDate, shiftStartSecond === "59" ? 59 : 0), // Başlangıç saniyesini 0 yap, eğer 59 ise
          shiftStartMinute
        ),
        shiftStartHour
      );
      const endDateTime = setHours(
        setMinutes(
          setSeconds(currentEndDate, shiftEndSecond === "59" ? 59 : 0), // Bitiş saniyesini 0 yap, eğer 59 ise
          shiftEndMinute
        ),
        shiftEndHour
      );
      setStartDate(startDateTime); // startDate'i güncelle
      setEndDate(endDateTime); // endDate'i güncelle
    }
  }, [selectedShift, startDate.getTime(), endDate.getTime()]);

  // Mesai saatleri seçeneklerinin değişimi
  const handleShiftSelectChange = (selectedOption) => {
    setSelectedShift(selectedOption);
  };

  const machinesWithPlaceholder = [
    { label: "Seçilmedi", value: 0 },
    ...machines,
  ];
  const operationsWithPlaceholder = [
    { label: "Seçilmedi", value: 0 },
    ...operations,
  ];

  // PERSONEL GROUP SELECT CHANGE
  const handlePersonGroupChange = async (e) => {
    setSelectedPersonelGroups(e);
    setLoading(true);
    await api
      .get(`/api/Personel/GetPersonsByPersonGroup?personGroupID=${e.value}`)
      .then((res) => {
        const dataPersonGroup = res.data;
        const newDataPersonGroup = dataPersonGroup?.map((item) => ({
          personelID: { value: item.personelID, label: item.name },
          machineID: { value: item.machineID, label: item.machineName },
          operationID: { value: item.operationID, label: item.operationName },
          projectID: 0,
        }));
        setPersonShifts(newDataPersonGroup);
      })
      .catch((err) => console.log(err.data))
      .finally(() => setLoading(false));
  };

  const postData = {
    customerID,
    startDate,
    endDate,
    personShifts: personShifts?.map((item) => ({
      machineID: item?.machineID?.value || 0,
      operationID: item?.operationID?.value || 0,
      personelID: item?.personelID?.value || 0,
      projectID: 0,
    })),
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Axios isteği yapılandırma
      await api.post("/api/Shift/PostShiftPersons/PostShiftPersons", postData);

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Vardiya oluşturuldu !",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/personelShifts");
    } catch (error) {
      console.error("Hata alındı: ", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${error}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="w-full mx-auto px-12 min-h-screen max-h-screen overflow-y-scroll">
            <div className="pb-5 pt-12">
              <h1 className="text-3xl font-semibold title">Vardiya Oluştur</h1>
            </div>
            <form
              onSubmit={handleSubmit}
              className="grid grid-cols-2 gap-12 items-center w-full mt-5"
            >
              <div className="col-span-1">
                <label
                  htmlFor="startDate"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Başlangıç Tarih :
                </label>
                <DatePicker
                  id="startDate"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  locale={tr}
                  dateFormat="d MMMM, yyyy "
                />
              </div>

              <div className="col-span-1">
                <label
                  htmlFor="endDate"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Bitiş Tarih :
                </label>
                <DatePicker
                  id="endDate"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  locale={tr}
                  dateFormat="d MMMM, yyyy"
                />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="shiftDate"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Mesai Saatleri :
                </label>

                <Select
                  value={selectedShift}
                  options={shiftDates}
                  id="shiftDate"
                  maxMenuHeight={200}
                  onChange={handleShiftSelectChange} // Değişiklik olduğunda seçilen mesai saatiyi güncelle
                />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="personGroups"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Personel Grupları :
                </label>

                <Select
                  value={selectedPersonelGroups}
                  onChange={(e) => handlePersonGroupChange(e)}
                  options={personelGroups}
                  id="personGroups"
                  maxMenuHeight={200}
                />
              </div>

              <div className="relative overflow-x-auto shadow-md sm:rounded-lg mb-5 w-full max-h-96 overflow-y-auto mt-6 col-span-2">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Personeller
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Makineler
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Operasyonlar
                      </th>
                      <th scope="col" className="px-6 py-3"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {personShifts?.map((singleService, index) => (
                      <tr key={index} className="bg-white border-b">
                        <td className="px-6 py-4">
                          <Select
                            value={singleService.personelID}
                            onChange={(e) =>
                              handlePersonelShiftsChange(e, index)
                            }
                            maxMenuHeight={200}
                            options={personels}
                          />
                        </td>
                        <td className="px-6 py-4">
                          <Select
                            value={singleService.machineID}
                            onChange={(e) => handleMachineChange(e, index)}
                            options={machinesWithPlaceholder}
                            maxMenuHeight={200}
                          />
                        </td>
                        <td className="px-6 py-4">
                          <Select
                            value={singleService.operationID}
                            onChange={(e) => handleOperationChange(e, index)}
                            options={operationsWithPlaceholder}
                            maxMenuHeight={200}
                          />
                        </td>

                        <td className="px-6 py-4">
                          <button
                            onClick={() => handleServiceRemove(index)}
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg m-5"
                          >
                            <i className="fa-regular fa-trash-can"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <button
                    type="button"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg m-5 outline-none"
                    onClick={handleServiceAdd}
                  >
                    Ekle
                    <i className="fa-solid fa-plus ml-2"></i>
                  </button>
                </table>
              </div>

              <div className="col-span-3 flex justify-end gap-[20px]">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg m-5"
                >
                  Kaydet
                  <i className="fa-regular fa-floppy-disk ml-2"></i>
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default PersonelShiftAdd;
