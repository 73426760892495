import React, { useEffect, useRef, useState } from "react";
import { AiOutlineUser, AiOutlineAppstore } from "react-icons/ai";
import { IoIosNotificationsOutline } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/propex_logo.png";
import { useDispatch, useSelector } from "react-redux";
import { getNotification } from "../../redux/userDataSlice";
import { LuPartyPopper } from "react-icons/lu";
import koenLogo from "../../assets/images/koen-logo.png";
import api from "../../api/api";
import { useAuth } from "../../context/AuthContext";

const Header = () => {
  const { logout } = useAuth();
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [notifDropdownOpen, setNotifDropdownOpen] = useState(false);
  const sliceData = useSelector((state) => state.notifications);
  const userData = useSelector((state) => state.userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDropdownRef = useRef(null);
  const notifDropdownRef = useRef(null);
  const handleClick = () => {
    logout();
    navigate("/");
  };
  const handleOutsideClick = (event) => {
    if (
      userDropdownRef.current &&
      !userDropdownRef.current.contains(event.target)
    ) {
      setUserDropdownOpen(false);
    }

    if (
      notifDropdownRef.current &&
      !notifDropdownRef.current.contains(event.target)
    ) {
      setNotifDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    dispatch(getNotification());
  }, [dispatch]);

  const handleClickStatus = async (notificationID, customerID, linkTo) => {
    await api
      .put(
        `/api/UserNotification/PutNotificationStatus/PutNotificationStatus?notificationID=${notificationID}&customerID=${customerID}`
      )
      .then((res) => {
        navigate(linkTo);
      })
      .catch((err) => {
        console.error("API request failed with error:", err);
        alert("An error occurred. Please try again later.");
      });
  };
  return (
    <nav className="py-5 bg-slate-950">
      <div className="flex justify-between px-8 items-center">
        <div>
          <Link to={"/dashboards"}>
            <img src={logo} className="h-11 cursor-pointer" alt="logo" />
          </Link>
        </div>
        <ul className="flex mr-4">
          <li className="relative flex items-center">
            <button
              onClick={() => {
                setNotifDropdownOpen(!notifDropdownOpen);
              }}
              className="relative inline-flex items-center text-sm font-medium text-center text-white hover:bg-slate-600 rounded-full transition duration-200 p-2 focus:outline-none active:bg-blue-200"
            >
              <IoIosNotificationsOutline color="white" size={25} />
              <span className="sr-only">Notifications</span>
              <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-slate-950 bg-white border-2 border-white rounded-full -top-1 -end-1 ">
                {sliceData.filter((item) => item.status === 0).length}
              </div>
            </button>
            {notifDropdownOpen && (
              <div
                id="userDropdown"
                className="absolute bg-white divide-y divide-gray-200 rounded-lg shadow-lg w-96 top-9 right-3 max-h-[700px] overflow-y-auto z-[1000]"
                ref={notifDropdownRef}
              >
                <div className="px-4 py-3 text-sm text-gray-900 ">
                  <Link to={"/notifications"}>
                    <h1
                      style={{ color: "#0766AD" }}
                      className="font-semibold text-xl"
                    >
                      Bildirimler
                    </h1>
                  </Link>
                </div>
                <ul
                  className="py-2 text-sm text-gray-700"
                  aria-labelledby="avatarButton"
                >
                  {sliceData.map((item, index) => {
                    if (item.status === 0) {
                      const tarihNesnesi = new Date(item.insertDate);
                      const suAnkiTarih = new Date();
                      const gecenMilisaniyeler = suAnkiTarih - tarihNesnesi;

                      const gecenGunler = Math.floor(
                        gecenMilisaniyeler / (1000 * 60 * 60 * 24)
                      );
                      const gecenSaatler = Math.floor(
                        (gecenMilisaniyeler / (1000 * 60 * 60)) % 24
                      );
                      const gecenDakikalar = Math.floor(
                        (gecenMilisaniyeler / (1000 * 60)) % 60
                      );

                      const gecenSureGun =
                        gecenGunler > 0 ? `${gecenGunler} gün` : "";
                      const gecenSureSaat =
                        gecenSaatler > 0 ? `${gecenSaatler} saat` : "";
                      const gecenSureDakika =
                        gecenDakikalar > 0 ? `${gecenDakikalar} dakika` : "";

                      // switch case ile yönlendirme yapılıyor
                      let linkTo = "/notifications"; // Varsayılan değer

                      switch (item.type) {
                        case 122:
                          linkTo = `/demandEdit/${item.relationID}`;
                          break;
                        // Diğer type'lar için gerekli case'leri ekleyin
                        case 123:
                          linkTo = `/offerEdit/${item.relationID}`;
                          break;
                        case 124:
                          linkTo = `/orderEdit/${item.relationID}`;
                          break;
                        case 125:
                          linkTo = `/invoiceEdit/${item.relationID}`;
                          break;
                        case 126:
                          linkTo = `/projectEdit/${item.relationID}`;
                          break;
                        case 127:
                          linkTo = `/taskDetailByAssignedUser/${item.relationID}`;
                          break;
                        case 128:
                          linkTo = `/formBuilderSchemaDetail/${item.relationID}`;
                          break;
                        case 129:
                          linkTo = `/stockCardEdit/${item.relationID}`;
                          break;
                        case 130:
                          linkTo = `/workFlowEdit/${item.relationID}`;
                          break;

                        default:
                          break;
                      }

                      return (
                        <li className="border-b-2" key={index}>
                          <a
                            onClick={() =>
                              handleClickStatus(
                                item.notificationID,
                                item.customerID,
                                linkTo
                              )
                            }
                            className="block px-4 py-2 hover:bg-gray-100 text-black flex items-center cursor-pointer"
                          >
                            <LuPartyPopper
                              size={30}
                              color="#0766AD"
                              className="mr-5"
                            />
                            <div className="flex flex-col justify-center">
                              <p className="font-medium text-gray-600 truncate w-72">
                                {item.notification}
                              </p>
                              <span className="text-gray-400">
                                {gecenSureGun} {gecenSureSaat} {gecenSureDakika}{" "}
                                {gecenSureGun ||
                                gecenSureSaat ||
                                gecenSureDakika
                                  ? "önce"
                                  : ""}
                              </span>
                            </div>
                          </a>
                        </li>
                      );
                    }
                    return null; // Okunmamış bildirim yoksa null döndür
                  })}

                  {sliceData.every((item) => item.status !== 0) && (
                    <li>
                      <div className="flex items-center justify-center">
                        <p className="text-gray-400">
                          Okunmamış bildiriminiz yok.
                        </p>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </li>

          <li className="flex items-center">
            <button
              className="hover:bg-slate-600 rounded-full transition duration-200 p-2 focus:outline-none active:bg-blue-200"
              onClick={() => setUserDropdownOpen(!userDropdownOpen)}
            >
              <AiOutlineUser color="white" size={25} />
            </button>

            {userDropdownOpen && (
              <div
                ref={userDropdownRef}
                id="userDropdown"
                className="absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 top-20 right-14 z-[1000]"
              >
                <div className="px-4 py-3 text-sm text-gray-900 ">
                  <div className="font-medium truncate text-blue-600">
                    {userData.roleName}
                  </div>
                  <div className="font-medium truncate">
                    {userData.userName.charAt(0).toUpperCase() +
                      userData.userName.slice(1).toLowerCase()}
                  </div>
                </div>
                <ul
                  className="py-2 text-sm text-gray-700 "
                  aria-labelledby="avatarButton"
                >
                  <li>
                    <a
                      href="/dashboards"
                      className="block px-4 py-2 hover:bg-gray-100 text-gray-600 font-medium"
                    >
                      Anasayfa
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="block px-4 py-2 hover:bg-gray-100 text-gray-600 font-medium"
                    >
                      Ayarlar
                    </a>
                  </li>
                </ul>
                <div className="py-1">
                  <a
                    onClick={handleClick}
                    className="cursor-pointer block px-4 py-2 text-sm text-red-500 hover:bg-gray-100 font-semibold"
                  >
                    Çıkış
                  </a>
                </div>
              </div>
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
