import React, { useEffect, useState } from "react";
import "../demands/demands.css";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api/api";
import Swal from "sweetalert2";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import { FaFlagCheckered } from "react-icons/fa";
import { IoIosPlay } from "react-icons/io";
import { useAuth } from "../../context/AuthContext";
import * as XLSX from "xlsx";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa6";
import { FaRegEye } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import WorkOrderPdfModal from "./WorkOrderPdfModal";
import LoadingSpinner from "../LoadingSpinner";

const WorkOrdersActive = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [customerID, setCustomerID] = useState(Number());
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 1);
  });
  const [endDate, setEndDate] = useState(() => {
    const today = new Date();
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    ).getDate();
    return new Date(today.getFullYear(), today.getMonth(), lastDayOfMonth);
  });
  const [openDetailIds, setOpenDetailIds] = useState([]);

  useEffect(() => {
    setLoading(true);
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=27`
        )
        .then((res) => {
          setPermissions(res.data);
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
          const formattedEndDate = moment(endDate).format("YYYY-MM-DD");
          api
            .get(
              `/api/WorkOrder/GetWorkOrdersByDate?customerID=${user.country}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
            )
            .then((res) => setData(res.data))
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user, startDate, endDate]);

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    // Saate 3 ekleyerek hesaplama yapalım
    let saat = tarihObjesi.getHours();

    // Eğer saat 24'ten büyükse 24'e göre modunu alalım
    saat = saat % 24;

    // Saati string formatına çevirirken padding işlemi yapalım
    const saatString = saat.toString().padStart(2, "0");
    const dakika = tarihObjesi.getMinutes().toString().padStart(2, "0");

    return `${gun}/${ay}/${yil} - ${saatString}.${dakika} `;
  };

  const onChangeDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleStartChangeStatus = async (workOrderID) => {
    setLoading(true);
    try {
      const token =
        localStorage.getItem("token") || sessionStorage.getItem("token");

      if (!token) {
        throw new Error("Token bulunamadı, lütfen giriş yapın.");
      }

      api
        .post(
          `/api/WorkOrder/SendWorkOrdertoShift/SendWorkOrdertoShift?customerID=${customerID}&workOrderID=${workOrderID}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "İş emri başlatıldı !",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            window.location.reload();
          });
        })
        .catch((error) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${error.response.data}`,
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Error occurred while handling delete operation:", error);
      setLoading(false);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${error.message}`,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  // ITEMS TO EXCEL
  const handleExportData = async () => {
    setLoading(true);
    try {
      const token =
        sessionStorage.getItem("token") || localStorage.getItem("token");
      const response = await api.get(
        `/api/Common/GetDocumentNumber?customerID=${customerID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPdfData(response.data);

      const worksheet = XLSX.utils.json_to_sheet(data);

      // Örneğin, A, B, C sütunları için genişlik ayarı
      worksheet["!cols"] = [
        { wch: 10 }, // A sütunu genişliği
        { wch: 10 }, // B sütunu genişliği
        { wch: 10 }, // C sütunu genişliği
        { wch: 10 }, // D sütunu genişliği
        { wch: 60 }, // E sütunu genişliği
        { wch: 60 }, // F sütunu genişliği
        { wch: 20 }, // G sütunu genişliği
        { wch: 20 }, // H sütunu genişliği
        { wch: 20 }, // I sütunu genişliği
        { wch: 20 }, // J sütunu genişliği
        { wch: 10 }, // K sütunu genişliği
        { wch: 20 }, // L sütunu genişliği
        { wch: 20 }, // M sütunu genişliği
        { wch: 20 }, // N sütunu genişliği
        { wch: 20 }, // O sütunu genişliği
        { wch: 10 }, // P sütunu genişliği
        { wch: 10 }, // Q sütunu genişliği
        { wch: 10 }, // R sütunu genişliği
        { wch: 10 }, // S sütunu genişliği
        { wch: 20 }, // T sütunu genişliği
        { wch: 10 }, // U sütunu genişliği
        { wch: 10 }, // V sütunu genişliği
        { wch: 10 }, // W sütunu genişliği
        { wch: 100 }, // X sütunu genişliği
        { wch: 50 }, // Y sütunu genişliği
        { wch: 10 }, // Z sütunu genişliği
        { wch: 100 }, // AA sütunu genişliği
        { wch: 50 }, // AB sütunu genişliği
        { wch: 10 }, // AC sütunu genişliği
        { wch: 20 }, // AD sütunu genişliği
        { wch: 20 }, // AE sütunu genişliği
        { wch: 40 }, // AF sütunu genişliği
        { wch: 10 }, // AG sütunu genişliği
        // ve böyle devam eder...
      ];

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const fileName = `${response.data}.xlsx`; // Dosya adını ve uzantısını belirtiyoruz
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.error("Error fetching document number:", error);
    } finally {
      setLoading(false);
    }
  };

  // Detay açma işlevi
  const openDetail = (workOrderID) => {
    setOpenDetailIds((prevIds) => [...prevIds, workOrderID]);
  };

  // Detay kapatma işlevi
  const closeDetail = (workOrderID) => {
    setOpenDetailIds((prevIds) => prevIds.filter((id) => id !== workOrderID));
  };

  const fetchDocumentNumber = async () => {
    setLoading(true);
    try {
      const token =
        sessionStorage.getItem("token") || localStorage.getItem("token"); // Token'ı localStorage'dan alabilirsiniz
      const response = await api.get(
        `/api/Common/GetDocumentNumber?customerID=${customerID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Authorization header'ını ekliyoruz
          },
        }
      );

      // Mevcut veri ile API'den gelen veriyi birleştiriyoruz

      setPdfData(response.data);
    } catch (error) {
      console.error("Error fetching document number:", error);
    } finally {
      setLoading(false);
      setOpenPdfModal(true); // Modal'ı açıyoruz
    }
  };
  return (
    <>
      {loading ? (
        <LoadingSpinner loading={loading} />
      ) : (
        <>
          <div className="add-button pt-6">
            {permissions?.insertPermission && (
              <>
                <Link to={"/manualWorkOrderAdd"}>
                  <button
                    type="button"
                    className="butonCl text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mr-2 mb-2"
                  >
                    Manuel Üretim Planlama Oluştur
                    <i className="fa-solid fa-plus ml-2"></i>
                  </button>
                </Link>

                <Link to={"/workOrderAdd"}>
                  <button
                    type="button"
                    className="butonCl text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mr-2 mb-2"
                  >
                    Sipariş'ten Üretim Planlama Oluştur
                    <i className="fa-solid fa-plus ml-2"></i>
                  </button>
                </Link>
              </>
            )}
            <div className="mb-5">
              <h1 className="text-3xl font-semibold title">Üretim Planlama</h1>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <input
                  className="w-96 block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                  type="text"
                  placeholder="İş emri ara.."
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
              </div>

              <div className="flex items-center gap-5">
                <DatePicker
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 "
                  locale={tr}
                  dateFormat="d MMMM, yyyy "
                  selectsRange
                  placeholderText="Tarih aralığını seçiniz..."
                  selected={startDate}
                  onChange={onChangeDate}
                  startDate={startDate}
                  endDate={endDate}
                />
                <div className="flex gap-5 mr-4">
                  <a
                    onClick={fetchDocumentNumber}
                    className="font-medium text-red-600 hover:underline cursor-pointer"
                  >
                    <FaRegFilePdf size={30} />
                  </a>
                  <button onClick={handleExportData}>
                    <a className="font-medium text-green-600 hover:underline">
                      <RiFileExcel2Line size={35} />
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-white uppercase bg-slate-900 sticky top-0">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Adı
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Gerçekleşen Adet
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Planlanan Adet
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Baş. Tarih
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Bit. Tarih
                  </th>

                  <th scope="col" className="px-6 py-3">
                    İşlemler
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data
                    ?.filter((item) =>
                      item.name
                        ?.toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    )
                    .map((item, index) => {
                      const isEndDateAfterNow =
                        new Date(item.endDate) > new Date();
                      const isStatus1553 = item.status === 1553;
                      return (
                        <React.Fragment key={index}>
                          <tr
                            key={index}
                            className={`border-b hover:bg-gray-50 ${
                              isStatus1553 && isEndDateAfterNow
                                ? "bg-red-50 hover:bg-red-100"
                                : "bg-white"
                            } ${
                              item.childWorkOrders.length > 0
                                ? "cursor-pointer"
                                : ""
                            }`}
                            onClick={() =>
                              openDetailIds.includes(item.workOrderID)
                                ? closeDetail(item.workOrderID)
                                : openDetail(item.workOrderID)
                            }
                          >
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                            >
                              {item.name}
                            </th>
                            <td className="px-6 py-4">
                              {item.plannedProductionQuantity}
                            </td>
                            <td className="px-6 py-4">
                              {item.actualProductionQuantity}
                            </td>
                            <td className="px-6 py-4">
                              {formatDate(item.startDate)}
                            </td>
                            <td className="px-6 py-4">
                              {formatDate(item.endDate)}
                            </td>
                            <td className="flex items-center px-6 py-4 space-x-3">
                              {permissions.reportPermission && (
                                <Link
                                  to={`/workOrderDetail/${item.workOrderID}`}
                                >
                                  <a className="font-medium text-green-600 hover:underline">
                                    <FaRegEye size={20} />
                                  </a>
                                </Link>
                              )}
                              {item.status === 1551 && (
                                <button
                                  onClick={() =>
                                    handleStartChangeStatus(item.workOrderID)
                                  }
                                  title="İş emrini başlat"
                                >
                                  <a className="font-medium text-green-600 hover:underline">
                                    <IoIosPlay size={25} />
                                  </a>
                                </button>
                              )}
                              {item.status === 1552 && (
                                <Link
                                  to={`/workOrderClosing/${item.workOrderID}`}
                                  title="İş emrini kapat"
                                >
                                  <a className="font-medium text-red-600 hover:underline">
                                    <FaFlagCheckered size={20} />
                                  </a>
                                </Link>
                              )}
                            </td>
                            {item.childWorkOrders.length > 0 && (
                              <div className="relative">
                                <FaAngleDown
                                  className={`absolute right-4 -top-6 transform -translate-y-1/2 ${
                                    openDetailIds.includes(item.workOrderID)
                                      ? "rotate-180"
                                      : ""
                                  }`}
                                  size={18}
                                  color="gray"
                                />
                              </div>
                            )}
                          </tr>
                          {openDetailIds.includes(item.workOrderID) && // Detay açık ise alt satırları göster
                            item.childWorkOrders.map((detail, idx) => (
                              <tr
                                key={idx}
                                className={`border-b hover:bg-gray-200 ${
                                  isStatus1553 && isEndDateAfterNow
                                    ? "bg-red-50 hover:bg-red-100"
                                    : "bg-gray-100"
                                }`}
                              >
                                <th
                                  scope="row"
                                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                                >
                                  {detail.name}
                                </th>
                                <td className="px-6 py-4">
                                  {detail.plannedProductionQuantity}
                                </td>
                                <td className="px-6 py-4">
                                  {detail.actualProductionQuantity}
                                </td>
                                <td className="px-6 py-4">
                                  {formatDate(detail.startDate)}
                                </td>
                                <td className="px-6 py-4">
                                  {formatDate(detail.endDate)}
                                </td>
                                <td className="flex items-center px-6 py-4 space-x-3">
                                  <Link
                                    to={`/workOrderDetail/${detail.workOrderID}`}
                                  >
                                    <a className="font-medium text-green-600 hover:underline">
                                      <FaRegEye size={20} />
                                    </a>
                                  </Link>
                                  {item.status === 1551 && (
                                    <button
                                      onClick={() =>
                                        handleStartChangeStatus(
                                          detail.workOrderID
                                        )
                                      }
                                      title="İş emrini başlat"
                                    >
                                      <a className="font-medium text-green-600 hover:underline">
                                        <IoIosPlay size={25} />
                                      </a>
                                    </button>
                                  )}
                                  {item.status === 1552 && (
                                    <Link
                                      to={`/workOrderClosing/${detail.workOrderID}`}
                                      title="İş emrini kapat"
                                    >
                                      <a className="font-medium text-red-600 hover:underline">
                                        <FaFlagCheckered size={20} />
                                      </a>
                                    </Link>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </React.Fragment>
                      );
                    })
                ) : (
                  <tr>
                    <td
                      colSpan={10}
                      className="px-6 py-4 text-center text-2xl font-medium"
                    >
                      Henüz kayıtlı bir veri yoktur.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
      {openPdfModal === true && (
        <WorkOrderPdfModal
          data={data}
          pdfData={pdfData}
          onCancel={() => setOpenPdfModal(false)}
        />
      )}
    </>
  );
};

export default WorkOrdersActive;
