import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import CreatableSelect from "react-select/creatable";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import Swal from "sweetalert2";
import { useAuth } from "../../context/AuthContext";

const FormBuilderSchemaEdit = () => {
  const { user } = useAuth();
  const [customerID, setCustomerID] = useState("");
  const [textBoxSets, setTextBoxSets] = useState([]);
  const [textAreaSets, setTextAreaSets] = useState([]);
  const [radioBox, setRadioBox] = useState([]);
  const [checkBox, setCheckBox] = useState([]);
  const [listBox, setListBox] = useState([]);
  const [datePicker, setDatePicker] = useState([]);
  const [file, setFile] = useState([]);
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const params = useParams();

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=12`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.updatePermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(`/api/FormBuilder/Get?id=${params.id}&customerID=${user}`)
            .then((res) => {})
            .catch((err) => {
              console.log(err.data);
            });
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const addTextBoxSet = () => {
    setTextBoxSets([
      ...textBoxSets,
      {
        customerID,
        name: "",
        file: "",
        value: "",
        type: 93,
        status: 0,
        viewIndex: 0,
        description: "",
      },
    ]);
  };
  const addTextAreaBoxSet = () => {
    setTextAreaSets([
      ...textAreaSets,
      {
        customerID,
        name: "",
        file: "",
        value: "",
        type: 94,
        status: 0,
        viewIndex: 0,
        description: "",
      },
    ]);
  };
  const addRadioBoxSet = () => {
    setRadioBox([
      ...radioBox,
      {
        customerID,
        name: "",
        file: "",
        value: "",
        type: 95,
        status: 0,
        viewIndex: 0,
        description: "",
      },
    ]);
  };
  const addCheckBoxSet = () => {
    setCheckBox([
      ...checkBox,
      {
        customerID,
        name: "",
        file: "",
        value: "",
        type: 96,
        status: 0,
        viewIndex: 0,
        description: "",
      },
    ]);
  };
  const addListBoxSet = () => {
    setListBox([
      ...listBox,
      {
        customerID,
        name: "",
        file: "",
        value: "",
        type: 97,
        status: 0,
        viewIndex: 0,
        description: "",
      },
    ]);
  };
  const addDatePickerSet = () => {
    setDatePicker([
      ...datePicker,
      {
        customerID,
        name: "",
        file: "",
        value: "",
        type: 99,
        status: 0,
        viewIndex: 0,
        description: "",
      },
    ]);
  };
  const addFileSet = () => {
    setFile([
      ...file,
      {
        customerID,
        name: "",
        file: "",
        value: "",
        type: 100,
        status: 0,
        viewIndex: 0,
        description: "",
      },
    ]);
  };

  // Function to update the data of a specific text box set
  const updateTextBoxSet = (index, key, value) => {
    const updatedSets = [...textBoxSets];
    updatedSets[index][key] = value;
    setTextBoxSets(updatedSets);
  };

  const updateTextAreaSet = (index, key, value) => {
    const updatedSets = [...textAreaSets];
    updatedSets[index][key] = value;
    setTextAreaSets(updatedSets);
  };
  const updateRadioBox = (index, key, value) => {
    const updatedRadio = [...radioBox];
    updatedRadio[index][key] = value;
    setRadioBox(updatedRadio);
  };
  const updateCheckBox = (index, key, value) => {
    const updatedCheckBox = [...checkBox];
    updatedCheckBox[index][key] = value;
    setCheckBox(updatedCheckBox);
  };
  const updateListBox = (index, key, value) => {
    const updatedListBox = [...listBox];
    updatedListBox[index][key] = value;
    setListBox(updatedListBox);
  };
  const handleCreatableChange = (index, selectedOptions) => {
    const selectedValues = selectedOptions.map((item) => item.value);
    updateListBox(index, "value", selectedValues.join(", ")); // Join values as a string
  };

  const updateDatePicker = (index, key, value) => {
    const updatedDatePicker = [...datePicker];
    updatedDatePicker[index][key] = value;
    setDatePicker(updatedDatePicker);
  };
  const updateFile = (index, key, value) => {
    const updatedFile = [...file];
    updatedFile[index][key] = value;
    setFile(updatedFile);
  };

  const location = useLocation();
  const formData =
    location.state && location.state.name
      ? location.state
      : { name: "", description: "" };

  const postData = {
    customerID,
    imageFile: "",
    name: formData.name,
    type: 0,
    status: 0,
    viewBox: 0,
    description: formData.description,
    keywords: "",
    formFields: [],
  };

  if (textBoxSets.length > 0) {
    postData.formFields = postData.formFields.concat(textBoxSets);
  }

  if (textAreaSets.length > 0) {
    postData.formFields = postData.formFields.concat(textAreaSets);
  }
  if (radioBox.length > 0) {
    postData.formFields = postData.formFields.concat(radioBox);
  }
  if (checkBox.length > 0) {
    postData.formFields = postData.formFields.concat(checkBox);
  }
  if (listBox.length > 0) {
    postData.formFields = postData.formFields.concat(listBox);
  }
  if (datePicker.length > 0) {
    postData.formFields = postData.formFields.concat(datePicker);
  }
  if (file.length > 0) {
    postData.formFields = postData.formFields.concat(file);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // api
    //   .post("/api/FormBuilder/Post/Post", postData)

    //   .then((res) => {
    //     Swal.fire({
    //       position: "top-end",
    //       icon: "success",
    //       title: "Form oluşturuldu !",
    //       showConfirmButton: false,
    //       timer: 1500,
    //     });
    //     navigate("/formList");
    //   })

    //   .catch((err) => {
    //     console.log(err);
    //     Swal.fire("Form Oluşturalamadı !");
    //   });
  };
  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />

        <aside
          id="default-sidebar"
          className=" right-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0 absolute"
          aria-label="Sidebar"
        >
          <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50">
            <ul className="space-y-2 font-medium">
              <li>
                <a
                  className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                  onClick={addTextBoxSet}
                >
                  <i className="fa-solid fa-font"></i>

                  <span className="ms-3">Text Box</span>
                </a>
              </li>
              <li>
                <a
                  className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                  onClick={addTextAreaBoxSet}
                >
                  <i className="fa-solid fa-paragraph"></i>

                  <span className="ms-3">Text Area</span>
                </a>
              </li>
              <li>
                <a
                  className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                  onClick={addRadioBoxSet}
                >
                  <i className="fa-regular fa-circle-dot"></i>

                  <span className="ms-3">Radio</span>
                </a>
              </li>
              <li>
                <a
                  className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                  onClick={addCheckBoxSet}
                >
                  <i className="fa-regular fa-circle-check"></i>

                  <span className="ms-3">Check Box</span>
                </a>
              </li>
              <li>
                <a
                  className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                  onClick={addListBoxSet}
                >
                  <i className="fa-solid fa-list-ol"></i>

                  <span className="ms-3">List Box</span>
                </a>
              </li>
              <li>
                <a className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group">
                  <i className="fa-regular fa-star"></i>

                  <span className="ms-3">Score Box</span>
                </a>
              </li>
              <li>
                <a
                  className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                  onClick={addDatePickerSet}
                >
                  <i className="fa-regular fa-calendar-days"></i>

                  <span className="ms-3">Date Picker</span>
                </a>
              </li>
              <li>
                <a
                  className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                  onClick={addFileSet}
                >
                  <i className="fa-solid fa-upload"></i>

                  <span className="ms-3">File Upload</span>
                </a>
              </li>
            </ul>
          </div>
        </aside>

        <form
          className="m-12 w-[67vw] h-[90vh] p-8 bg-[#f6f6f6] rounded-lg flex flex-col gap-1 overflow-y-scroll"
          onSubmit={handleSubmit}
        >
          <h1 className="text-center font-medium text-2xl">{formData.name}</h1>
          <p>{formData.description}</p>
          {textBoxSets.map((item, index) => (
            <div key={index} className="mb-5 flex mt-12">
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-36 p-2.5"
                placeholder="Başlık giriniz..."
                required
                value={item.name}
                onChange={(e) =>
                  updateTextBoxSet(index, "name", e.target.value)
                }
              />
              <input
                type="text"
                className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Açıklama..."
                disabled
              />
            </div>
          ))}

          {textAreaSets.map((item, index) => (
            <div key={index} className="mb-5 flex">
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-36 p-2.5"
                placeholder="Başlık giriniz..."
                required
                value={item.name}
                onChange={(e) =>
                  updateTextAreaSet(index, "name", e.target.value)
                }
              />
              <textarea
                type="text"
                className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Açıklama..."
                disabled
              ></textarea>
            </div>
          ))}
          {radioBox.map((item, index) => (
            <div key={index}>
              <ul className="items-center w-full text-sm font-medium text-gray-900 sm:flex">
                <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r">
                  <div className="flex items-center">
                    <input
                      id="horizontal-list-radio-license"
                      type="radio"
                      value=""
                      disabled
                      name="list-radio"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      for="horizontal-list-radio-license"
                      className="w-full py-3 ms-2 text-sm font-medium text-gray-900"
                    >
                      <input
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-36 p-2.5"
                        placeholder="Başlık giriniz..."
                        required
                        value={item.name}
                        onChange={(e) =>
                          updateRadioBox(index, "name", e.target.value)
                        }
                      />
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          ))}
          {checkBox.map((item, index) => (
            <div key={index}>
              <div class="flex items-center mb-4">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  value=""
                  disabled
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                />
                <label
                  for="default-checkbox"
                  className="ms-2 text-sm font-medium text-gray-900"
                >
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-36 p-2.5"
                    placeholder="Başlık giriniz..."
                    required
                    value={item.name}
                    onChange={(e) =>
                      updateCheckBox(index, "name", e.target.value)
                    }
                  />
                </label>
              </div>
            </div>
          ))}

          {datePicker.map((item, index) => (
            <div key={index} className="flex">
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-36 p-2.5"
                placeholder="Başlık giriniz..."
                required
                value={item.name}
                onChange={(e) =>
                  updateDatePicker(index, "name", e.target.value)
                }
              />
              <div className="relative max-w-sm">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                  </svg>
                </div>
                <input
                  datepicker
                  type="date"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                  placeholder="Select date"
                  disabled
                />
              </div>
            </div>
          ))}

          {file.map((item, index) => (
            <div key={index} className="mt-5 flex items-center gap-2">
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-36 p-2.5"
                placeholder="Başlık giriniz..."
                required
                value={item.name}
                onChange={(e) => updateFile(index, "name", e.target.value)}
              />
              <input id="file_input" type="file" disabled />
            </div>
          ))}

          {listBox.map((item, index) => (
            <div key={index} className="mt-5 flex">
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-36 p-2.5"
                placeholder="Başlık giriniz..."
                required
                value={item.name}
                onChange={(e) => updateListBox(index, "name", e.target.value)}
              />
              <CreatableSelect
                onChange={(selectedOptions) =>
                  handleCreatableChange(index, selectedOptions)
                }
                isMulti
                styles={{
                  control: (base, state) => ({
                    ...base,
                    width: "500px",
                    minHeight: "30px",
                  }),
                }}
              />
            </div>
          ))}

          <div>
            <button
              type="submit"
              className="bg-blue-500 w-36 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg m-5 float-right"
            >
              Kaydet
              <i className="fa-regular fa-floppy-disk ml-2"></i>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default FormBuilderSchemaEdit;
