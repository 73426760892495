import React, { useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Calendar,
  momentLocalizer,
  dateFnsLocalizer,
} from "react-big-calendar";
import moment from "moment";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import { useNavigate } from "react-router-dom";
import "moment/locale/tr";
import tr from "date-fns/locale/tr";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import "./calendar.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner";

const localizer = momentLocalizer(moment);

const Calendars = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [assignedUser, setAssignedUser] = useState([]);
  const [myMeetings, setMyMeetings] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedToken1 =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    setToken(localStorage.getItem("token"));
    if (user && user.country) {
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=20`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/Task/GetTasksByAssignedUser?customerID=${user.country}&userID=${user.nameidentifier}`
            )
            .then((res) => {
              const formattedTasks = res.data.map((task) => ({
                title: task.name,
                start: moment(task.startDate).toDate(),
                end: moment(task.endDate).toDate(),
                id: task.taskID,
                color: "#94a0df",
              }));
              setAssignedUser(formattedTasks);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));

          const fetchData = async () => {
            try {
              // Axios isteği yapılandırma
              const response = await api
                .get(
                  `/api/Meeting/GetMeetingsForUser?customerID=${user.country}`,
                  {
                    headers: {
                      Authorization: `Bearer ${storedToken1}`,
                    },
                  }
                )
                .then((res) => {
                  const formattedMyMeetings = res.data.map((meeting) => ({
                    title: meeting.name,
                    type: meeting.type,
                    name: meeting.title,
                    description: meeting.description,
                    id: meeting.meetingID,
                    start: moment(meeting.startDate).toDate(),
                    end: moment(meeting.startDate)
                      .add(meeting.duration, "minutes")
                      .toDate(), // duration ekleme
                    color: "#e67c73",
                  }));
                  setMyMeetings(formattedMyMeetings);
                });

              setData(response.data);
            } catch (error) {
              console.error("Error fetching data: ", error);
            } finally {
              setLoading(false); // İstek tamamlandığında setLoading(false) çağrısı ekleniyor.
            }
          };

          fetchData();
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const messages = {
    allDay: "Tüm gün",
    previous: "Önceki",
    next: "Sonraki",
    today: "Bugün",
    month: "Ay",
    week: "Hafta",
    day: "Gün",
    agenda: "Ajanda",
    date: "Tarih",
    time: "Saat",
    event: "Etkinlik",
    showMore: (total, meetings) => `+${total} daha`,
  };

  const locales = {
    tr: tr, // Türkçe dil dosyası
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const handleClick = (event) => {
    const isMeeting = myMeetings.some((meeting) => meeting.id === event.id);
    const isTask = assignedUser.some((task) => task.id === event.id);

    if (isMeeting) {
      // Eğer tıklanan etkinlik bir toplantı ise ilgili toplantının detayına git
      navigate(`../my-meetings`);
    } else if (isTask) {
      // Eğer tıklanan etkinlik bir görev ise ilgili görevin detayına git
      navigate(`../taskDetailByAssignedUser/${event.id}`);
    }
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="mx-auto h-[100vh]">
            <Calendar
              localizer={localizer}
              culture="tr"
              events={[...assignedUser, ...myMeetings]}
              startAccessor="start"
              endAccessor="end"
              onDoubleClickEvent={handleClick}
              style={{
                paddingTop: "50px",
                paddingBottom: "50px",
                width: "75vw",
              }}
              views={["month", "week", "day"]}
              messages={messages}
              selectable={true}
              eventPropGetter={(event) => ({
                style: {
                  backgroundColor: event.color,
                },
              })}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Calendars;
