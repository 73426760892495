import React, { useEffect, useState } from "react";
import { RiHome4Line } from "react-icons/ri";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi/";
import { Sidebar, SubMenu, Menu, MenuItem } from "react-pro-sidebar";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getModules } from "../../redux/userDataSlice";
import BarLoader from "react-spinners/BarLoader";

function Sidebars() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  useEffect(() => {
    dispatch(getModules());
  }, []);

  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(null); // Hover durumunu kontrol etmek için state

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  // Responsive sidebar toggle
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1500) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Sidebar
        className={`app ${toggled ? "toggled" : ""} h-full`}
        backgroundColor="#020617"
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
        width="350px"
      >
        <main>
          <Menu>
            {collapsed ? (
              <MenuItem
                icon={<FiChevronsRight style={{ color: "white" }} />}
                onClick={handleCollapsedChange}
              ></MenuItem>
            ) : (
              <MenuItem
                onMouseEnter={() => setHoverIndex("menu")} // Menü hover başlar
                onMouseLeave={() => setHoverIndex(null)} // Menü hover biter
                suffix={<FiChevronsLeft />}
                onClick={handleCollapsedChange}
                style={{
                  color: "white",
                  backgroundColor: hoverIndex === "menu" ? "#1e293b" : "transparent", // Menü hover rengi
                  transition: "background-color 0.3s ease",
                }}
              >
                <h1 className="ml-[15px] font-medium text-white">
                  <i className="fa-solid fa-bars mr-3 text-white"></i>
                  Menü
                </h1>
              </MenuItem>
            )}
            <hr className="border-none h-[1px] bg-slate-700" />
          </Menu>

          {!state.isLoading ? (
            <Menu>
              <Link style={{ color: "white" }} to={"/dashboards"}>
                <MenuItem
                  onMouseEnter={() => setHoverIndex("home")} // Hover başlar
                  onMouseLeave={() => setHoverIndex(null)} // Hover biter
                  style={{
                    fontWeight: "500",
                    marginTop: "10px",
                    backgroundColor:
                      hoverIndex === "home" ? "#1e293b" : "transparent", // Hover olduğunda renk değişimi
                    transition: "background-color 0.3s ease",
                  }}
                  icon={<RiHome4Line color="white" size={20} />}
                >
                  Anasayfa
                </MenuItem>
              </Link>
              {state.userModules?.map((item, index) =>
                item.rolesModulesSub?.length ? (
                  <SubMenu
                    onMouseEnter={() => setHoverIndex(index)}
                    onMouseLeave={() => setHoverIndex(null)}
                    style={{
                      color: "white",
                      fontWeight: "500",
                      paddingBottom: "30px",
                      paddingTop: "30px",
                      backgroundColor:
                        hoverIndex === index ? "#1e293b" : "transparent",
                      transition: "background-color 0.3s ease",
                    }}
                    key={index}
                    label={item.moduleName}
                    icon={<i style={{ color: "white" }} className={`${item.icon}`}></i>}
                  >
                    {item.rolesModulesSub.map((subItem, subIndex) => (
                      <Link to={subItem.moduleLink} key={subIndex}>
                        <MenuItem
                          onMouseEnter={() => setHoverIndex(`${index}-${subIndex}`)}
                          onMouseLeave={() => setHoverIndex(null)}
                          style={{
                            color: "white",
                            fontWeight: "500",
                            backgroundColor:
                              hoverIndex === `${index}-${subIndex}`
                                ? "#1e293b"
                                : "#020617",
                            transition: "background-color 0.3s ease",
                          }}
                          icon={<i style={{ color: "white" }} className={`${subItem.icon}`}></i>}
                        >
                          {subItem.moduleName}
                        </MenuItem>
                      </Link>
                    ))}
                  </SubMenu>
                ) : (
                  <Link to={item.moduleLink} key={index}>
                    <MenuItem
                      onMouseEnter={() => setHoverIndex(index)}
                      onMouseLeave={() => setHoverIndex(null)}
                      style={{
                        color: "gray",
                        fontWeight: "500",
                        backgroundColor:
                          hoverIndex === index ? "#1e293b" : "transparent",
                        transition: "background-color 0.3s ease",
                      }}
                      icon={<i style={{ color: "#0766AD" }} className={`${item.icon}`}></i>}
                    >
                      {item.moduleName}
                    </MenuItem>
                  </Link>
                )
              )}
            </Menu>
          ) : (
            <div
              style={{
                display: "grid",
                placeItems: "center",
                alignItems: "center",
                marginTop: "200px",
              }}
            >
              <BarLoader
                color={"blue"}
                loading={state.isLoading}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
                speedMultiplier={0.5}
              />
            </div>
          )}
        </main>
      </Sidebar>
    </div>
  );
}

export default Sidebars;
