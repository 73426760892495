import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import api from "../../api/api";
import Swal from "sweetalert2";
import { useAuth } from "../../context/AuthContext";

const DeleteProduct = ({ onCancel = () => {} }) => {
  const { user } = useAuth();
  const [stockCardsList, setStockCardList] = useState([]);
  const [stockCardsListName, setStockCardListName] = useState(null);
  const [customerID, setCustomerID] = useState(Number());

  useEffect(() => {
    if  (user && user.country){
    setCustomerID(user.country);

    api
      .get(`/api/StockCard/GetStockCards?customerID=${user.country}`)
      .then((res) => {
        setStockCardList(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
    }
  }, [user]);

  const getStockCardsName = stockCardsList?.map((item) => ({
    value: item.stockCardID,
    label: item.name,
  }));

  const handleClick = (e)=>{
    e.preventDefault()
    api.delete(`/api/StockCard/DeleteStockCard/${stockCardsListName.value}?customerID=${customerID}`)
    .then((res)=>{
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Ürün silindi !",
            showConfirmButton: false,
            timer: 1500,
          });
          onCancel();
    })
    .catch((err)=>{
        console.log(err.data);
        Swal.fire("Ürün silinemedi !");
    })
  }
  
  return (
    <div className="fixed inset-0 w-screen h-screen bg-black bg-opacity-30 z-[1000] flex justify-center items-center">
  <div className="w-3/5 p-5 bg-white rounded-lg flex flex-col justify-center items-center">
        <div className="w-full">
          <label
            htmlFor="title"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Silinecek ürünü seçiniz...
          </label>
          <Select
            value={stockCardsListName}
            onChange={(e) => setStockCardListName(e)}
            id="getStockCard"
            options={getStockCardsName}
          />
        </div>

        <div className="flex justify-center gap-[20px]">
          <button
            onClick={onCancel}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full m-5"
          >
            Vazgeç
          </button>
          <button
            type="button"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5"
            onClick={handleClick}
          >
            Onayla
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteProduct;
