import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PrivateRoute = ({children}) => {
  // const { user } = useAuth();
  const tokenLocal = localStorage.getItem('token');
  const tokenSession = sessionStorage.getItem('token');
  return tokenLocal || tokenSession ? children : <Navigate to="/" />;
};

export default PrivateRoute;