import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import Tree from "react-d3-tree";
import api from "../../api/api";
import "./custom-tree.css";
import JobAdd from "./JobAdd";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import JobRemove from "./JobRemove";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner";

const OrganazationSchema = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModule, setOpenModule] = useState(false);
  const [openModuleRemove, setOpenModuleRemove] = useState(false);

  useEffect(() => {
    if (user && user.country) {
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=21`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(`/api/User/GetOrganizastionChart?customerID=${user.country}`)
            .then((res) => {
              const modifiedData = modifyData(res.data);
              setData(modifiedData);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  // Veri yapısındaki "person" başlığını "personel" olarak değiştiren fonksiyon
  const modifyData = (data) => {
    const modifiedData = JSON.parse(JSON.stringify(data)); // Derin kopya

    const traverse = (node) => {
      if (node.attributes && node.attributes.person) {
        node.attributes.P = node.attributes.person; // "person"ı "personel" olarak değiştir
        delete node.attributes.person; // "person"ı kaldır
      }
      if (node.children) {
        node.children.forEach((child) => traverse(child));
      }
    };

    traverse(modifiedData);
    return modifiedData;
  };

  const containerStyles = {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const treeContainerStyles = {
    transform: "",
    width: "100vw", // Flipping width and height
    height: "100%", // Flipping width and height
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div style={containerStyles}>
            <div style={treeContainerStyles}>
              <Tree
                data={data}
                orientation="vertical"
                separation={{ siblings: 3, nonSiblings: 3 }}
                translate={{ x: 1300, y: 100 }} // Daha iyi konumlandırma için bu değerleri ayarlayın
                pathFunc="step"
                rootNodeClassName="node__root"
                branchNodeClassName="node__branch"
                leafNodeClassName="node__leaf"
                zoomable={true}
                zoom={0.5}
              />
            </div>
            <button
              onClick={() => {
                setOpenModuleRemove(true);
              }}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg m-2"
              style={{ position: "absolute", top: 100, right: 85 }}
            >
              Sil
            </button>
            <button
              onClick={() => {
                setOpenModule(true);
              }}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg m-2"
              style={{ position: "absolute", top: 100, right: 10 }}
            >
              Ekle
            </button>
          </div>
        )}
        {openModule === true && (
          <JobAdd
            onCancel={() => setOpenModule(false)}
            onSubmitCancel={() => {
              setOpenModule(false);
              window.location.reload();
            }}
          />
        )}
        {openModuleRemove === true && (
          <JobRemove
            onCancel={() => setOpenModuleRemove(false)}
            onSubmitCancel={() => {
              setOpenModuleRemove(false);
              window.location.reload();
            }}
          />
        )}
      </div>
      <Footer />
    </>
  );
};

export default OrganazationSchema;
