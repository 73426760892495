import React, { useState } from 'react';
import Footer from '../components/bars/Footer';

const App = () => {
  const [data, setData] = useState([
    { id: 1, name: 'Apple MacBook Pro 17"', color: 'Silver', category: 'Laptop', price: '$2999', details: [] },
    { id: 2, name: 'Microsoft Surface Pro', color: 'White', category: 'Laptop PC', price: '$1999', details: [] },
    { id: 3, name: 'Magic Mouse 2', color: 'Black', category: 'Accessories', price: '$99', details: [] },
  ]);

  const handleRowClick = (id) => {
    setData(prevData =>
      prevData.map(item =>
        item.id === id
          ? {
              ...item,
              details: item.details.length ? [] : ['Detail 1', 'Detail 2', 'Detail 3'],
            }
          : item
      )
    );
  };

  return (
    <div className="relative overflow-x-auto">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3">
              Product name
            </th>
            <th scope="col" className="px-6 py-3">
              Color
            </th>
            <th scope="col" className="px-6 py-3">
              Category
            </th>
            <th scope="col" className="px-6 py-3">
              Price
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <React.Fragment key={item.id}>
              <tr className="bg-white border-b" onClick={() => handleRowClick(item.id)}>
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap cursor-pointer">
                  {item.name}
                </th>
                <td className="px-6 py-4 cursor-pointer">
                  {item.color}
                </td>
                <td className="px-6 py-4 cursor-pointer">
                  {item.category}
                </td>
                <td className="px-6 py-4 cursor-pointer">
                  {item.price}
                </td>
              </tr>
              {item.details.map((detail, index) => (
                <tr key={index} className="bg-gray-100">
                  <td colSpan="4" className="px-6 py-4 text-gray-700">
                    {detail}
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <Footer/>
    </div>
  );
};

export default App;

//  <div className="w-full bg-gray-300 absolute h-8"></div>
