import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import logo from "../../assets/images/logo.png";

// Font ayarları
// Register Font
Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});

// Stil ayarları
const styles = StyleSheet.create({
  body: {
    paddingTop: 50,
    paddingBottom: 65,
    paddingHorizontal: 15,
    fontFamily: "Roboto",
  },
  header: {
    fontSize: 16,
    marginTop: 20,
    marginBottom: 20,
    textAlign: "center",
    color: "black",
    fontFamily: "Roboto",
  },
  table: {
    display: "table",
    width: "auto",
    margin: 12,
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "25%", // Adjust the width as needed
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Roboto",
    color: "black",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
    fontFamily: "Roboto",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    fontFamily: "Roboto",
  },
  logoContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 170, // Adjust width and height as needed
    height: 80,
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: 140, // Adjust width and height to fit your logo
    height: 40,
  },
});

const UsersPDF = ({pdfData, data }) => {
  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();
    return `${gun}/${ay}/${yil}`;
  };

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.logoContainer}>
          <Image src={logo} style={styles.logo} />
        </View>
        <Text style={styles.header} fixed>
          User Information
        </Text>
        <Text style={styles.header} fixed>Belge No: {pdfData}</Text>
        
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Kullanıcı Adı</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Adı</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Soyadı</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>E-Mail</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Telefon</Text>
            </View>
          </View>

          {data?.map((item, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.userName}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.surname}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.email}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.telephone}</Text>
              </View>
            </View>
          ))}
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
};

export default UsersPDF;
