import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import { useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner";

const PersonelGroupDetail = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [groupData, setGroupData] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=25`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.reportPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(`/api/Personel/GetPersonGroup?id=${params.id}`)
            .then((res) => {
              setGroupData(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            })
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);
  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex justify-center items-center gap-8 mx-auto min-h-screen">
            <div className="flex mt-32 items-center h-screen flex-col gap-10">
              <div className="bg-gray-50 overflow-hidden max-w-[40rem] min-w-[40rem]">
                <div className="px-4 py-5 sm:px-6 bg-slate-900">
                  <h3 className="text-lg leading-6 font-medium text-white ">
                    Personel Grup Bilgileri
                  </h3>
                </div>
                <div className="border-t border-gray-200 grid-cols-2 grid">
                  <div className="col-span-2 sm:col-span-1">
                    <div className="bg-gray-50 px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm  font-medium text-gray-500"
                      >
                        Personel Grup Adı :
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {groupData?.name}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm font-medium text-gray-500"
                      >
                        Başlık :
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {groupData?.title}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm font-medium text-gray-500"
                      >
                        Açıklama :
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {groupData?.description}
                      </dd>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default PersonelGroupDetail;
