import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import left from "../../assets/images/left-click.png";
import right from "../../assets/images/right-click.png";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import { FaStarOfLife } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaArrowLeftLong } from "react-icons/fa6";

const ProjectAdd = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [customerID, setCustomerID] = useState(Number());
  const [token, setToken] = useState(null);
  const [users, setUsers] = useState(null);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [customerKey, setCustomerKey] = useState(null);
  const [keywords, setKeywords] = useState([]);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedUser, setSelectedUser] = useState([]);
  const [projectType, setProjectType] = useState(null);
  const [selectedProjectType, setSelectedProjectType] = useState([]);

  //ASSIGNED USERS MODULE
  const [data, setData] = useState([]);
  const [assignedModules, setAssignedModules] = useState([]);
  const [preAssignedModules, setPreAssignedModules] = useState([]);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [userModules, setUserModules] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=14`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.insertPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newData = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setCustomerKey(newData);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/User/GetUsers?customerID=${user.country}`)
            .then((res) => {
              setUserModules(res.data);
              const data = res.data;
              const newData = data.map((item) => ({
                value: item.userID,
                label: item.userName,
              }));
              setUsers(newData);
            })
            .catch((err) => console.log(err.data));

          api
            .get("/api/Common/GetParameters?parametername=ProjectType")
            .then((res) => {
              const projectData = res.data;
              const newDataProject = projectData.map((item) => ({
                value: item.parameterID, //backend istenen
                label: item.parameterValue2, //ekrana basılan
              }));
              setProjectType(newDataProject);
            })
            .catch((err) => console.log(err.data));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  /// ASSIGNED USERS MODEL OPTIONS
  const moveItemToAssigned = () => {
    if (selectedItemData && !assignedModules.includes(selectedItemData)) {
      const yeniOge = {
        ...selectedItemData,
        customerID,
      };

      setAssignedModules((oncekiModuller) => [...oncekiModuller, yeniOge]);
      setData((oncekiData) =>
        oncekiData.filter((oge) => oge !== selectedItemData)
      );
      setSelectedItemData(null);
    }
  };

  const moveItemToAllModules = () => {
    if (selectedItemData && !data.includes(selectedItemData)) {
      setData((prevData) => [...prevData, selectedItemData]);
      setAssignedModules((prevModules) =>
        prevModules.filter((item) => item !== selectedItemData)
      );
      setSelectedItemData(null);
    }
  };

  const postData = {
    customerID,
    parentID: 0,
    userID: selectedUser.value,
    document: "",
    name,
    title,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    type: selectedProjectType.value,
    status: 0,
    viewIndex: 0,
    description,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),

    assignedUsers: assignedModules.map((item) => ({
      userID: item.userID,
    })),
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    api
      .post("/api/Project/Post", postData)

      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Proje oluşturuldu !",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/projects");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `${err}`,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  return (
    <>
      <Header />
      <div className="flex min-h-screen h-full">
        <Sidebars />
        <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen w-[75%] bg-gray-100">

        <div className="mb-5 flex items-center gap-2">
        <FaStarOfLife size={15}/>
            <h1 className="text-3xl font-semibold title">
              Proje Oluştur
            </h1>
          </div>
          <form
            onSubmit={handleSubmit}
            className="grid grid-cols-2 gap-7 items-center w-full"
          >
            <div className="">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Proje Adı :
              </label>
              <input
                type="text"
                id="name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="">
              <label
                htmlFor="title"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Proje Başlığı :
              </label>
              <input
                type="text"
                id="title"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="">
              <label
                htmlFor="keywords"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Anahtar Kelimeler :
              </label>
              <CreatableSelect
                onChange={(e) => setKeywords(e)}
                options={customerKey}
                isMulti
                maxMenuHeight={200}
                id="keywords"
                classNamePrefix="custom-select"
              />
            </div>
            <div className="">
              <label
                htmlFor="userSelect"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Kullanıcı Seç :
              </label>
              <Select
                maxMenuHeight={200}
                id="userSelect"
                options={users}
                value={selectedUser}
                onChange={(e) => setSelectedUser(e)}
                classNamePrefix="custom-select"
              />
            </div>
            <div className="col-span-1">
              <label
                htmlFor="startDate"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Başlangıç Tarih :
              </label>
              <DatePicker
                id="startDate"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                locale={tr}
                dateFormat={"dd/MM/yy"}
              />
            </div>
            <div className="col-span-1">
              <label
                htmlFor="projectType"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Proje Tipi :
              </label>
              <Select
                maxMenuHeight={200}
                id="projectType"
                options={projectType}
                value={selectedProjectType}
                onChange={(e) => setSelectedProjectType(e)}
                classNamePrefix="custom-select"
              />
            </div>

            <div className="col-span-1">
              <label
                htmlFor="endDate"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Bitiş Tarih :
              </label>
              <DatePicker
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                locale={tr}
                dateFormat={"dd/MM/yy"}
              />
            </div>
            <div>
              <label
                htmlFor="description"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Açıklama :
              </label>
              <textarea
                id="description"
                type="text"
                className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <div className="flex gap-16 h-full mt-16 col-span-2">
              <div className="overflow-y-scroll max-h-[500px] min-w-[300px] min-h-[300px] origin-top-right right-0 mt-2 w-64  bg-white ring-1 ring-black ring-opacity-5 focus:outline-none col-span-3">
              <h1 className="px-4 py-1 text-lg font-medium text-white bg-slate-900">
                  Tüm Kullanıcılar
                </h1>
                <div
                  className="py-1"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  {userModules
                    ?.filter(
                      (item) =>
                        !preAssignedModules.some(
                          (assignedItem) => assignedItem.userID === item.userID
                        ) &&
                        !assignedModules.some(
                          (assignedItem) => assignedItem.userID === item.userID
                        )
                    )
                    .map((item, index) => (
                      <button
                        key={index}
                        className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                        role="menuitem"
                        type="button"
                        onClick={() => setSelectedItemData(item)}
                      >
                        {item.userName}
                      </button>
                    ))}
                </div>
              </div>
              <div className="flex flex-col gap-7 justify-center">
                <button
                  onClick={moveItemToAssigned}
                  type="button"
                  className="w-12 hover:scale-110 transition-transform duration-200"
                >
                  <FaArrowRightLong color="green" size={50}/>
                </button>
                <button
                  onClick={moveItemToAllModules}
                  type="button"
                  className="w-12 hover:scale-110 transition-transform duration-200"
                >
                  <FaArrowLeftLong color="#E4003A" size={50}/>
                </button>
              </div>
              <div className="overflow-y-scroll max-h-[500px] min-w-[300px] origin-top-right right-0 mt-2 w-64 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <h1 className="px-4 py-1 text-lg font-medium text-white bg-slate-900">
                  Atanacak Kullanıcılar
                </h1>
                <div
                  className="py-1"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  {assignedModules.map((item) => (
                    <button
                      key={item.userID}
                      type="button"
                      className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                      role="menuitem"
                      onClick={() => setSelectedItemData(item)}
                    >
                      {item.userName}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-span-2 flex justify-end gap-[20px]">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg my-5"
              >
                Kaydet
                <i className="fa-regular fa-floppy-disk ml-2"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProjectAdd;
