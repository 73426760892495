import React, { useState, useRef, useCallback, useEffect } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
  Panel,
} from "reactflow";
import "reactflow/dist/style.css";
import Header from "../../components/bars/Header";
import CustomNode from "./CustomNode";

import "./workFlow.css";
import WorkFlowSideBar from "./WorkFlowSideBar";
import CustomNodeApr from "./CustomNodeApr";
import CustomNodeMail from "./CustomNodeMail";
import CustomNodeNotif from "./CustomNodeNotif";
import CustomNodeForm from "./CustomNodeForm";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomNodeAprPers from "./CustomNodeAprPers";
import CustomNodeMailPers from "./CustomNodeMailPers";
import CustomNodeNotifPers from "./CustomNodeNotifPers";
import CustomNodeSms from "./CustomNodeSms";
import CustomNodeSmsPers from "./CustomNodeSmsPers";
import api from "../../api/api";
import Swal from "sweetalert2";
import CustomNodeStart from "./CustomNodeStart";
import CustomNodeEnd from "./CustomNodeEnd";
import CustomNodeDemand from "./CustomNodeDemand";
import CustomNodeOffer from "./CustomNodeOffer";
import CustomNodeOrder from "./CustomNodeOrder";
import CustomNodeInvoice from "./CustomNodeInvoice";
import CustomNodeProject from "./CustomNodeProject";
import CustomNodeTask from "./CustomNodeTask";
import CustomNodeDocument from "./CustomNodeDocument";
import CustomNodeFormModule from "./CustomNodeFormModule";
import CustomNodeMeeting from "./CustomNodeMeeting";
import { useAuth } from "../../context/AuthContext";
import CustomNodeWorkOrder from "./CustomNodeWorkOrder";
import CustomNodeControlWareHouse from "./CustomNodeControlWareHouse";
import CustomNodeWaybillWarehouse from "./CustomNodeWaybillWarehouse";



const nodeTypes = {
  custom: CustomNode,
  custom88: CustomNodeApr,
  custom50: CustomNodeMail,
  custom51: CustomNodeNotif,
  custom52: CustomNodeForm,
  custom49: CustomNodeAprPers,
  custom89: CustomNodeMailPers,
  custom90: CustomNodeNotifPers,
  custom92: CustomNodeSms,
  custom91: CustomNodeSmsPers,
  custom86: CustomNodeStart,
  custom87: CustomNodeEnd,
  custom43: CustomNodeDemand,
  custom44: CustomNodeOffer,
  custom45: CustomNodeOrder,
  custom46: CustomNodeInvoice,
  custom47: CustomNodeProject,
  custom48: CustomNodeTask,
  custom53: CustomNodeDocument,
  custom134: CustomNodeFormModule,
  custom141: CustomNodeMeeting,
  custom1582: CustomNodeWorkOrder,
  custom1596: CustomNodeControlWareHouse,
  custom1597: CustomNodeWaybillWarehouse
};




const WorkFlow = () => {
  const { user } = useAuth();
  const [userData, setUserData] = useState(null)
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [token, setToken] = useState(null);
  
  const navigate = useNavigate()
  const location = useLocation();
  const formData = location.state



  useEffect(()=>{
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if  (user && user.country){
      api
      .get(
        `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=11`
      )
    .then((res) => {
      // İlgili veriyi kontrol et
      if (res.data.assigmentID === undefined || !res.data.insertPermission) {
        // SweetAlert2 ile uyarıyı göster ve yönlendir
        Swal.fire({
          icon: "error",
          title: "Yetkiniz Yok",
          text: "Bu sayfaya yetkiniz yoktur.",
          confirmButtonText: "Tamam",
        }).then((result) => {
          if (result.isConfirmed) {
            // Yönlendirme işlemi
            navigate("/dashboards");
          }
        });
        return; // Diğer API çağrılarını atla
      }
      setUserData(user)
      
    })
    .catch((err) => console.log(err.data));
    }
  },[user])
  

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const type = event.dataTransfer.getData("application/reactflow");
      const nodeId = event.dataTransfer.getData('nodeId');
    

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      let newNodeType = "custom"; // default type

     // Determine the type based on conditions
     switch (type) {
      case "Departman Onayı": //Departman Onay
        newNodeType = "custom88";
        break;
      case "Departman-Email": //Departman Mail Bildirimi
        newNodeType = "custom50";
        break;
      case "Departman-Bildirim": //Departman Bildirimi
        newNodeType = "custom51";
        break;
      case "Form": //Form Çağır
        newNodeType = "custom52";
        break;
      case "Personel Onayı": //Personel Onay
        newNodeType = "custom49";
        break;
      case "Personele Email": //Personel Mail Bildirimi
        newNodeType = "custom89";
        break;
      case "Personele Bildirim": //Personel Bildirimi
        newNodeType = "custom90";
        break;
      case "Departman-SMS": //Departman SMS Bildirimi
        newNodeType = "custom92";
        break;
      case "Personele SMS": //Personel SMS Bildirimi
        newNodeType = "custom91";
        break;
      case "Başla": //Başlat
        newNodeType = "custom86";
        break;
      case "Bitir": //Bitir
        newNodeType = "custom87";
        break;
      case "Satın Alma Talebi": //Talep
        newNodeType = "custom43";
        break;
      case "Teklif": //Teklif
        newNodeType = "custom44";
        break;
      case "Sipariş": //Sipariş
        newNodeType = "custom45";
        break;
      case "Fatura": //Fatura
        newNodeType = "custom46";
        break;
      case "Proje": //Proje
        newNodeType = "custom47";
        break;
      case "Görev": // Görev
        newNodeType = "custom48";
        break;
      case "Doküman": // Döküman
        newNodeType = "custom53";
        break;
      case "Form Modülü": // Form Modülü
        newNodeType = "custom134";
        break;
      case "Toplantı": // Toplantı Modülü
        newNodeType = "custom141";
        break;
      case "İş Emri": // Toplantı Modülü
        newNodeType = "custom1582";
        break;
      case "Kontrol Depo İşlemleri": // Kontrol Depo İşlemleri
        newNodeType = "custom1596";
        break;
      case "İrsaliye-Depo İşlemleri": // İrsaliye-Depo İşlemleri
        newNodeType = "custom1597";
        break;
      // Add more conditions as needed
      default:
        break;
    }
      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const newNode = {
        id: nodeId,
        type: newNodeType,
        position,
        data: {
           label: `${type}`, 
           selects: {
            'status': '0',
          },
          formID:{
            'value':'0'
          },
          checked:{
            'value': false
          },
          stockCardID:{
            'value': '0'
          }

          },
        
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance]
  );

  

  const postData = {
    customerID: userData?.country,
    parentID : 0,
    title: formData.title,
    name: formData.name,
    type : 0,
    status:0,
    viewIndex : 0,
    description: formData.description,
    keywords: "",
    workFlowNodes: 
     nodes?.map((nodes)=>(
      {
        customName: nodes?.id,
        customerID: userData?.country,
        workFlowID: "",
        formID: nodes?.data?.formID?.value,
        isRequired: nodes?.data?.checked?.value,
        userID: userData?.nameidentifier,
        name: nodes?.data?.label,
        title: nodes?.data?.label,
        description: "",
        viewIndex: 0,
        keywords: "",
        positionX: nodes?.position?.x,
        positionY: nodes?.position?.y,
        width: nodes?.width,
        height: nodes?.height,
        selected: false,
        type: nodes?.id,
        status: nodes?.data?.selects?.status,
        stockCardID: nodes?.data?.stockCardID?.value,
        
      }
     )),
    
    workFlowEdges: 
      edges?.map((edges)=>(
        {
          source: edges?.source,
          sourceHandle: edges?.sourceHandle,
          target: edges?.target,
          targetHandle: edges?.targetHandle,
          id: edges?.id,
          customName: edges?.id
        }
      ))
    
    
  }

  


 


const handleSubmit = (e) => {
  e.preventDefault();
  api
  .post("/api/WorkFlow/Post/Post", postData)

    .then((res) => {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "İş akışı oluşturuldu !",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/workFlowList")
    }
    )

    .catch((err) => {
      console.log(err);
      Swal.fire("İş akışı oluşturalamadı !");
    });
};

console.log(postData);
  return (
      <>
      <Header />
      <div className="dndflow">
        <ReactFlowProvider>
          <div className="reactflow-wrapper" ref={reactFlowWrapper}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onInit={setReactFlowInstance}
              onDrop={onDrop}
              onDragOver={onDragOver}
              fitView
              nodeTypes={nodeTypes}
              zoom={30}
            >
              <Controls />
              <Panel position="top-left">
                <Link to={"/workFlowList"}>
                  <button
                    type="button"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none"
                  >
                    Geri Dön{" "}
                  </button>
                </Link>             
              </Panel>
              <Panel position="bottom-right">
                
                <button
                  onClick={handleSubmit}
                  type="button"
                  className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none"
                >
                  Kaydet
                </button>
              </Panel>
            </ReactFlow>
          </div>
          <WorkFlowSideBar />
        </ReactFlowProvider>
      </div>
    </>
  );
};

export default WorkFlow;
