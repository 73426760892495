import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import OrderDetailPdf from "./OrderDetailPdf";

const OrderPdfDetailModal = ({ data, onCancel = () => {} }) => {
  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30 z-50 flex justify-center items-center">
      <div className="w-3/5 p-8 bg-white rounded-lg flex flex-col justify-center items-center">
        <form className="grid grid-cols-2 gap-7 items-center">
          <div className="col-span-2 text-center">
            <h1 className="font-medium text-2xl text-gray-600">
              PDF yüklendiğinde indirebilirsiniz.
            </h1>
          </div>
          <div className="col-span-2 text-center">
            <PDFDownloadLink
              document={<OrderDetailPdf data={data} />}
              fileName={`${data.orderNo || "FORM"}.pdf`}
            >
              {({ loading }) =>
                loading ? (
                  <a className="text-lg font-medium text-red-600 hover:underline">
                    Yükleniyor...
                  </a>
                ) : (
                  <a className="text-lg font-medium text-green-600 hover:underline">
                    PDF İndir
                  </a>
                )
              }
            </PDFDownloadLink>
          </div>

          <div className="col-span-2 flex justify-center gap-[20px]">
            <button
              onClick={onCancel}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg m-5"
            >
              Kapat
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OrderPdfDetailModal;
