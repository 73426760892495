import React from "react";
import { useEffect } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import api from "../../../api/api";
import { useState } from "react";
import { useAuth } from "../../../context/AuthContext";

const DeleteAccount = ({ onCancel = () => {} }) => {
  const { user } = useAuth();
  const [getAccounts, setGetAccounts] = useState([]);
  const [accounts, setAccounts] = useState(null);
  const [customerID, setCustomerID] = useState(Number());

  useEffect(() => {
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/Account/GetAccounts?customerID=${user.country}&pageNumber=1&pageSize=99999`
        )
        .then((res) => {
          setGetAccounts(res.data.items);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, [user]);

  const accountsOptions = getAccounts?.map((item) => ({
    label: item.name,
    value: item.accountID,
  }));

  const handleClick = (e) => {
    e.preventDefault();
    api
      .delete(
        `/api/Account/DeleteAccount/${accounts.value}?customerID=${customerID}`
      )
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Müşteri silindi !",
          showConfirmButton: false,
          timer: 1500,
        });
        onCancel();
      })
      .catch((err) => {
        console.log(err.data);
        Swal.fire("Müşteri silinemedi !");
      });
  };
  return (
    <div className="fixed inset-0 w-screen h-screen bg-black bg-opacity-30 z-[1000] flex justify-center items-center">
      <div className="w-3/5 p-5 bg-white rounded-lg flex flex-col justify-center items-center">
        <div className="w-full">
          <label
            htmlFor="title"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Silinecek müşteriyi seçiniz...
          </label>
          <Select
            value={accounts}
            onChange={(e) => setAccounts(e)}
            options={accountsOptions}
            id="getStockCard"
          />
        </div>

        <div className="flex justify-center gap-[20px]">
          <button
            onClick={onCancel}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full m-5"
          >
            Vazgeç
          </button>
          <button
            onClick={handleClick}
            type="button"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5"
          >
            Onayla
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
