import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import Select from "react-select";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";

const MeetingCheck = () => {
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState(null);
  const [newUserData, setUserNewData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [meetingAgendaItems, setMeetingAgendaItems] = useState([]);
  const [customerID, setCustomerID] = useState(Number());
  const [userOptions, setUserOptions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [meetingTypes, setMeetingTypes] = useState([]);
  const [status, setStatus] = useState([]);
  const [users, setUsers] = useState(null);
  const [description, setDescription] = useState("");
  const [token, setToken] = useState(null);
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=15`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.reportPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(`/api/User/GetUsers?customerID=${user.country}`)
            .then((res) => {
              setUsers(res.data);
              const data = res.data;
              const newData = data.map((item) => ({
                value: item.userID,
                label: item.userName,
              }));
              setUserOptions(newData);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/Meeting/Get?id=${params.id}&customerID=${user.country}`)
            .then((res) => {
              setData(res.data);
              setDescription(res.data.description);
              setEndDate(res.data.endDate);

              const statusLabel =
                res.data.status === 1 ? "Tamamlandı" : "İptal Edildi";
              setStatus({ value: res.data.status, label: statusLabel });

              const transformedData = res.data.meetingAgendaItems.map(
                (item) => {
                  let agendaItemSelect = {};

                  if (item.userID === null && item.departmentID === null) {
                    return {
                      agendaItemSelect,
                      description: item.description,
                      customerID: item.customerID,
                      userID: {},
                      departmentID: {},
                      startDate:
                        item.startDate === null
                          ? null
                          : new Date(item.startDate),
                      endDate:
                        item.endDate === null ? null : new Date(item.endDate),
                      desicionID: item.agendaItemID,
                    };
                  } else if (item.userID === null) {
                    agendaItemSelect = { value: 1, label: "Departman" };
                  } else if (item.departmentID === null) {
                    agendaItemSelect = { value: 2, label: "Kullanıcı" };
                  }

                  return {
                    agendaItemSelect,
                    description: item.description,
                    customerID: item.customerID,
                    userID:
                      item.userID === null
                        ? {}
                        : { value: item.userID, label: "" },
                    departmentID:
                      item.departmentID === null
                        ? {}
                        : { value: item.departmentID, label: "" },
                    startDate:
                      item.startDate === null ? null : new Date(item.startDate),
                    endDate:
                      item.endDate === null ? null : new Date(item.endDate),
                    desicionID: item.agendaItemID,
                  };
                }
              );

              setMeetingAgendaItems(transformedData);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/Department/GetDepartments?customerID=${user.country}`)
            .then((res) => {
              const dataDep = res.data;
              const newDataDep = dataDep.map((item) => ({
                value: item.departmentID,
                label: item.name,
              }));
              setDepartments(newDataDep);
            })
            .catch((err) => console.error(err.data));

          api
            .get("/api/Common/GetParameters?parametername=MeetingType")
            .then((res) => setMeetingTypes(res.data))
            .catch((err) => console.error(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  useEffect(() => {
    if (users && data.meeting_Users && meetingTypes && departments.length > 0) {
      const matchedUsers = data.meeting_Users.map((item) => {
        const matchedUser = users.find((user) => user.userID === item.userID);
        if (matchedUser) {
          return {
            ...item,
            userName: matchedUser.userName,
            isChecked: false,
            description: item.description,
            isChecked: item.status === 1 ? false : true,
          };
        }
        return item;
      });
      setUserNewData(matchedUsers);

      const matchedType = meetingTypes.find(
        (item) => item.parameterID === data.type
      );
      const matchedUser = users.find((item) => item.userID === data.userID);
      setNewData({
        ...data,
        typeName: matchedType?.parameterValue2,
        userName: matchedUser?.userName,
      });

      const updatedAgendaItems = meetingAgendaItems.map((item) => {
        if (item.userID.value) {
          const matchedUser = users.find(
            (user) => user.userID === item.userID.value
          );
          if (matchedUser) {
            item.userID.label = matchedUser?.userName;
          }
        }
        if (item.departmentID.value) {
          const matchedDepartment = departments.find(
            (dep) => dep.value === item.departmentID.value
          );
          console.log("matchedDepartment", matchedDepartment);

          if (matchedDepartment) {
            item.departmentID.label = matchedDepartment?.label;
          }
        }
        return item;
      });
      setMeetingAgendaItems(updatedAgendaItems);
    }
  }, [users, data, meetingTypes, departments]);

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    let saat = tarihObjesi.getHours();
    saat = saat % 24;
    const saatString = saat.toString().padStart(2, "0");
    const dakika = tarihObjesi.getMinutes().toString().padStart(2, "0");

    return `${gun}/${ay}/${yil} - ${saatString}.${dakika} `;
  };

  const handleServiceChange = (e, index) => {
    const { value } = e.target;
    const list = [...meetingAgendaItems];
    list[index].description = value;
    setMeetingAgendaItems(list);
  };

  const handleAgendaItemChange = (e, index) => {
    const list = [...meetingAgendaItems];
    list[index].agendaItemSelect = e;
    setMeetingAgendaItems(list);
  };

  const handleServiceSelectChange = (e, index) => {
    const list = [...meetingAgendaItems];
    list[index].userID = e;
    setMeetingAgendaItems(list);
  };

  const handleServiceDepSelectChange = (e, index) => {
    const list = [...meetingAgendaItems];
    list[index].departmentID = e;
    setMeetingAgendaItems(list);
  };

  const handleServiceStartSelectChange = (e, index) => {
    const list = [...meetingAgendaItems];
    list[index].startDate = e;
    setMeetingAgendaItems(list);
  };

  const handleServiceEndSelectChange = (e, index) => {
    const list = [...meetingAgendaItems];
    list[index].endDate = e;
    setMeetingAgendaItems(list);
  };

  const handleServiceAdd = () => {
    setMeetingAgendaItems([
      ...meetingAgendaItems,
      {
        agendaItemSelect: {},
        description: "",
        customerID,
        userID: {},
        departmentID: {},
        startDate: null,
        endDate: null,
      },
    ]);
  };

  const handleServiceRemove = (index) => {
    const list = [...meetingAgendaItems];
    list.splice(index, 1);
    setMeetingAgendaItems(list);
  };

  const handleInputChange = (index, value) => {
    const updatedUserData = [...newUserData];
    updatedUserData[index].description = value;
    setUserNewData(updatedUserData);
  };

  const handleCheckboxChange = (index) => {
    const updatedUserData = [...newUserData];
    updatedUserData[index].isChecked = !updatedUserData[index].isChecked;
    setUserNewData(updatedUserData);
  };

  const postData = {
    meetingID: params.id,
    customerID,
    status: status.value,
    description,
    meetingUsers: newUserData?.map((item) => ({
      userID: item.userID,
      status: item.isChecked ? -1 : 1,
      description: item.description,
    })),
    meetingDesicions: meetingAgendaItems?.map((item) => ({
      userID: item.userID.value,
      description: item.description,
      departmentID: item.departmentID.value,
      startDate: item.startDate,
      endDate: item.endDate,
      desicionID: item.desicionID,
    })),
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    api
      .put("/api/Meeting/PutFinal/PutFinal", postData)
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Toplantı güncellendi !",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/meetings");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Toplantı güncellenemedi !");
      })
      .finally(() => setLoading(false));
  };

  console.log(postData);
  console.log("agenda", meetingAgendaItems);

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen w-[75%] bg-gray-100">
          <form
            className="w-full flex flex-col gap-[50px]"
            onSubmit={handleSubmit}
          >
            <div className="flex gap-[50px] pr-[70px] pt-[30px]">
              <div className="w-[60vw] bg-slate-900 max-w-3xl shadow overflow-hidden rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-lg leading-6 font-medium text-white">
                    {data?.title}
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-400">
                    {data?.name}
                  </p>
                </div>
                <div className="border-t border-gray-200">
                  <dl>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm font-medium text-gray-500"
                      >
                        Toplantı Sahibi
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {newData?.userName}
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Süre
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {data?.duration} dk
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Tarih
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {formatDate(data?.startDate)}
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Toplantı Tipi
                      </dt>
                      <dd className=" mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {newData?.typeName}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div className="w-[40rem] flex flex-col gap-[30px]">
                <div className="flex flex-col gap-3">
                  <label
                    className="text-lg font-semibold text-gray-600"
                    htmlFor="status"
                  >
                    Toplantı Durum :
                  </label>
                  <Select
                    id="status"
                    maxMenuHeight={200}
                    options={[
                      { value: 1, label: "Tamamlandı" },
                      { value: -1, label: "İptal Edildi" },
                    ]}
                    value={status}
                    onChange={(e) => setStatus(e)}
                    classNamePrefix="custom-select"
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <label
                    className="text-lg font-semibold text-gray-600"
                    htmlFor="endDate"
                  >
                    Bitiş Tarihi :
                  </label>
                  <DatePicker
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    locale={tr}
                    dateFormat={"dd/MM/yy"}
                    id="endDate"
                  />
                </div>
              </div>
            </div>
            <div className="overflow-x-auto mb-5 w-full overflow-y-auto h-[400px]">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-white uppercase bg-slate-900">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Toplantı Kararları
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Baş. Tarih
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Bit. Tarih
                    </th>
                    <th scope="col" className="px-6 py-3">
                      İşlem seç
                    </th>
                    <th scope="col" className="px-6 py-3">
                      (Kullanıcı/Departman)
                    </th>
                    <th scope="col" className="px-6 py-3"></th>
                  </tr>
                </thead>
                <tbody>
                  {meetingAgendaItems.map((singleService, index) => (
                    <tr key={index} className="bg-white border-b">
                      <td className="px-6 py-4">
                        <input
                          name="taskTodo"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          required
                          onChange={(e) => handleServiceChange(e, index)}
                          value={singleService.description}
                        />
                      </td>
                      <td className="px-6 py-4">
                        <DatePicker
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          showTimeSelect
                          locale={tr}
                          dateFormat="MMMM d, yyyy h:mm aa"
                          selected={singleService.startDate}
                          onChange={(e) =>
                            handleServiceStartSelectChange(e, index)
                          }
                        />
                      </td>
                      <td className="px-6 py-4">
                        <DatePicker
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          showTimeSelect
                          locale={tr}
                          dateFormat="MMMM d, yyyy h:mm aa"
                          selected={singleService.endDate}
                          onChange={(e) =>
                            handleServiceEndSelectChange(e, index)
                          }
                        />
                      </td>
                      <td className="px-6 py-4">
                        <Select
                          value={singleService.agendaItemSelect}
                          onChange={(e) => handleAgendaItemChange(e, index)}
                          options={[
                            { value: 1, label: "Departman" },
                            { value: 2, label: "Kullanıcı" },
                          ]}
                          maxMenuHeight={200}
                          classNamePrefix="custom-select"
                        />
                      </td>
                      {singleService.agendaItemSelect.value === 1 && (
                        <td className="px-6 py-4">
                          <Select
                            value={singleService.departmentID}
                            onChange={(e) =>
                              handleServiceDepSelectChange(e, index)
                            }
                            options={departments}
                            maxMenuHeight={200}
                            classNamePrefix="custom-select"
                          />
                        </td>
                      )}
                      {singleService.agendaItemSelect.value === 2 && (
                        <td className="px-6 py-4">
                          <Select
                            value={singleService.userID}
                            onChange={(e) =>
                              handleServiceSelectChange(e, index)
                            }
                            options={userOptions}
                            maxMenuHeight={200}
                            classNamePrefix="custom-select"
                          />
                        </td>
                      )}
                      {singleService.agendaItemSelect.value === undefined && (
                        <td className="px-6 py-4">
                          <Select
                            maxMenuHeight={200}
                            classNamePrefix="custom-select"
                          />
                        </td>
                      )}

                      <td className="px-6 py-4">
                        <button
                          onClick={() => handleServiceRemove(index)}
                          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg m-5"
                        >
                          <i className="fa-regular fa-trash-can"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <button
                  type="button"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg my-5 outline-none"
                  onClick={handleServiceAdd}
                >
                  Ekle
                  <i className="fa-solid fa-plus ml-2"></i>
                </button>
              </table>
            </div>

            <div className="flex gap-32">
              <div className="relative overflow-x-auto  mb-5 w-full overflow-y-auto max-h-[500px]">
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-white uppercase bg-slate-900">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Katılımcılar
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Durum
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Mazeret
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {newUserData?.map((item, index) => (
                      <tr key={index} className="bg-white border-b">
                        <td className="px-6 py-4">
                          <input
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            disabled
                            value={item.userName}
                          />
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex justify-start">
                            <label className="text-sm font-medium text-gray-500">
                              Katılmadı
                            </label>
                            <input
                              type="checkbox"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-16 p-2.5"
                              checked={item.isChecked}
                              onChange={() => handleCheckboxChange(index)}
                            />
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <input
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            value={item.description}
                            onChange={(e) =>
                              handleInputChange(index, e.target.value)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex justify-between items-end pb-6">
              <div className="w-full">
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Açıklama :
                </label>
                <textarea
                  id="description"
                  type="text"
                  className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
            <div className="col-span-4 flex justify-end gap-[20px]">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg my-5"
                >
                  Kaydet
                  <i className="fa-regular fa-floppy-disk ml-2"></i>
                </button>
              </div>
          </form>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default MeetingCheck;
