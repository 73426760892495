import Sidebars from "../../components/bars/Sidebar";
import Header from "../../components/bars/Header";
import React, { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import Switch from "react-switch";
import api from "../../api/api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";

const StockCardsAdd = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [getBrands, setGetBrands] = useState(null);
  const [getCategories, setGetCategories] = useState(null);
  const [getVat, setGetVat] = useState(null);
  const [getUnitTypes, setGetUnitTypes] = useState(null);
  const [getStockTypes, setGetStockTypes] = useState(null);
  const [getStockStatus, setGetStockStatus] = useState(null);
  const [name, setName] = useState("");
  const [stockQuality, setStockQuality] = useState("");
  const [description, setDescription] = useState("");
  const [code, setCode] = useState("");
  const [barcode, setBarcode] = useState("");
  const [purchasePrice, setPurchasePrice] = useState(Number());
  const [salesPrice, setSalesPrice] = useState(Number());
  const [alertLevel, setAlertLevel] = useState(Number());
  const [size1, setSize1] = useState(Number());
  const [size2, setSize2] = useState(Number());
  const [size3, setSize3] = useState(Number());
  const [isAlert, setIsAlert] = useState(false);
  const [customerID, setCustomerID] = useState(Number());
  const [categoryList, setCategoryList] = useState([]);
  const [token, setToken] = useState(null);
  const [keywords, setKeywords] = useState([]);
  const [customerKey, setCustomerKey] = useState(null);
  const [wareHouses, setWareHouses] = useState([]);
  const [selectedWareHouse, setSelectedWareHouse] = useState({});
  const [currency, setCurrency] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [stockStatus, setStockStatus] = useState({
    value: "",
    label: "",
  });
  const [stockTypes, setStockTypes] = useState({
    value: "",
    label: "",
  });
  const [unitTypes, setUnitTypes] = useState({
    value: "",
    label: "",
  });

  const [brand, setBrand] = useState({
    value: "",
    label: "",
  });
  const [vat, setVat] = useState({
    value: "",
    label: "",
  });

  const handleChange = (nextChecked) => {
    setIsAlert(nextChecked);
  };

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=6`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.insertPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/StockCard/GetStockBrands/GetBrands?customerID=${user.country}`
            )
            .then((res) => {
              setGetBrands(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newData = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setCustomerKey(newData);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/StockCard/GetStockCategories/GetCategories?customerID=${user.country}`
            )
            .then((res) => {
              setGetCategories(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get("/api/Common/GetVat")
            .then((res) => {
              setGetVat(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get("/api/Common/GetParameters?parametername=UnitType")
            .then((res) => {
              setGetUnitTypes(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get("/api/Common/GetParameters?parametername=StockType")
            .then((res) => {
              setGetStockTypes(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get(
              `/api/WareHouse/GetWareHouses?customerID=${user.country}&pageNumber=1&pageSize=9999`
            )
            .then((res) => {
              const newDataWareHouses = res.data?.items.map((item) => ({
                value: item.wareHouseID,
                label: item.name,
              }));
              setWareHouses(newDataWareHouses);
            })
            .catch((err) => console.log(err));

          api
            .get("/api/Common/GetCurrencies")
            .then((res) => {
              const currencyData = res.data?.map((item) => ({
                value: item.currencyID,
                label: item.name,
              }));
              setCurrency(currencyData);
            })
            .catch((err) => console.log(err.data));

          api
            .get("/api/Common/GetParameters?parametername=StockStatus")
            .then((res) => {
              setGetStockStatus(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            })
            .finally(() => setLoading(false));
        })
        .finally(() => setLoading(false));
    }
  }, [user]);

  const brandsName = getBrands?.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const categoriesName = getCategories?.map((item) => ({
    value: item.categoryID,
    label: item.name,
  }));
  const vatName = getVat?.map((item) => ({
    value: item,
    label: item,
  }));

  const unitTypeName = getUnitTypes?.map((item) => ({
    value: item.parameterID,
    label: item.parameterValue2,
  }));
  const stockTypeName = getStockTypes?.map((item) => ({
    value: item.parameterID,
    label: item.parameterValue2,
  }));
  const stockStatusName = getStockStatus?.map((item) => ({
    value: item.parameterID,
    label: item.parameterValue2,
  }));

  const find = getBrands?.find((item) => item.name === brand.value);
  const categoryId = categoryList?.map((item) => item.value);
  const stringCatId = JSON.stringify(categoryId);

  // const newCategoryList = categoryId?.map((item)=>item.value)

  const postData = {
    customerID: Number(customerID),
    brandID: find?.brandID,
    wareHouseID: selectedWareHouse.value,
    categoryList: stringCatId,
    currencyID: selectedCurrency.value,
    code,
    name,
    barcode,
    status: Number(stockStatus.value),
    alert: isAlert,
    alertLevel: Number(alertLevel),
    viewIndex: 0,
    description,
    salesPrice: Number(salesPrice),
    purchasePrice: Number(purchasePrice),
    vat: Number(vat.value),
    type: Number(stockTypes.value),
    unitType: unitTypes.value,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    stockQuality,
    size1,
    size2,
    size3,
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    api
      .post("/api/StockCard/Post/Post", postData)

      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Ürün oluşturuldu !",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/stockCards");
      })

      .catch((err) => {
        console.log(err);
        Swal.fire("Ürün Oluşturalamadı !");
      })
      .finally(() => setLoading(false));
  };

  console.log(postData);
  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="p-12 mx-auto my-14 rounded-[3px] justify-between w-[75%] bg-gray-100">
          <div className="mb-5 flex items-center gap-2">
          <FaStarOfLife size={15}/>
              <h1 className="text-3xl font-semibold title  ">Ürün Oluştur</h1>
            </div>
            <form
              onSubmit={handleSubmit}
              className="grid grid-cols-2 gap-7 items-center w-full"
            >
              <div>
                <label
                  htmlFor="title"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Adı :
                </label>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  id="title"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Açıklama :
                </label>
                <input
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  type="text"
                  id="description"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
              <div>
                <label
                  htmlFor="code"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Kodu :
                </label>
                <input
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  type="text"
                  id="code"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <div className="flex items-end justify-between">
                <div>
                  <label
                    htmlFor="ProductCode"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Ürün Barkodu :
                  </label>
                  <input
                    value={barcode}
                    onChange={(e) => setBarcode(e.target.value)}
                    type="number"
                    id="ProductCode"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
                <div className="w-[200px]">
                  <label
                    htmlFor="stockType"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Tip :
                  </label>
                  <Select
                    id="stockType"
                    options={stockTypeName}
                    value={stockTypes}
                    onChange={(e) => setStockTypes(e)}
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                  />
                </div>
                <div className="w-[105px]">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="unitTypes"
                  >
                    Birim :
                  </label>
                  <Select
                    id="unitTypes"
                    options={unitTypeName}
                    value={unitTypes}
                    onChange={(e) => setUnitTypes(e)}
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="salesPrice"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Satış Fiyatı :
                </label>
                <input
                  value={salesPrice}
                  onChange={(e) => setSalesPrice(e.target.value)}
                  type="number"
                  id="salesPrice"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>

              <div className="flex items-end justify-between">
                <div>
                  <label
                    htmlFor="purchasePrice"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Alış Fiyatı :
                  </label>
                  <input
                    value={purchasePrice}
                    onChange={(e) => setPurchasePrice(e.target.value)}
                    type="number"
                    id="purchasePrice"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
                <div className="w-[200px]">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="stockStatus"
                  >
                    Durum :
                  </label>
                  <Select
                    id="stockStatus"
                    options={stockStatusName}
                    value={stockStatus}
                    onChange={(e) => setStockStatus(e)}
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                  />
                </div>

                <div className="w-[105px]">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="vatRate"
                  >
                    KDV Oranı :
                  </label>
                  <Select
                    id="vatRate"
                    options={vatName}
                    value={vat}
                    onChange={(e) => setVat(e)}
                    maxMenuHeight={200}
                    classNamePrefix="custom-select"
                  />
                </div>
              </div>
              <div>
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="brand"
                >
                  Marka :
                </label>
                <Select
                  value={brand}
                  onChange={(e) => setBrand(e)}
                  options={brandsName}
                  id="brand"
                  maxMenuHeight={200}
                  classNamePrefix="custom-select"
                />
              </div>

              <div className="flex items-center gap-[30px] justify-between">
                <div className="w-[200px]">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="brand"
                  >
                    Para Birimi :
                  </label>
                  <Select
                    maxMenuHeight={200}
                    options={currency}
                    value={selectedCurrency}
                    onChange={(e) => setSelectedCurrency(e)}
                    classNamePrefix="custom-select"
                  />
                </div>
                <div>
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor=""
                  >
                    Stok Adet :
                  </label>
                  <input
                    value={alertLevel}
                    onChange={(e) => setAlertLevel(e.target.value)}
                    type="number"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5"
                    required
                  />
                </div>
                <div className="flex flex-col">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor=""
                  >
                    Bildirim :
                  </label>
                  <Switch checked={isAlert} onChange={handleChange} />
                </div>
              </div>
              <div>
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="categories"
                >
                  Kategoriler :
                </label>
                <Select
                  id="categories"
                  options={categoriesName}
                  isMulti
                  name="colors"
                  classNamePrefix="custom-select"
                  value={categoryList}
                  onChange={(e) => setCategoryList(e)}
                  maxMenuHeight={200}
                />
              </div>
              <div className="flex items-center justify-between">
                <div>
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="size1"
                  >
                    Ölçü 1 :
                  </label>
                  <input
                    type="number"
                    className="bg-red-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5"
                    required
                    id="size1"
                    value={size1}
                    onChange={(e) => setSize1(e.target.value)}
                  />
                  <p className="text-xs text-red-400 mt-2 ml-1 font-medium absolute">
                    Bu ölçü birimi üretim planlamada arama kriteri olarak
                    kullanılacaktır.
                  </p>
                </div>

                <div>
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="size2"
                  >
                    Ölçü 2 :
                  </label>
                  <input
                    type="number"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5"
                    required
                    id="size2"
                    value={size2}
                    onChange={(e) => setSize2(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="size3"
                  >
                    Ölçü 3 :
                  </label>
                  <input
                    type="number"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5"
                    required
                    id="size3"
                    value={size3}
                    onChange={(e) => setSize3(e.target.value)}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="title"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Ürün Kalitesi :
                </label>
                <input
                  value={stockQuality}
                  onChange={(e) => setStockQuality(e.target.value)}
                  type="text"
                  id="title"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Varsayılan Depo Seçimi :
                </label>
                <Select
                  maxMenuHeight={200}
                  options={wareHouses}
                  value={selectedWareHouse}
                  onChange={(e) => setSelectedWareHouse(e)}
                  classNamePrefix="custom-select"
                />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="visitors"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Anahtar Kelimeler
                </label>
                <CreatableSelect
                  onChange={(e) => setKeywords(e)}
                  options={customerKey}
                  isMulti
                  maxMenuHeight={200}
                  classNamePrefix="custom-select"
                />
              </div>

              <div className="col-span-2 flex justify-end gap-[20px]">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg my-5"
                >
                  Kaydet
                  <i className="fa-regular fa-floppy-disk ml-2"></i>
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default StockCardsAdd;
