import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Select from "react-select";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { useAuth } from "../../context/AuthContext";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { FaRegFilePdf } from "react-icons/fa6";
import ShippingSlipPDF from "./ShippingSlipPDF";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";

const ShippingSlipEdit = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(Number());
  const [data, setData] = useState([]);
  const [stockCards, setStockCards] = useState([]);
  const [wareHouses, setWareHouses] = useState([]);
  const [token, setToken] = useState(null);
  //FORM
  const [shippingSlipItems, setShippingSlipItems] = useState([
    {
      stockCardID: Number(),
      unitID: Number(),
      quantity: Number(),
      type: 0,
      status: 0,
      viewIndex: 0,
      description: "",
    },
  ]);
  const [description, setDescription] = useState("");
  const [notes, setNotes] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [keywordsData, setKeywordsData] = useState([]);
  const [unitTypesOpt, setUnitTypesOpt] = useState([]);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [exitWareHouse, setExitWareHouse] = useState({});
  const [entranceWareHouse, setEntranceWareHouse] = useState({});

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=38`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.updatePermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/WareHouse/GetShippingSlip?id=${params.id}&customerID=${user.country}`
            )
            .then((res) => {
              setData(res.data);
              setTitle(res.data.title);
              setName(res.data.name);
              setEntranceWareHouse({
                value: res.data.entranceWareHouseID,
                label: res.data.entranceWareHouseName,
              });
              setExitWareHouse({
                value: res.data.exitWareHouseID,
                label: res.data.exitWareHouseName,
              });
              const data = JSON.parse(res.data.keywords);
              const newData = data.map((item) => ({
                value: item,
                label: item,
                __isNew__: true,
              }));
              setKeywords(newData);
              setDescription(res.data.description);
              setNotes(res.data.notes);

              setShippingSlipItems(res.data.wareHouse_ShippingSlipItems);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/StockCard/GetStockCards?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              const dataStockCards = res.data.items;
              const newDataStockCards = dataStockCards?.map((item) => ({
                value: item.stockCardID,
                label: `${item.code} - ${item.name}`,
              }));
              setStockCards(newDataStockCards);
            })
            .catch((err) => console.log(err.data));

          api
            .get("/api/Common/GetParameters?parametername=UnitType")
            .then((res) => {
              const unitTypes = res.data;
              const newDataUnitTypes = unitTypes?.map((item) => ({
                value: item.parameterID,
                label: item.parameterValue2,
              }));
              setUnitTypesOpt(newDataUnitTypes);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newDataKey = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setKeywordsData(newDataKey);
            })
            .catch((err) => console.log(err));
          api
            .get(
              `/api/WareHouse/GetWareHouses?customerID=${user.country}&pageNumber=1&pageSize=9999`
            )
            .then((res) => {
              console.log(res.data);
              const newDataWareHouses = res.data?.items.map((item) => ({
                value: item.wareHouseID,
                label: item.name,
              }));
              setWareHouses(newDataWareHouses);
            })
            .catch((err) => console.log(err));

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newDataKey = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setKeywordsData(newDataKey);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const handleServiceRemove = (index) => {
    const list = [...shippingSlipItems];
    list.splice(index, 1);
    setShippingSlipItems(list);
  };

  const handleServiceAdd = () => {
    setShippingSlipItems([
      ...shippingSlipItems,
      {
        stockCardID: Number(),
        unitID: Number(),
        quantity: Number(),
        type: 0,
        status: 0,
        viewIndex: 0,
        description: "",
      },
    ]);
  };

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...shippingSlipItems];
    list[index][name] = value;
    setShippingSlipItems(list);
  };
  const handleSelectChange = (e, index) => {
    const list = [...shippingSlipItems];
    list[index].stockCardID = e;
    setShippingSlipItems(list);
  };
  const handleUnitTypeSelectChange = (e, index) => {
    const list = [...shippingSlipItems];
    list[index].unitID = e;
    setShippingSlipItems(list);
  };

  const postData = {
    shippingSlipID: params.id,
    customerID,
    exitWareHouseID: exitWareHouse.value,
    entranceWareHouseID: entranceWareHouse.value,
    documentID: 0,
    name,
    shippingNo: data?.shippingNo,
    title,
    type: 0,
    status: 0,
    viewIndex: 0,
    description,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    notes,
    wareHouse_ShippingSlipItems: shippingSlipItems?.map((item) => ({
      shippingSlipItemID: item.shippingSlipItemID,
      stockCardID: item.stockCardID.value || item.stockCardID,
      quantity: item.quantity,
      type: item.type,
      status: item.status,
      viewIndex: item.viewIndex,
      description: item.description,
      unitID: item.unitID.value || item.unitID,
    })),
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Axios isteği yapılandırma
      await api.put(
        "/api/WareHouse/PutWareHouse_ShippingSlip/PutWareHouse_ShippingSlip",
        postData,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Depo transfer fişi güncellendi",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/shippingSlips");
    } catch (error) {
      console.error("Hata alındı: ", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${error.response.data}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data && unitTypesOpt.length) {
      const slipItems = data?.wareHouse_ShippingSlipItems;
      const newDataSlipItems = slipItems?.map((item) => {
        const matchedUnitType = unitTypesOpt?.find(
          (unit) => unit.value === item.unitID
        );
        return {
          shippingSlipItemID: item.shippingSlipItemID,
          stockCardID: { value: item.shippingSlipItemID, label: item.name },
          quantity: item.quantity,
          type: item.type,
          status: item.status,
          viewIndex: item.viewIndex,
          description: item.description,
          unitID: matchedUnitType || { value: item.unitType, label: "" },
        };
      });
      setShippingSlipItems(newDataSlipItems);
    }
  }, [data, unitTypesOpt]);

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen w-[75%] bg-gray-100">
                  <div className="mb-5 flex items-center gap-2">
                  <FaStarOfLife size={15}/>
              <h1 className="text-3xl font-semibold title">
                Depolar Arası Transfer Güncelle
              </h1>
            </div>
            <form
              onSubmit={handleSubmit}
              className="grid grid-cols-4 gap-7 items-center w-full"
            >
              <div className="col-span-2">
                <label
                  htmlFor="title"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Başlık :
                </label>
                <input
                  type="text"
                  id="title"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Adı :
                </label>
                <input
                  type="text"
                  id="name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Giriş Depo :
                </label>
                <Select
                  maxMenuHeight={200}
                  options={wareHouses}
                  value={entranceWareHouse}
                  onChange={(e) => setEntranceWareHouse(e)}
                   classNamePrefix="custom-select"
                />
              </div>
              <div className="col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Çıkış Depo :
                </label>
                <Select
                  maxMenuHeight={200}
                  options={wareHouses}
                  value={exitWareHouse}
                  onChange={(e) => setExitWareHouse(e)}
                   classNamePrefix="custom-select"
                />
              </div>

              <div className="col-span-4">
                <label
                  htmlFor="keywords"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Anahtar Kelimeler :
                </label>
                <CreatableSelect
                  isMulti
                  maxMenuHeight={200}
                  id="keywords"
                  onChange={(e) => setKeywords(e)}
                  options={keywordsData}
                  value={keywords}
                   classNamePrefix="custom-select"
                />
              </div>
              <div className="col-span-3">
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Açıklama :
                </label>
                <textarea
                  id="description"
                  type="text"
                  className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="notes"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Not :
                </label>
                <textarea
                  id="notes"
                  type="text"
                  className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  rows={4}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </div>

              <div>
                <PDFDownloadLink
                  document={
                    <ShippingSlipPDF
                      data={data}
                      shippingSlipItems={shippingSlipItems}
                    />
                  }
                  filename="FORM"
                >
                  {({ loading }) =>
                    loading ? (
                      <a className="font-medium text-red-600 hover:underline">
                        <FaRegFilePdf size={30} />
                      </a>
                    ) : (
                      <a className="font-medium text-red-600 hover:underline flex gap-4 items-center">
                        <FaRegFilePdf size={30} /> Depo fişini çıktı al.
                      </a>
                    )
                  }
                </PDFDownloadLink>
              </div>
              <div className="overflow-x-auto mb-5 w-full overflow-y-auto col-span-4 mt-12">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-white uppercase bg-slate-900">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Ürün
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Birim
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Adet
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Açıklama
                      </th>
                      <th scope="col" className="px-6 py-3">
                        İşlem
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {shippingSlipItems?.map((singleService, index) => (
                      <tr key={index} className="bg-white border-b">
                        <td className="px-6 py-4">
                          <div className="w-[200px]">
                            <Select
                              onChange={(e) => handleSelectChange(e, index)}
                              value={singleService.stockCardID}
                              options={stockCards}
                              maxMenuHeight={200}
                               classNamePrefix="custom-select"
                            />
                          </div>
                        </td>

                        <td className="px-6 py-4">
                          <div className="w-[200px]">
                            <Select
                              onChange={(e) =>
                                handleUnitTypeSelectChange(e, index)
                              }
                              value={singleService.unitID}
                              options={unitTypesOpt}
                              maxMenuHeight={200}
                               classNamePrefix="custom-select"
                            />
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <input
                            value={singleService.quantity}
                            onChange={(e) => handleServiceChange(e, index)}
                            name="quantity"
                            type="number"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required
                          />
                        </td>
                        <td className="px-6 py-4">
                          <input
                            value={singleService.description}
                            onChange={(e) => handleServiceChange(e, index)}
                            name="description"
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required
                          />
                        </td>

                        <td className="px-6 py-4">
                          <button
                            onClick={() => handleServiceRemove(index)}
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg "
                          >
                            <i className="fa-regular fa-trash-can"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <button
                    onClick={handleServiceAdd}
                    type="button"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg my-5"
                  >
                    Ekle
                    <i className="fa-solid fa-plus ml-2"></i>
                  </button>
                </table>
              </div>

              <div className="col-span-4 flex justify-end gap-[20px]">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg my-5"
                >
                  Kaydet
                  <i className="fa-regular fa-floppy-disk ml-2"></i>
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ShippingSlipEdit;
