import React, { useEffect, useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoicesPDF from "./InvoicesPDF";
import { useAuth } from "../../context/AuthContext";
import api from "../../api/api";
import LoadingSpinnerModal from "../LoadingSpinnerModal";

const InvoicePdfModal = ({ data, pdfData, onCancel = () => {} }) => {
  const { user } = useAuth();
  const [data1, setData1] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user && user.country) {
      api
        .get(
          `/api/Invoice/GetInvoices?customerID=${user.country}&pageNumber=1&pageSize=99999`
        )
        .then((res) => {
          setData1(res.data.items);
        })
        .catch((err) => console.log(err.data))
        .finally(() => setLoading(false));
    }
  }, [user]);
  return (
    <>
      {loading ? (
        <LoadingSpinnerModal loading={loading} />
      ) : (
        <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30 z-[1000] flex justify-center items-center">
          <div className="w-3/5 p-8 bg-white rounded-lg flex flex-col justify-center items-center">
            <form className="grid grid-cols-2 gap-7 items-center">
              <div className="col-span-2 text-center">
                <h1 className="font-medium text-2xl text-gray-600">
                  PDF yüklendiğinde indirebilirsiniz.
                </h1>
              </div>
              <div className="col-span-2 text-center">
                <PDFDownloadLink
                  document={<InvoicesPDF pdfData={pdfData} data={data1} />}
                  fileName={`${pdfData || "FORM"}.pdf`}
                >
                  {({ loading }) =>
                    loading ? (
                      <a className="text-lg font-medium text-red-600 hover:underline">
                        Yükleniyor...
                      </a>
                    ) : (
                      <a className="text-lg font-medium text-green-600 hover:underline">
                        PDF İndir
                      </a>
                    )
                  }
                </PDFDownloadLink>
              </div>

              <div className="col-span-2 flex justify-center gap-[20px]">
                <button
                  onClick={onCancel}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg m-5"
                >
                  Kapat
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default InvoicePdfModal;
