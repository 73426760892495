import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Select from "react-select";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import Timeline from "react-calendar-timeline";
import moment from "moment";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";

const options = [
  { value: -1, label: "Mevcut iş emrinin üzerine yaz" },
  { value: -2, label: "Mevcut iş emrini sonraya ötele" },
  { value: -3, label: "İş Emrini Mevcut iş emrinin sonrasına ekle" },
];

const productionOptions = [
  { value: 1, label: " Üretim/Ürün" },
  { value: 2, label: " Üretim/Operasyon" },
];

const PreProductionAdd = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(Number());
  const [token, setToken] = useState(null);
  const [orders, setOrders] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [title, setTitle] = useState("");
  const [ordersOption, setOrdersOption] = useState([]);
  const [operations, setOperations] = useState([]);
  const [machines, setMachines] = useState([]);
  const [wareHouses, setWareHouses] = useState([]);
  const [stockCards, setStockCards] = useState([]);
  const [stockCardOpt, setStockCardOpt] = useState([]);
  const [machinesByServices, setMachinesByServices] = useState([]);
  const [machinesByServiceOpt, setMachinesByServiceOpt] = useState([]);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [notes, setNotes] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [keywordsData, setKeywordsData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [productionStockCard, setProductionStockCard] = useState([]);
  const [allSuccess, setAllSuccess] = useState(false);

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=27`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.insertPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/StockCard/GetProductionStockCards?customerID=${user.country}`
            )
            .then((res) => {
              setStockCards(res.data);
              const stockCard = res.data?.map((item) => ({
                value: item.stockCardID,
                label: item.name,
              }));
              setStockCardOpt(stockCard);
            })
            .catch((err) => console.log(err));
          api
            .get(
              `/api/Machine/GetMachinesByService?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              setMachinesByServices(res.data.items);
              const machineServices = res.data?.items.map((item) => ({
                value: item.machineID,
                label: item.name,
              }));
              setMachinesByServiceOpt(machineServices);
            })
            .catch((err) => console.log(err));

          api
            .get(
              `/api/Account/GetAccounts?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              const account = res.data?.items.map((item) => ({
                value: item.accountID,
                label: item.name,
              }));
              setAccounts(account);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/Machine/GetMachines?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              setMachines(res.data.items);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/Operation/Getoperations?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              setOperations(res.data.items);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/WareHouse/GetWareHouses?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              const newDataWareHouse = res.data.items?.map((item) => ({
                value: item.wareHouseID,
                label: item.name,
              }));
              setWareHouses(newDataWareHouse);
            })
            .catch((err) => console.log(err));

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newDataKey = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setKeywordsData(newDataKey);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const handleServiceAdd = () => {
    setProductionStockCard([...productionStockCard, {}]);
  };
  const handleServiceRemove = (index) => {
    const list = [...productionStockCard];
    list.splice(index, 1);
    setProductionStockCard(list);
  };

  const handleServiceQuantitySelectChange = (e, index) => {
    const list = [...productionStockCard];
    list[index].quantity = e;
    setProductionStockCard(list);
  };
  const handleServiceStockID = (e, index) => {
    const list = [...productionStockCard];
    list[index].stockCardID = e.value;
    list[index].moId = e.moId;
    list[index].stockCardName = e.label;
    list[index].wareHouseName = e.wareHouseName;
    list[index].wareHouseID = e.wareHouseID;
    setProductionStockCard(list);
  };

  const handleServiceProductionType = (e, index) => {
    const list = [...productionStockCard];

    // İlgili indeksteki satırı sil
    list.splice(index, 1);

    // Yeni bir obje oluştur ve belirtilen indekse ekle
    const newEntry = {
      ...list[index], // önceki objenin diğer alanlarını korumak isterseniz
      productionType: e, // productionType alanını yeni değerle güncelle
    };

    // İlgili indekse yeni satırı ekle
    list.splice(index, 0, newEntry);

    // Güncellenmiş listeyi state'e ayarla
    setProductionStockCard(list);
  };

  const handleServiceStatusSelectChange = async (e, index, singleService) => {
    setLoading(true);
    const list = [...productionStockCard];
    list[index].status = e;
    setProductionStockCard(list);

    // Tarih ve saat bilgilerini doğru şekilde ayarlayalım
    const formattedStartDate = new Date(
      singleService.response.availableStartDate
    ).toISOString(); // ISO formatında tarih ve saat

    // isMachineProduct'a göre machineOperation değerini belirleyelim
    const machineOperation = singleService.isMachineProduct ? 1 : 2;

    await api
      .get(
        `/api/WorkOrder/ReCalculateProductionTime?stockCardID=${
          singleService.stockCardID
        }&customerID=${customerID}&machineOperationID=${
          singleService.selectedOperation.value ||
          singleService.selectedMachine.value
        }&machineOperation=${machineOperation}&quantity=${
          singleService.quantity
        }&startDate=${formattedStartDate}`
      )
      .then((res) => {
        const list2 = [...productionStockCard];
        list2[index].startDate = new Date(res.data.startDate);
        list2[index].endDate = new Date(res.data.endDate);
        list[index].response = res.data;

        setProductionStockCard(list2);
      })
      .catch((error) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `${error}`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleMachineChange = async (selectedOption, index) => {
    setLoading(true);
    const list = [...productionStockCard];
    list[index].selectedMachine = selectedOption;
    setProductionStockCard(list);

    // Bu ayın 1'i için tarih formatlama
    const currentDate = new Date();
    const startDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const formattedStartDate = startDate.toISOString();

    // Bir ay sonrasının 1'i için tarih formatlama
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    const formattedEndDate = nextMonth.toISOString();

    await api
      .get(
        `/api/WorkOrder/GetShiftPlaningByMachineID?customerID=${customerID}&machineID=${selectedOption.value}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
      )
      .then((res) => {
        const list2 = [...productionStockCard];
        list2[index].shiftPlanning = res.data;
        setProductionStockCard(list2);
      })
      .catch((error) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `${error}`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOperationChange = async (selectedOption, index) => {
    setLoading(true);
    const list = [...productionStockCard];
    list[index].selectedOperation = selectedOption;
    setProductionStockCard(list);

    // Bu ayın 1'i için tarih formatlama
    const currentDate = new Date();
    const startDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const formattedStartDate = startDate.toISOString();

    // Bir ay sonrasının 1'i için tarih formatlama
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    const formattedEndDate = nextMonth.toISOString();

    await api
      .get(
        `/api/WorkOrder/GetShiftPlaningByOperationID?customerID=${customerID}&operationID=${selectedOption.value}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
      )
      .then((res) => {
        const list3 = [...productionStockCard];
        list3[index].shiftPlanning = res.data;
        setProductionStockCard(list3);
      })
      .catch((error) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `${error}`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleWareHouse = async (selectedOption, index) => {
    const list = [...productionStockCard];
    list[index].wareHouseID = selectedOption.value;
    list[index].wareHouseName = selectedOption.label;
    setProductionStockCard(list);
  };

  const handleStartDate = async (selectedOption, index, singleService) => {
    const formattedStartDate = new Date(selectedOption).toISOString(); // ISO formatında tarih ve saat
    const list = [...productionStockCard];
    list[index].startDate = selectedOption;

    try {
      setLoading(true);
      let response;
      if (singleService.isMachineProduct || singleService.isMachineProduct2) {
        response = await api.get(
          `/api/WorkOrder/CalculateProductionTimeMachine?stockCardID=${singleService.stockCardID}&machineID=${singleService.selectedMachine.value}&customerID=${customerID}&quantity=${singleService.quantity}&startDate=${formattedStartDate}`
        );
      } else if (singleService.isOperationProduct) {
        response = await api.get(
          `/api/WorkOrder/CalculateProductionTimeOperation?stockCardID=${singleService.stockCardID}&operationID=${singleService.selectedOperation.value}&customerID=${customerID}&quantity=${singleService.quantity}&startDate=${formattedStartDate}`
        );
      }

      // API'den dönen veriye göre uygun alanı güncelleyin
      if (response) {
        list[index].response = response.data;
      }
    } catch (error) {
      console.error("Error fetching production time:", error);
    } finally {
      setLoading(false);
    }

    setProductionStockCard(list);
  };

  const handleEndDate = async (selectedOption, index) => {
    const list = [...productionStockCard];
    list[index].endDate = selectedOption;
    setProductionStockCard(list);
  };

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    // Saate 3 ekleyerek hesaplama yapalım
    let saat = tarihObjesi.getHours();

    // Eğer saat 24'ten büyükse 24'e göre modunu alalım
    saat = saat % 24;

    // Saati string formatına çevirirken padding işlemi yapalım
    const saatString = saat.toString().padStart(2, "0");
    const dakika = tarihObjesi.getMinutes().toString().padStart(2, "0");

    return `${gun}/${ay}/${yil} - ${saatString}.${dakika} `;
  };

  const handleItemClick = (itemId) => {
    let selectedItem = null;
    // productionStockCard dizisindeki her bir öğeyi kontrol et
    for (const item of productionStockCard) {
      // Her bir öğenin shiftPlanning özelliğinin içindeki shiftID'leri kontrol et
      for (const shiftId in item.shiftPlanning) {
        // Eğer shiftID, itemId'ye eşitse, bu öğeyi seçili olarak işaretle ve döngüyü durdur
        if (item.shiftPlanning[shiftId].shiftID === itemId) {
          selectedItem = item.shiftPlanning[shiftId];
          break;
        }
      }
      // Eğer selectedItem bulunduysa, döngüyü durdur
      if (selectedItem) {
        Swal.fire({
          title: `${selectedItem.workOrderNames}`,
          html: `Baş.Tarihi:<span class="font-bold text-green-600"> ${formatDate(
            selectedItem.startDate
          )}</span><br>Bit.Tarih: <span class="font-bold text-green-600">${formatDate(
            selectedItem.endDate
          )}</span>`,
        });
      }
    }
  };

  const postData = {
    customerID,
    orderID: 0,
    accountID: selectedAccount.value,
    name,
    title,
    startDate,
    endDate,
    actualStartDate: "",
    actualEndDate: "",
    actualProtuctionMinute: 0,
    plannedProductionQuantity: 0,
    actualProductionQuantity: 0,
    type: 1681,
    status: 0,
    viewIndex: 0,
    description,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    notes,
    workOrderItems: productionStockCard?.map((item) => ({
      stockCardID: item.stockCardID,
      machineID: item.selectedMachine ? item.selectedMachine.value : 0,
      operationID: item.selectedOperation ? item.selectedOperation.value : 0,
      wareHouseID: item.wareHouseID,
      name: item.name,
      startDate: item.startDate,
      endDate: new Date(item.endDate),
      plannedProductionQuantity: Number(item.quantity),
      status: item.status?.value,
    })),
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Axios isteği yapılandırma
      await api.post("/api/WorkOrder/Post/Post", postData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "İş Emri oluşturuldu !",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/workOrders");
    } catch (error) {
      console.error("Hata alındı: ", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${error}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleFindStockCardId = (e, index) => {
    // productionStockCard listesinin bir kopyasını oluştur
    const list = [...productionStockCard];

    // e.value ile eşleşen stockCardID'yi bul
    const foundCard = stockCards.find((card) => card.stockCardID === e.value);

    if (foundCard) {
      // Eğer eşleşen bir kart bulunduysa, sadece ilgili alanları güncelle
      list[index] = {
        ...list[index], // mevcut objeyi koru
        ...foundCard, // yeni değerlerle güncelle
      };
    } else {
      // Eşleşen kart bulunamazsa, hata işlemi veya varsayılan değer atama yapılabilir
      console.error("Stock card not found");
    }

    // Güncellenmiş listeyi state'e ayarla
    setProductionStockCard(list);
  };

  const handleFindMachineId = async (e, index) => {
    setLoading(true);

    // productionStockCard listesinin bir kopyasını oluştur
    const list = [...productionStockCard];

    // e.value ile eşleşen stockCardID'yi bul
    const foundCard = machinesByServices.find(
      (service) => service.machineID === e.value
    );

    if (foundCard) {
      // Eğer eşleşen bir kart bulunduysa, selectedOperation'ı güncelle
      list[index] = {
        ...list[index], // mevcut objeyi koru
        ...foundCard, // yeni değerlerle güncelle
        selectedMachine: e, // yeni değerlerle güncelle
        isMachineProduct2: true, // yeni değerlerle güncelle
      };
    } else {
      console.error("machine not found");
    }

    // Bu ayın 1'i için tarih formatlama
    const currentDate = new Date();
    const startDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const formattedStartDate = startDate.toISOString();

    // Bir ay sonrasının 1'i için tarih formatlama
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    const formattedEndDate = nextMonth.toISOString();

    try {
      const res = await api.get(
        `/api/WorkOrder/GetShiftPlaningByMachineID?customerID=${customerID}&machineID=${e.value}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
      );
      list[index].shiftPlanning = res.data;
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${error}`,
        showConfirmButton: false,
        timer: 1500,
      });
    }

    setProductionStockCard(list);
    setLoading(false);

    console.log("Güncellenmiş liste: ", list);
  };

  useEffect(() => {
    // Tüm item'ların response.success değerini kontrol et
    const allItemsSuccess = productionStockCard.every(
      (item) => item.response?.success === true
    );
    setAllSuccess(allItemsSuccess);
  }, [productionStockCard]);
  console.log("productionStockCard", productionStockCard);

  console.log("postData", postData);
  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen  w-[75%] bg-gray-100">
                <div className="mb-5 flex items-center gap-2">
                <FaStarOfLife size={15}/>
              <h1 className="text-3xl font-semibold title">
                Ön İmalat Oluştur
              </h1>
            </div>
            <form
              onSubmit={handleSubmit}
              className="grid grid-cols-4 gap-7 items-center w-full"
            >
              <div className="col-span-2">
                <label
                  htmlFor="title"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Üretim Planlama Başlığı :
                </label>
                <input
                  type="text"
                  id="title"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Üretim Planlama Adı :
                </label>
                <input
                  type="text"
                  id="name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Cari Seç :
                </label>
                <Select
                  maxMenuHeight={200}
                  options={accounts}
                  value={selectedAccount}
                  onChange={(e) => setSelectedAccount(e)}
                  required
                  classNamePrefix="custom-select"
                />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="keywords"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Anahtar Kelimeler :
                </label>
                <CreatableSelect
                  isMulti
                  maxMenuHeight={200}
                  id="keywords"
                  onChange={(e) => setKeywords(e)}
                  options={keywordsData}
                  classNamePrefix="custom-select"
                />
              </div>

              <div className="col-span-3">
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Açıklama :
                </label>
                <textarea
                  id="description"
                  type="text"
                  className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="note"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Not :
                </label>
                <textarea
                  id="note"
                  type="text"
                  className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  rows={4}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </div>

              <div className=" overflow-x-auto mb-5 w-full col-span-4">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-white uppercase bg-slate-900">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        İşlem seç
                      </th>
                      <th scope="col" className="px-6 py-3">
                        İş Emri Kalemleri
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Makine / Operasyon
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Depo
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Adet
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Baş. Tarih
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Bit.Tarih
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Durum
                      </th>
                      <th scope="col" className="px-6 py-3"></th>
                      <th scope="col" className="px-6 py-3">
                        İşlemler
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {productionStockCard?.map((singleService, index) => (
                      <React.Fragment key={index}>
                        <tr className="bg-white border-b">
                          <td className="px-6 py-4">
                            <Select
                              value={singleService.productionType}
                              onChange={(e) =>
                                handleServiceProductionType(e, index)
                              }
                              options={productionOptions}
                              maxMenuHeight={200}
                              required
                              menuPortalTarget={document.body} // Menüyü body içinde render eder
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }), // z-index ayarını burada yapın
                              }}
                              classNamePrefix="custom-select"
                            />
                          </td>
                          <td className="px-6 py-4">
                            {singleService.productionType?.value === 1 ? (
                              <Select
                                value={{
                                  label: singleService.name,
                                  value: singleService.stockCardID,
                                }}
                                onChange={(e) =>
                                  handleFindStockCardId(e, index)
                                }
                                options={stockCardOpt}
                                maxMenuHeight={200}
                                required
                                menuPortalTarget={document.body} // Menüyü body içinde render eder
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }), // z-index ayarını burada yapın
                                }}
                                classNamePrefix="custom-select"
                              />
                            ) : singleService.productionType?.value === 2 ? (
                              <Select
                                value={{
                                  label: singleService.name,
                                  value: singleService.machineID,
                                }}
                                onChange={(e) => handleFindMachineId(e, index)}
                                options={machinesByServiceOpt}
                                maxMenuHeight={200}
                                required
                                menuPortalTarget={document.body} // Menüyü body içinde render eder
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }), // z-index ayarını burada yapın
                                }}
                                classNamePrefix="custom-select"
                              />
                            ) : (
                              <Select placeholder={"<< İşlem seçiniz"} />
                            )}
                          </td>

                          {!singleService.isMachineProduct &&
                            !singleService.isOperationProduct &&
                            !singleService.machineOperationItems && (
                              <td className="px-6 py-4">
                                <Select classNamePrefix="custom-select" placeholder={"<< Ürün seçiniz"} />
                              </td>
                            )}
                          {singleService.isMachineProduct && (
                            <td className="px-6 py-4">
                              <Select
                                options={
                                  singleService.machines.length > 0
                                    ? singleService.machines.map((item) => ({
                                        label: item.name,
                                        value: item.machineID,
                                      }))
                                    : machines.map((item) => ({
                                        label: item.name,
                                        value: item.machineID,
                                      }))
                                }
                                maxMenuHeight={200}
                                onChange={(e) => handleMachineChange(e, index)}
                                value={singleService?.selectedMachine}
                                required
                                menuPortalTarget={document.body} // Menüyü body içinde render eder
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }), // z-index ayarını burada yapın
                                }}
                                classNamePrefix="custom-select"
                              />
                            </td>
                          )}

                          {singleService.isOperationProduct && (
                            <td className="px-6 py-4">
                              <Select
                                options={
                                  singleService.operations.length > 0
                                    ? singleService.operations.map((item) => ({
                                        label: item.name,
                                        value: item.operationID,
                                      }))
                                    : operations.map((item) => ({
                                        label: item.name,
                                        value: item.operationID,
                                      }))
                                }
                                maxMenuHeight={200}
                                onChange={(e) =>
                                  handleOperationChange(e, index)
                                }
                                value={singleService?.selectedOperation}
                                required
                                menuPortalTarget={document.body} // Menüyü body içinde render eder
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }), // z-index ayarını burada yapın
                                }}
                                classNamePrefix="custom-select"
                              />
                            </td>
                          )}
                          {singleService.machineOperationItems && (
                            <td className="px-6 py-4">
                              <Select
                                value={{
                                  value: singleService.moId,
                                  label: singleService.stockCardName,
                                }}
                                onChange={(e) => handleServiceStockID(e, index)}
                                options={
                                  singleService.machineOperationItems.length >
                                    0 &&
                                  singleService.machineOperationItems.map(
                                    (item) => ({
                                      label: item.stockCardName,
                                      value: item.stockCardID,
                                      wareHouseName: item.wareHouseName,
                                      wareHouseID: item.wareHouseID,
                                      moId: item.moId,
                                    })
                                  )
                                }
                                maxMenuHeight={200}
                                required
                                menuPortalTarget={document.body} // Menüyü body içinde render eder
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }), // z-index ayarını burada yapın
                                }}
                                classNamePrefix="custom-select"
                              />
                            </td>
                          )}
                          <td className="px-6 py-4 w-32">
                            <Select
                              onChange={(e) => handleWareHouse(e, index)}
                              value={{
                                label: singleService.wareHouseName,
                                value: singleService.wareHouseID,
                              }}
                              options={wareHouses}
                              maxMenuHeight={200}
                              menuPortalTarget={document.body} // Menüyü body içinde render eder
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }), // z-index ayarını burada yapın
                              }}
                              classNamePrefix="custom-select"
                            />
                          </td>
                          <td className="px-6 py-4">
                            <input
                              name="quantity"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-16 p-2.5"
                              required
                              value={singleService.quantity}
                              onChange={(e) => {
                                handleServiceQuantitySelectChange(
                                  e.target.value,
                                  index
                                );
                              }}
                            />
                          </td>
                          <td className="px-6 py-4">
                            <DatePicker
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              showTimeSelect
                              locale={tr}
                              dateFormat="dd/MM/yyyy - HH:mm"
                              selected={singleService.startDate}
                              onChange={(e) => {
                                handleStartDate(e, index, singleService);
                              }}
                              required
                              popperClassName="datepicker-zindex" // popperClassName ile özel sınıf eklenir
                              popperPlacement="bottom-start" // Takvimin pozisyonunu ayarlar
                              portalId="root-portal" // Takvimi root altında render eder
                            />
                          </td>
                          <td className="px-6 py-4">
                            <DatePicker
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              showTimeSelect
                              locale={tr}
                              dateFormat="dd/MM/yyyy - HH:mm"
                              selected={singleService.endDate}
                              onChange={(e) => {
                                handleEndDate(e, index);
                              }}
                              required
                              popperClassName="datepicker-zindex" // popperClassName ile özel sınıf eklenir
                              popperPlacement="bottom-start" // Takvimin pozisyonunu ayarlar
                              portalId="root-portal" // Takvimi root altında render eder
                            />
                          </td>
                          <td className="px-6 py-4 max-w-sm">
                            <p
                              className={
                                singleService.response?.success &&
                                singleService.response?.status === 200
                                  ? "text-green-500 font-semibold text-base"
                                  : "text-red-500 font-semibold"
                              }
                            >
                              {singleService.response?.success &&
                              singleService.response?.status === 200
                                ? "Üretim planlamaya uygun"
                                : singleService.response?.errorMessage}
                            </p>
                          </td>
                          {singleService.response?.status === -2 &&
                            singleService.response?.success && (
                              <td className="px-6 py-4 w-44">
                                <Select
                                  options={options}
                                  maxMenuHeight={200}
                                  onChange={(e) => {
                                    handleServiceStatusSelectChange(
                                      e,
                                      index,
                                      singleService
                                    );
                                  }}
                                  value={singleService?.status}
                                  menuPortalTarget={document.body} // Menüyü body içinde render eder
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }), // z-index ayarını burada yapın
                                  }}
                                  classNamePrefix="custom-select"
                                />
                              </td>
                            )}

                          <td className="px-6 py-4">
                            <button
                              onClick={() => handleServiceRemove(index)}
                              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg "
                            >
                              <i className="fa-regular fa-trash-can"></i>
                            </button>
                          </td>
                        </tr>
                        <tr>
                          {singleService.shiftPlanning ? (
                            <td colSpan="9">
                              <div
                                style={{
                                  maxWidth: "80vw",
                                  marginBottom: "70px",
                                  margin: "0 auto 70px auto",
                                }}
                              >
                                <div>
                                  <p className="font-semibold text-green-600 text-lg">
                                    Lütfen vardiyaları daha net görebilmek için
                                    başlık alanına{" "}
                                    <span className="text-gray-500">▼</span>{" "}
                                    tıklayınız !
                                  </p>
                                </div>
                                <Timeline
                                  groups={[
                                    {
                                      id: singleService.shiftPlanning
                                        ? singleService.shiftPlanning[0]
                                            ?.machineID ||
                                          singleService.shiftPlanning[0]
                                            ?.operationID
                                        : null,
                                      title: singleService.shiftPlanning
                                        ? singleService.shiftPlanning[0]
                                            ?.machineName ||
                                          singleService.shiftPlanning[0]
                                            ?.operationName
                                        : null,
                                    },
                                  ]}
                                  items={
                                    singleService.shiftPlanning
                                      ? singleService.shiftPlanning.map(
                                          (shiftPlan) => ({
                                            id: shiftPlan.shiftID,
                                            group: singleService
                                              ? shiftPlan.machineID ||
                                                shiftPlan.operationID
                                              : shiftPlan.operationID ||
                                                shiftPlan.machineID,
                                            title: singleService
                                              ? shiftPlan.workOrderNames ||
                                                shiftPlan.workOrderNames
                                              : shiftPlan.workOrderNames ||
                                                shiftPlan.workOrderNames,
                                            start_time: moment(
                                              shiftPlan.startDate
                                            ),
                                            end_time: moment(shiftPlan.endDate),
                                          })
                                        )
                                      : []
                                  }
                                  defaultTimeStart={moment().startOf("month")}
                                  defaultTimeEnd={moment().endOf("month")}
                                  lineHeight={50}
                                  itemHeightRatio={0.9}
                                  onItemClick={(id) => handleItemClick(id)}
                                />
                              </div>
                            </td>
                          ) : null}
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                  <button
                    onClick={handleServiceAdd}
                    type="button"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg my-5"
                  >
                    Ekle
                    <i className="fa-solid fa-plus ml-2"></i>
                  </button>
                </table>
              </div>

              <div className="col-span-4 flex justify-end gap-[20px]">
                <button
                  type="submit"
                  className={`font-bold py-2 px-4 rounded-lg my-5 text-white ${
                    allSuccess
                      ? "bg-blue-500 hover:bg-blue-700"
                      : "bg-blue-800 cursor-not-allowed"
                  }`}
                  disabled={!allSuccess}
                >
                  Kaydet
                  <i className="fa-regular fa-floppy-disk ml-2"></i>
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default PreProductionAdd;
