import React from "react";
import { useAuth } from "../../context/AuthContext";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logo from "../../assets/images/logo.png";

const InvoiceDetailPDF = ({ data }) => {
  const styles = StyleSheet.create({
    page: {
      padding: 30,
      fontFamily: "Roboto",
    },
    section: {
      marginBottom: 10,
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 20,
    },
    invoiceNoContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    logo: {
      width: 140, // Adjust width and height to fit your logo
      height: 40,
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
      marginBottom: 20,
    },
    text: {
      fontSize: 12,
      marginBottom: 5,
    },
    table: {
      marginBottom: 20,
    },
    tableHeader: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: "#000",
      borderBottomStyle: "solid",
      marginBottom: 5,
    },
    tableRow: {
      flexDirection: "row",
      marginBottom: 5,
      borderBottomColor: "gray",
      borderBottomStyle: "solid",
      borderBottomWidth: 0.5,
    },
    tableCell: {
      flex: 1,
      fontSize: 10,
      padding: 5,
    },
    footer: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 20,
    },
  });

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();
    return `${gun}/${ay}/${yil}`;
  };


  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image src={logo} style={styles.logo} />
          <View style={styles.invoiceNoContainer}>
            <Text style={styles.title}>PROFORMA FATURA: {data?.invoiceNo}</Text>
            <Text style={styles.text}>Tarih: {formatDate(data?.invoiceDate)}</Text>
            <Text style={styles.text}>Bitiş Tarihi: {formatDate(data?.expiryDate)}</Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.text}>Talep:</Text>
          <Text style={[styles.text, { fontWeight: "bold" }]}>
            {data?.name}
          </Text>
          <Text style={styles.text}>{data?.taxOffice}</Text>
          <Text style={styles.text}>{data?.address}</Text>
          <Text style={styles.text}>{data?.account?.telephone}</Text>
          <Text style={styles.text}>{data?.account?.email}</Text>
        </View>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={styles.tableCell}>Ürün Adı</Text>
            <Text style={styles.tableCell}>Miktar</Text>
            <Text style={styles.tableCell}>Birim Fiyatı</Text>
            <Text style={styles.tableCell}>Vergi Fiyatı</Text>
            <Text style={styles.tableCell}>Vergi Oranı</Text>
            <Text style={styles.tableCell}>Toplam Fiyat</Text>
          </View>
          {data?.invoiceItems.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableCell}>{item.name}</Text>
              <Text style={styles.tableCell}>{item.quantity}</Text>
              <Text style={styles.tableCell}>{item.unitPrice}</Text>
              <Text style={styles.tableCell}>{item.taxPrice}</Text>
              <Text style={styles.tableCell}>{item.taxRate}</Text>
              <Text style={styles.tableCell}>{item.totalPrice}</Text>
            </View>
          ))}
        </View>

        <View style={styles.footer}>
          <View style={{ width: "60%" }}>
            <Text style={styles.text}>Açıklama: {data?.description}</Text>
          </View>
          <View>
            <Text style={styles.text}>
              Ara Toplam: {data?.price} {data?.currencyName}
            </Text>
            <Text style={styles.text}>
              İndirim: {data?.lineDiscounts} {data?.currencyName}
            </Text>
            <Text style={styles.text}>
              Vergi: {data?.taxPrice} {data?.currencyName}
            </Text>
            <Text style={styles.text}>
              Toplam: {data?.totalPrice} {data?.currencyName}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InvoiceDetailPDF;
