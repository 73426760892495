import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import logo from "../../assets/images/logo.png";
import api from "../../api/api";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import DemandDetailPdf from "./DemandDetailPdf";
import { FaRegFilePdf } from "react-icons/fa6";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner";

const DemandDetail = () => {
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState(null);
  const [token, setToken] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    if (user && user.country) {
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=2`
        )
        .then((res) => {
          setPermissions(res.data);
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.reportPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(`/api/Demand/Get?id=${params.id}&customerID=${user.country}`)
            .then((res) => {
              setData(res.data);
              res.data.demandDate = formatDate(res.data.demandDate);
              res.data.expiryDate = formatDate(res.data.expiryDate);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();
    return `${gun}/${ay}/${yil}`;
  };

  return (
    <>
      <Header />

      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="demandDetailCont mx-auto">
            {permissions.showPricePermission && (
              <div className="absolute right-0 pr-5">
                <PDFDownloadLink
                  document={<DemandDetailPdf data={data} />}
                  filename="FORM"
                >
                  {({ loading }) =>
                    loading ? (
                      <a className="text-lg font-medium text-red-600 hover:underline">
                        Yükleniyor...
                      </a>
                    ) : (
                      <a className="text-lg font-medium text-red-600 hover:underline flex gap-2">
                        <FaRegFilePdf size={30} />
                        Talep çıktı al.
                      </a>
                    )
                  }
                </PDFDownloadLink>
              </div>
            )}

            <div className="demandPage">
              <div className="page-top">
                <div className="page-top-logo">
                  <img className="w-[200px] mb-[10px]" src={logo} alt="" />
                  <p>Office 149, 450 South Brand Brooklyn</p>
                  <p>San Diego County, CA 91905, USA</p>
                  <p>+1 (123) 456 7891, +44 (876) 543 2198</p>
                </div>
                <div className="page-top-info">
                  <h1> {data?.demandNo}</h1>
                  <p>
                    <span>Tarih :</span> {data?.demandDate}
                  </p>
                  <p>
                    <span>Bitiş Tarihi :</span> {data?.expiryDate}
                  </p>
                </div>
              </div>
              <div className="page-mid-info">
                <h1>Satın Alma Talebi :</h1>
                <p style={{ fontWeight: "600", color: "rgb(63, 63, 63)" }}>
                  {data?.name}
                </p>
                <p>{data?.taxOffice}</p>
                <p>{data?.address}</p>
                <p>{data?.account?.telephone}</p>
                <p>{data?.account?.email}</p>
              </div>

              <div className="page-mid-items relative overflow-x-auto my-8">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Ürün Adı
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Miktar
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Birim fiyatı
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Vergi Fiyatı
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Vergi Oranı
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Toplam Fiyat
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.demandItems.map((item, index) => (
                      <tr key={index} className="bg-white border-b">
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                        >
                          {item.name}
                        </th>
                        <td className="px-6 py-4">{item.quantity}</td>
                        {permissions.showPricePermission ? (
                          <td className="px-6 py-4">{item.unitPrice}</td>
                        ) : (
                          <td className="px-6 py-4">-</td>
                        )}
                        {permissions.showPricePermission ? (
                          <td className="px-6 py-4">{item.taxPrice}</td>
                        ) : (
                          <td className="px-6 py-4">-</td>
                        )}
                        {permissions.showPricePermission ? (
                          <td className="px-6 py-4">{item.taxRate}</td>
                        ) : (
                          <td className="px-6 py-4">-</td>
                        )}

                        {permissions.showPricePermission ? (
                          <td className="px-6 py-4">{item?.totalPrice}</td>
                        ) : (
                          <td className="px-6 py-4">-</td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {permissions.showPricePermission && (
                <div className="page-bottom">
                  <div style={{ width: "60%" }}>
                    <p>
                      <span className="font-semibold">Açıklama :</span>{" "}
                      {data?.description}
                    </p>
                  </div>
                  <div className="total-detail">
                    <h1>
                      Ara Toplam :{" "}
                      <span className="float-right ml-12">
                        {data?.price} {data?.currencyName}
                      </span>
                    </h1>
                    <h1>
                      İndirim :{" "}
                      <span className="float-right ml-12">
                        {data?.lineDiscounts} {data?.currencyName}
                      </span>
                    </h1>
                    <h1>
                      Vergi :{" "}
                      <span className="float-right ml-12">
                        {data?.taxPrice} {data?.currencyName}
                      </span>
                    </h1>
                    <h1>
                      Toplam :{" "}
                      <span className="float-right ml-12">
                        {data?.totalPrice} {data?.currencyName}
                      </span>
                    </h1>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default DemandDetail;
