import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../api/api";
import { useAuth } from "../../context/AuthContext";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import logo from "../../assets/images/logo.png";

const DemandDetailPdf = ({ data }) => {
  const styles = StyleSheet.create({
    page: {
      padding: 30,
      fontFamily: "Roboto",
    },
    section: {
      marginBottom: 10,
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    demandNoContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    titleContainer: {
        display: "flex",
        flexDirection: "column",
        marginBottom: 20,
        justifyContent: "flex-end",
        alignItems: "flex-end",
      },
    logo: {
      width: 140, // Adjust width and height to fit your logo
      height: 40,
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
    },
    text: {
      fontSize: 12,
      marginBottom: 5,
    },
    table: {
      marginBottom: 20,
    },
    tableHeader: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: "#000",
      borderBottomStyle: "solid",
      marginBottom: 5,
    },
    tableRow: {
      flexDirection: "row",
      marginBottom: 5,
      borderBottomColor: "gray",
      borderBottomStyle: "solid",
      borderBottomWidth: 0.5,
    },
    tableCell: {
      flex: 1,
      fontSize: 10,
      padding: 5,
    },
    footer: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 20,
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image src={logo} style={styles.logo} />
          <View style={styles.demandNoContainer}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>SATIN ALMA TALEBİ</Text>
            <Text style={styles.title}>{data?.demandNo} </Text>

            </View>
            <Text style={styles.text}>Tarih: {data?.demandDate}</Text>
            <Text style={styles.text}>Bitiş Tarihi: {data?.expiryDate}</Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.text}>Talep:</Text>
          <Text style={[styles.text, { fontWeight: "bold" }]}>
            {data?.name}
          </Text>
          <Text style={styles.text}>{data?.taxOffice}</Text>
          <Text style={styles.text}>{data?.address}</Text>
          <Text style={styles.text}>{data?.account?.telephone}</Text>
          <Text style={styles.text}>{data?.account?.email}</Text>
        </View>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={styles.tableCell}>Ürün Adı</Text>
            <Text style={styles.tableCell}>Miktar</Text>
            <Text style={styles.tableCell}>Birim Fiyatı</Text>
            <Text style={styles.tableCell}>Vergi Fiyatı</Text>
            <Text style={styles.tableCell}>Vergi Oranı</Text>
            <Text style={styles.tableCell}>Toplam Fiyat</Text>
          </View>
          {data?.demandItems.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableCell}>{item.name}</Text>
              <Text style={styles.tableCell}>{item.quantity}</Text>
              <Text style={styles.tableCell}>{item.unitPrice}</Text>
              <Text style={styles.tableCell}>{item.taxPrice}</Text>
              <Text style={styles.tableCell}>{item.taxRate}</Text>
              <Text style={styles.tableCell}>{item.totalPrice}</Text>
            </View>
          ))}
        </View>

        <View style={styles.footer}>
          <View style={{ width: "60%" }}>
            <Text style={styles.text}>Açıklama: {data?.description}</Text>
          </View>
          <View>
            <Text style={styles.text}>
              Ara Toplam: {data?.price} {data?.currencyName}
            </Text>
            <Text style={styles.text}>
              İndirim: {data?.lineDiscounts} {data?.currencyName}
            </Text>
            <Text style={styles.text}>
              Vergi: {data?.taxPrice} {data?.currencyName}
            </Text>
            <Text style={styles.text}>
              Toplam: {data?.totalPrice} {data?.currencyName}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default DemandDetailPdf;
