import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { FaStar } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner";

const FormBuilderSchemaDetail = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const params = useParams();
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    if (user && user.country) {
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=12`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.reportPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/FormBuilder/Get?id=${params.id}&customerID=${user.country}`
            )
            .then((res) => setData(res.data))
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <form
            className="
          my-12 mx-auto
          w-[75vw] h-[90vh]
          p-[30px_20px]
          bg-gray-100
          rounded-lg
          flex flex-col gap-2
          overflow-y-scroll
        "
          >
            <h1 className="text-center font-medium text-2xl">{data?.name}</h1>
            <p className="mb-12 mt-12">
              <span className="font-medium mr-1">Açıklama:</span>{" "}
              {data?.description}
            </p>
            {
              /* formFields dizisini map ederek her bir form alanı için input ve label oluştur */
              data?.formFields?.map((field) => (
                <div className="mb-5" key={field.formFieldID}>
                  <label
                    className="font-medium mr-1"
                    htmlFor={`field-${field.formFieldID}`}
                  >
                    {field.name}:
                  </label>
                  {getFieldInputByType(
                    field,
                    setRating,
                    rating,
                    hover,
                    setHover
                  )}
                </div>
              ))
            }
          </form>
        )}
      </div>
      <Footer />
    </>
  );
};

// Her bir form alanı için uygun input elementini oluşturan yardımcı bir fonksiyon
const getFieldInputByType = (field, setRating, rating, hover, setHover) => {
  switch (field.type) {
    case 93:
      return (
        <input
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5"
          type="text"
          id={`field-${field.formFieldID}`}
        />
      );
    case 94:
      return (
        <textarea
          className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 h-40 p-2.5"
          id={`field-${field.formFieldID}`}
          style={{ resize: "none" }}
        ></textarea>
      );
    // Diğer case'leri ekleyebilirsiniz
    case 95:
      return <input type="radio" id={`field-${field.formFieldID}`} />;
    // Diğer case'leri ekleyebilirsiniz
    case 96:
      return <input type="checkbox" id={`field-${field.formFieldID}`} />;
    // Diğer case'leri ekleyebilirsiniz
    case 99:
      return (
        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-48 p-2.5"
          type="date"
          id={`field-${field.formFieldID}`}
        />
      );
    // Diğer case'leri ekleyebilirsiniz
    case 100:
      return <input type="file" id={`field-${field.formFieldID}`} />;
    // Diğer case'leri ekleyebilirsiniz
    case 97:
      const selectOptions = field.value
        .split(",")
        .map((word) => ({ value: word.trim(), label: word.trim() }));
      return (
        <Select
          options={selectOptions}
          className="w-48 my-1"
          placeholder={"Seçiniz"}
          maxMenuHeight={200}
          id={`field-${field.formFieldID}`}
          classNamePrefix="custom-select"
        />
      );
    case 98:
      return (
        <div className="flex gap-2" id={`field-${field.formFieldID}`}>
          {[...Array(5)].map((star, index) => {
            const currentRating = index + 1;

            return (
              <label key={currentRating}>
                <input
                  style={{ display: "none" }}
                  type="radio"
                  name="rating"
                  value={currentRating}
                  onClick={() => setRating(currentRating)}
                />
                <FaStar
                  style={{ cursor: "pointer" }}
                  size={30}
                  color={
                    currentRating <= (hover || rating) ? "#ffc107" : "#e4e5e9"
                  }
                  onMouseEnter={() => setHover(currentRating)}
                  onMouseLeave={() => setHover(null)}
                />
              </label>
            );
          })}
        </div>
      );
    // Diğer case'leri ekleyebilirsiniz
    default:
      return null;
  }
};

export default FormBuilderSchemaDetail;
