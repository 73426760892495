import React, { useState } from "react";
import { useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import api from "../../api/api";
import Swal from "sweetalert2";
import LoadingSpinnerModal from "../LoadingSpinnerModal";

const ControlCountModal = ({
  onCancel = () => {},
  selectedOpenModalId,
  selectedOpenModalParentId,
}) => {
  const { user } = useAuth();
  const [data, setData] = useState(null);
  const [token, setToken] = useState(null);
  const [customerID, setCustomerID] = useState(Number());
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(Number());
  const [quarantinaQuantity, setQuarantinaQuantity] = useState(Number());

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/WareHouse/GetShippingSlip?id=${selectedOpenModalId}&customerID=${user.country}`
        )
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err.data))
        .finally(() => setLoading(false));
    }
  }, [user]);

  const postData = {
    customerID,
    shippingSlipID: selectedOpenModalId,
    quantity,
    quarantinaQuantity,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Axios isteği yapılandırma
      await api.post(
        "/api/WareHouse/PostShippingSlipControl/PostShippingSlipControltoProductWareHouse",
        postData,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "İşlem başarılı !",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error("Hata alındı: ", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${error}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
      onCancel();
      // Belirli bir süre sonra sayfa yenilensin
      setTimeout(() => {
        window.location.reload();
      }, 1500); // 1500 milisaniye (1.5 saniye) sonra yenile
    }
  };

  console.log(postData);
  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30 z-[1000] flex justify-center items-center">
      <div className="w-3/5 p-8 bg-white rounded-lg flex flex-col justify-center items-center">
        {loading ? (
          <LoadingSpinnerModal loading={loading} />
        ) : (
          <form
            onSubmit={handleSubmit}
            className="grid grid-cols-2 gap-2 items-center"
          >
            <div className="col-span-2">
              <h1 className="text-center  text-4xl font-bold leading-none tracking-tight text-blue-800 pb-10">
                Kontrol Depo Sevkleri
              </h1>
            </div>
            <div className="col-span-2">
              <label
                htmlFor="title"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Başlık :
              </label>
              <input
                type="text"
                id="title"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                disabled
                value={data?.wareHouse_ShippingSlipItems[0]?.title}
              />
            </div>
            <div className="col-span-2">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Adı :
              </label>
              <input
                type="text"
                id="name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                disabled
                value={data?.wareHouse_ShippingSlipItems[0]?.name}
              />
            </div>
            <div className="col-span-2">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Geliş Adet :
              </label>
              <input
                type="number"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                disabled
                value={data?.wareHouse_ShippingSlipItems[0]?.quantity}
              />
            </div>
            <div className="col-span-2">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Ürün Deposuna Sevk Adet :
              </label>
              <input
                type="number"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                maxLength={3}
                value={quantity}
                onChange={(e) => {
                  setQuantity(e.target.value);
                  if (
                    data &&
                    data.wareHouse_ShippingSlipItems &&
                    data.wareHouse_ShippingSlipItems.length > 0
                  ) {
                    const currentQuantity =
                      data.wareHouse_ShippingSlipItems[0].quantity;
                    const currentScrapQuantity =
                      currentQuantity - e.target.value;
                    setQuarantinaQuantity(currentScrapQuantity);
                  }
                }}
              />
            </div>
            <div className="col-span-2">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Karantina Deposuna Sevk Adet :
              </label>
              <input
                type="number"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                disabled
                value={quarantinaQuantity}
              />
            </div>

            <div className="col-span-2 flex justify-center gap-[20px]">
              <button
                onClick={onCancel}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg m-5"
              >
                Vazgeç
              </button>
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg m-5"
              >
                Onayla
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ControlCountModal;
