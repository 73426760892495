import React, { useEffect, useState } from "react";
import assignment from "../../assets/images/assignment.png";
import api from "../../api/api";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const AssignmentCard = () => {
  const { user } = useAuth();
  const [data,setData] = useState(null)
  useEffect(() => {

    if  (user && user.country){
    api.get(`/api/Task/GetTasksByAssignedUser?customerID=${user.country}&userID=${user.nameidentifier}`)
    .then((res)=>setData(res.data))
    .catch((err)=>console.log(err.data))
    }
  }, [user]);

  return (
    <div className="p-6  rounded-lg flex justify-between items-center h-56 bg-gray-100">
      <div>
        <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-500">
          Bekleyen <span className="text-slate-950">{data?.filter((item) => item.status !== 73).length} göreviniz</span> var !
        </h5>

       <Link to={"/tasks"}>
       <button
          type="button"
          className="focus:outline-none text-white bg-slate-950 hover:bg-slate-800 focus:ring-4 focus:ring-slate-300 font-medium rounded-lg text-sm px-16 py-2.5 me-2 mb-2 my-12"
        >
          İncele
        </button>
       </Link>
      </div>
      <div>
        <img className="w-56" src={assignment} alt="" />
      </div>
    </div>
  );
};

export default AssignmentCard;
