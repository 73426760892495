import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Select from "react-select";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";

const MachineAdd = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(Number());
  const [kWh, setKWh] = useState(Number());
  const [kVA, setKVA] = useState(Number());
  const [serialNumber, setSerialNumber] = useState(Number());
  const [machineDailyRuntime, setMachineDailyRuntime] = useState(Number());
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [stockCards, setStockCards] = useState([]);
  const [stockCardsByType, setStockCardsByType] = useState([]);
  const [machineGroups, setMachineGroups] = useState([]);
  const [machineStatusOptions, setMachineStatusOptions] = useState([]);
  const [selectedMachineStatus, setSelectedMachineStatus] = useState({
    value: 0,
    label: "Makine Durumunu Seçiniz...",
  });
  const [token, setToken] = useState(null);
  //FORM
  const [machineProductionItems, setMachineProductionItems] = useState([]);
  const [machineOperationItems, setMachineOperationItems] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [notes, setNotes] = useState("");
  const [machineGroupID, setMachineGroupID] = useState({});
  const [keywords, setKeywords] = useState([]);
  const [keywordsData, setKeywordsData] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);

      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=23`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.insertPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/StockCard/GetStockCards?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              const dataStockCards = res.data.items;
              const newDataStockCards = dataStockCards?.map((item) => ({
                value: item.stockCardID,
                label: `${item.code} - ${item.name}`,
              }));
              setStockCards(newDataStockCards);
            })
            .catch((err) => console.log(err.data));
          api
            .get(
              `/api/StockCard/GetStockCardsByType?customerID=${user.country}&type=1663`
            )
            .then((res) => {
              const dataStockCardsByType = res.data;
              const newDataStockCardsByType = dataStockCardsByType?.map(
                (item) => ({
                  value: item.stockCardID,
                  label: `${item.code} - ${item.name}`,
                  value2: item.unitType,
                })
              );
              setStockCardsByType(newDataStockCardsByType);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/Machine/GetMachineGroups?customerID=${user.country}`)
            .then((res) => {
              const dataMachineGroups = res.data;
              const newDataMachineGroups = dataMachineGroups?.map((item) => ({
                value: item.machineGroupID,
                label: item.name,
              }));
              setMachineGroups(newDataMachineGroups);
            })
            .catch((err) => console.log(err.data));
          api
            .get(`/api/Common/GetParameters?parametername=MachineStatus`)
            .then((res) => {
              const dataMachinesStatus = res.data;
              const newDataMachineGroups = dataMachinesStatus?.map((item) => ({
                value: item.parameterID,
                label: item.parameterValue2,
              }));
              setMachineStatusOptions(newDataMachineGroups);
            })
            .catch((err) => console.log(err.data));
          api
            .get(`/api/Common/GetParameters?parametername=UnitType`)
            .then((res) => {
              setUnitTypes(res.data);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/Common/GetLocations?customerID=${user.country}`)
            .then((res) => {
              let dataLocations = res.data;
              let newDataLocations = dataLocations.map((item) => ({
                value: item,
                label: item,
              }));
              setLocations(newDataLocations);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newDataKey = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setKeywordsData(newDataKey);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const handleServiceRemove = (index) => {
    const list = [...machineProductionItems];
    list.splice(index, 1);
    setMachineProductionItems(list);
  };

  const handleServiceAdd = () => {
    setMachineProductionItems([
      ...machineProductionItems,
      {
        stockCardID: Number(),
        productionCapacityPerHour: Number(),
        productionCapacityPerMinute: Number(),
        name: "",
        title: "",
        type: Number(),
        status: "",
      },
    ]);
  };

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...machineProductionItems];

    // Saatlik kapasite değiştiğinde dakika başına kapasiteyi hesapla
    if (name === "productionCapacityPerHour") {
      const productionCapacityPerMinute = value / 60;
      list[index]["productionCapacityPerMinute"] = productionCapacityPerMinute;
    }

    list[index][name] = value;
    setMachineProductionItems(list);
  };

  const handleSelectChange = (e, index) => {
    const list = [...machineProductionItems];
    list[index].stockCardID = e;
    setMachineProductionItems(list);
  };

  ////////////  machineOperationItems /////////////

  const machineOperationItemsAdd = () => {
    setMachineOperationItems([
      ...machineOperationItems,
      {
        stockCardID: Number(),
        unitID: Number(),
        quantity: Number(),
        productionTimePerSecond: Number(),
      },
    ]);
  };

  const handleMachineOperationItemsRemove = (index) => {
    const list = [...machineOperationItems];
    list.splice(index, 1);
    setMachineOperationItems(list);
  };

  const handleSelectProductionChange = (e, index) => {
    const list = [...machineOperationItems];
    list[index].stockCardID = e.value;
    list[index].unitID = e.value2;
    const findUnittype = unitTypes.find(
      (item) => item.parameterID === e.value2
    );
    list[index].unitName = findUnittype.parameterValue2;
    setMachineOperationItems(list);
  };

  const handleProductionChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...machineOperationItems];
    list[index][name] = value;
    setMachineOperationItems(list);
  };

  const postData = {
    customerID,
    machineGroupID: machineGroupID?.value,
    name,
    title,
    kWh,
    kVA,
    location: selectedLocation?.label,
    type: 0,
    status: selectedMachineStatus?.value,
    maintanceStatus: 0,
    description,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    notes,
    machineProductionItems,
    machineOperationItems: machineOperationItems.map((item) => ({
      stockCardID: item.stockCardID,
      unitID: item.unitID,
      quantity: item.quantity,
      productionTimePerSecond: item.productionTimePerSecond,
    })),
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Axios isteği yapılandırma
      await api.post("/api/Machine/Post/Post", postData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Makine oluşturuldu !",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/machines");
    } catch (error) {
      console.error("Hata alındı: ", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${error}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  console.log("post", postData);

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen w-[75%] bg-gray-100">
                   <div className="mb-5 flex items-center gap-2">
                   <FaStarOfLife size={15}/>
              <h1 className="text-3xl font-semibold title">Makine Oluştur</h1>
            </div>
            <form
              className="grid grid-cols-4 gap-7 items-center w-full"
              onSubmit={handleSubmit}
            >
              <div className="col-span-2">
                <label
                  htmlFor="title"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Başlık :
                </label>
                <input
                  type="text"
                  id="title"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Adı :
                </label>
                <input
                  type="text"
                  id="name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Makine Grubu :
                </label>
                <Select
                  value={machineGroupID}
                  onChange={(e) => setMachineGroupID(e)}
                  options={machineGroups}
                  maxMenuHeight={200}
                  classNamePrefix="custom-select"
                />
              </div>
              <div className="col-span-2 flex justify-between">
                <div>
                  <label
                    htmlFor="dailyRunTime"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Günlük Çalışma Süresi (dk) :
                  </label>
                  <input
                    type="number"
                    id="dailyRunTime"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={machineDailyRuntime}
                    onChange={(e) => setMachineDailyRuntime(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="kwh"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    KWH :
                  </label>
                  <input
                    type="number"
                    id="kwh"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={kWh}
                    onChange={(e) => setKWh(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="kwh"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    KVA :
                  </label>
                  <input
                    type="number"
                    id="kva "
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={kVA}
                    onChange={(e) => setKVA(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Durum :
                </label>
                <Select
                  maxMenuHeight={200}
                  options={machineStatusOptions}
                  value={selectedMachineStatus}
                  onChange={(e) => setSelectedMachineStatus(e)}
                  classNamePrefix="custom-select"
                />
              </div>
              <div className="col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Seri No :
                </label>
                <input
                  type="number"
                  id="kva "
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  value={serialNumber}
                  onChange={(e) => setSerialNumber(e.target.value)}
                />
              </div>
              <div className="col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Lokasyon :
                </label>
                <Select
                  value={selectedLocation}
                  onChange={(e) => setSelectedLocation(e)}
                  options={locations}
                  maxMenuHeight={200}
                  classNamePrefix="custom-select"
                />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="keywords"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Anahtar Kelimeler :
                </label>
                <CreatableSelect
                  isMulti
                  maxMenuHeight={200}
                  id="keywords"
                  onChange={(e) => setKeywords(e)}
                  options={keywordsData}
                  classNamePrefix="custom-select"
                />
              </div>

              <div className="col-span-3">
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Açıklama :
                </label>
                <textarea
                  id="description"
                  type="text"
                  className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="note"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Not :
                </label>
                <textarea
                  id="note"
                  type="text"
                  className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  rows={4}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </div>
              <div className="mt-3">
                <p className="text-xl font-bold text-slate-600"> Üretim / Ürün</p>
              </div>
              <div className="relative overflow-x-auto mb-5 col-span-4 ">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-white uppercase bg-slate-900">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Ürün
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Saatlik Üretim Adeti
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Dakikalık Üretim Adeti
                      </th>
                      <th scope="col" className="px-6 py-3">
                        İşlem
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {machineProductionItems.map((singleService, index) => (
                      <tr key={index} className="bg-white border-b">
                        <td className="px-6 py-4">
                          <div>
                            <Select
                              onChange={(e) =>
                                handleSelectChange(e.value, index)
                              }
                              options={stockCards}
                              maxMenuHeight={200}
                              menuPortalTarget={document.body} // Menüyü body içinde render eder
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }), // z-index ayarını burada yapın
                              }}
                              classNamePrefix="custom-select"
                            />
                          </div>
                        </td>

                        <td className="px-6 py-4">
                          <input
                            value={singleService.productionCapacityPerHour}
                            onChange={(e) =>
                              handleServiceChange(e, index, "hour")
                            }
                            name="productionCapacityPerHour"
                            type="number"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required
                          />
                        </td>
                        <td className="px-6 py-4">
                          <input
                            value={singleService.productionCapacityPerMinute}
                            onChange={(e) =>
                              handleServiceChange(e, index, "minute")
                            }
                            name="productionCapacityPerMinute"
                            type="number"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required
                          />
                        </td>

                        <td className="px-6 py-4">
                          <button
                            onClick={() => handleServiceRemove(index)}
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg "
                          >
                            <i className="fa-regular fa-trash-can"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <button
                    onClick={handleServiceAdd}
                    type="button"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg m-5"
                  >
                    Ekle
                    <i className="fa-solid fa-plus ml-2"></i>
                  </button>
                </table>
              </div>
              <div className="mt-3">
                <p className="text-xl font-bold text-slate-600"> Üretim / Hizmet</p>
              </div>
              <div className="relative overflow-x-auto mb-5 col-span-4 ">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-white uppercase bg-slate-900">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Hizmet
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Üretim Adeti
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Birim
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Üretim Süresi (sn)
                      </th>
                      <th scope="col" className="px-6 py-3">
                        İşlem
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {machineOperationItems.map((singleService, index) => (
                      <tr key={index} className="bg-white border-b">
                        <td className="px-6 py-4">
                          <div>
                            <Select
                              options={stockCardsByType}
                              onChange={(e) =>
                                handleSelectProductionChange(e, index)
                              }
                              maxMenuHeight={200}
                              menuPortalTarget={document.body} // Menüyü body içinde render eder
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }), // z-index ayarını burada yapın
                              }}
                              classNamePrefix="custom-select"
                            />
                          </div>
                        </td>

                        <td className="px-6 py-4">
                          <input
                            name="quantity"
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required
                            onChange={(e) => handleProductionChange(e, index)}
                            value={singleService.quantity}
                          />
                        </td>
                        <td className="px-6 py-4">
                          <input
                            name=""
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            disabled
                            value={singleService.unitName}
                          />
                        </td>
                        <td className="px-6 py-4">
                          <input
                            name="productionTimePerSecond"
                            type="number"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required
                            value={singleService.productionTimePerSecond}
                            onChange={(e) => handleProductionChange(e, index)}
                          />
                        </td>

                        <td className="px-6 py-4">
                          <button
                            onClick={() =>
                              handleMachineOperationItemsRemove(index)
                            }
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg "
                          >
                            <i className="fa-regular fa-trash-can"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <button
                    onClick={machineOperationItemsAdd}
                    type="button"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg m-5"
                  >
                    Ekle
                    <i className="fa-solid fa-plus ml-2"></i>
                  </button>
                </table>
              </div>

              <div className="col-span-4 flex justify-end gap-[20px]">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg my-5"
                >
                  Kaydet
                  <i className="fa-regular fa-floppy-disk ml-2"></i>
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default MachineAdd;
//üretim ürünü , üretim hizmet
