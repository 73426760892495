import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import "../demands/demands.css";
import { useNavigate } from "react-router-dom";
import api from "../../api/api";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";

const MyMeetings = () => {
  const { user } = useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [meetingTypes, setMeetingTypes] = useState([]);
  const [newData, setNewData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken1 =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    if (user && user.country) {
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=17`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get("/api/Common/GetParameters?parametername=MeetingType")
            .then((res) => setMeetingTypes(res.data))
            .catch((err) => console.error(err.data));

          const fetchData = async () => {
            try {
              // Axios isteği yapılandırma
              const response = await api.get(
                `/api/Meeting/GetMeetingsForUser?customerID=${user.country}`,
                {
                  headers: {
                    Authorization: `Bearer ${storedToken1}`,
                  },
                }
              );

              setData(response.data);
            } catch (error) {
              console.error("Error fetching data: ", error);
            } finally {
              setLoading(false); // İstek tamamlandığında setLoading(false) çağrısı ekleniyor.
            }
          };

          fetchData();
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const handleSubmit = async (id) => {
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Axios isteği yapılandırma
      await api.put(
        `/api/Meeting/PutUserJoin/PutUserJoin?meetingID=${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );

      navigate(`/meetingLogin/${id}`);
    } catch (error) {
      console.error("Hata alındı: ", error);
    }
  };

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    // Saate 3 ekleyerek hesaplama yapalım
    let saat = tarihObjesi.getHours();

    // Eğer saat 24'ten büyükse 24'e göre modunu alalım
    saat = saat % 24;

    // Saati string formatına çevirirken padding işlemi yapalım
    const saatString = saat.toString().padStart(2, "0");
    const dakika = tarihObjesi.getMinutes().toString().padStart(2, "0");

    return `${gun}/${ay}/${yil} - ${saatString}.${dakika} `;
  };

  const getMeetingStatus = (startDate) => {
    const meetingDate = new Date(startDate); // Toplantı başlangıç tarihi
    const currentDate = new Date(); // Şu anki tarih ve saat

    // Eğer toplantı başlangıç tarihi şu andan önceyse "başlayacak", değilse "başladı"
    return meetingDate > currentDate ? "Başlayacak" : "Başladı";
  };

  useEffect(() => {
    if (meetingTypes && data) {
      // Veri ve kullanıcılar yüklendiyse eşleşme işlemini yap
      const matchedUsers = data.map((item) => {
        const matchedUser = meetingTypes.find(
          (type) => type.parameterID === item.type
        );
        if (matchedUser) {
          return {
            ...item,
            typeName: matchedUser.parameterValue2,
            meetStatus: getMeetingStatus(item.startDate),
          };
        }
        return item;
      });
      // Eşleşmiş kullanıcıları içeren veriyi set et
      setNewData(matchedUsers);
    }
  }, [meetingTypes, data]);

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="m-12 w-full min-h-screen">
            <div className="add-button">
            <div className="mb-5 flex items-center gap-2">
            <FaStarOfLife size={15}/>
                <h1 className="text-3xl font-semibold title">Toplantılarım</h1>
              </div>
              <div className="flex">
                <input
                  className="w-96 block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                  type="text"
                  placeholder="Toplantı ara.."
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
              </div>
            </div>

         
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-white uppercase bg-slate-900 sticky top-0">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Toplantı Adı
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Başlama Saati
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Tipi
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Durumu
                    </th>
                    <th scope="col" className="px-6 py-3">
                      İslemler
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {newData?.length > 0 ? (
                    newData
                      ?.filter((item) =>
                        item.name
                          ?.toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                      .map((item, index) => (
                        <tr
                          key={index}
                          className="bg-white border-b hover:bg-gray-50"
                        >
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                          >
                            {item?.name}
                          </th>
                          <td className="px-6 py-4">
                            {formatDate(item?.startDate)}
                          </td>
                          <td className="px-6 py-4">{item?.typeName}</td>

                          {item?.meetStatus === "Başlayacak" ? (
                            <td className="px-6 py-4 text-green-500 font-semibold">
                              {item?.meetStatus}
                            </td>
                          ) : (
                            <td className="px-6 py-4 text-yellow-500 font-semibold">
                              {item?.meetStatus}
                            </td>
                          )}
                          <td className="px-6 py-4">
                            <button
                              onClick={() => handleSubmit(item.meetingID)}
                              className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-1.5 focus:outline-none"
                            >
                              Katıl
                            </button>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td
                        colSpan={5}
                        className="px-6 py-4 text-center text-2xl font-medium"
                      >
                        Henüz kayıtlı bir veri yoktur.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default MyMeetings;
