import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner";

const PersonelDetail = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [personelData, setPersonelData] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=25`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.reportPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(`/api/Personel/Get?id=${params.id}&customerID=${user.country}`)
            .then((res) => {
              setPersonelData(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            })
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    return `${gun}/${ay}/${yil}`;
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex justify-center items-center gap-8 mx-auto min-h-screen">
            <div className="flex mt-32 items-center h-screen flex-col gap-10">
              <div className="bg-white max-w-[40rem] min-w-[40rem] overflow-hidden ">
                <div className="px-4 py-5 sm:px-6 bg-slate-900">
                  <h3 className="text-xl leading-6 font-semibold text-white">
                    Personel Bilgileri
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm font-medium text-gray-500 ">
                    {/* {data?.title} */}
                  </p>
                </div>
                <div className="border-t border-gray-200 grid-cols-2 grid">
                  <div className="col-span-2 sm:col-span-1">
                    <div className="bg-gray-50 px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm  font-medium text-gray-500"
                      >
                        Personel Adı :
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {personelData?.name}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm font-medium text-gray-500"
                      >
                        Personel Soyadı :
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {personelData?.surname}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm font-medium text-gray-500"
                      >
                        TC :
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {personelData?.identityNumber}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm font-medium text-gray-500"
                      >
                        Cinsiyet :
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {personelData?.gender === "E" ? "Erkek" : "Kadın"}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm font-medium text-gray-500"
                      >
                        Doğum Tarihi:
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {formatDate(personelData?.birthDate)}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm font-medium text-gray-500"
                      >
                        Doğum Yeri:
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {personelData?.birthPlace}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm font-medium text-gray-500"
                      >
                        Kan Grubu:
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {personelData?.bloodGroupName}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm font-medium text-gray-500"
                      >
                        Askerlik Durumu:
                      </label>
                      <input
                        type="checkbox"
                        checked={personelData.militaryStatus}
                        disabled={true}
                        className="mt-1 text-sm justify-start text-gray-900 "
                      />
                    </div>
                  </div>
                  <div className="col-span-2 sm:col-span-1 ">
                    <div className="bg-gray-50 px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm font-medium text-gray-500"
                      >
                        Departman:
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {personelData?.departmentName}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm font-medium text-gray-500"
                      >
                        Başlık:
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {personelData?.title}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm font-medium text-gray-500"
                      >
                        SGK Numarası :
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {personelData?.socialNumber}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm font-medium text-gray-500"
                      >
                        Telefon :
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {personelData?.telephone}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm font-medium text-gray-500"
                      >
                        GSM :
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {personelData?.cellphone}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm font-medium text-gray-500"
                      >
                        Email :
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {personelData?.email}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm font-medium text-gray-500"
                      >
                        İl :
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {personelData?.city}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm font-medium text-gray-500"
                      >
                        İlçe :
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {personelData?.district}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm font-medium text-gray-500"
                      >
                        Adres :
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {personelData?.address}
                      </dd>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default PersonelDetail;
